import { Col, Row } from 'antd';
import { FancyInput } from 'components/atoms/FancyInput';
import { FancySelect } from 'components/atoms/FancySelect';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setDefaultCondition, setDefaultDiscountPercentage } from 'redux/productsInfo/productsInfoSlice';
import { useGetConditionsQuery } from 'redux/services/animals/animalsApi';
import { useAppSelector } from 'redux/store';
import { ProductsSearchModal } from './ProductsSearchModal';

export const ProductHeaderActions = (): JSX.Element => {
  const dispatch = useDispatch();
  const { data } = useGetConditionsQuery({ overrideSkipTake: true, offset: 0, conditionNames: 'CERTRECOND,NEW,USED' });
  const { showExtraColumns } = useAppSelector((state) => state.productsInfo);
  const { defaultDiscountPercentage, defaultCondition } = useAppSelector((state) => state.productsInfo);
  const { pathname } = useLocation();
  const isView = pathname.includes('/view');

  return (
    <Row gutter={[8, 8]} align="middle">
      <Col>
        <ProductsSearchModal />
      </Col>
      <Col>
        <FancyInput
          value={isView ? undefined : defaultDiscountPercentage}
          disabled={isView}
          width={200}
          placeholder="Default Discount"
          onChange={(e): void => {
            dispatch(setDefaultDiscountPercentage(e.target.value));
          }}
        />
      </Col>
      <Col>
        <FancySelect
          value={isView ? 'Default Condition' : defaultCondition}
          disabled={isView}
          width={200}
          handleSelect={(condition: string): void => {
            dispatch(setDefaultCondition(condition));
          }}
          placeholder={isView ? null : 'Default Condition'}
          options={data?.data.map((condition) => ({ label: condition.name, value: condition.name }))}
        />
      </Col>
    </Row>
  );
};
