import { Form, message, Modal, Spin } from 'antd';
import { QuoteBreadcrumb } from 'components/atoms/QuoteBreadcrumb';
import { ActionControls } from 'components/common/ActionControls';
import { Loader } from 'components/common/Loader';
import { StepControls } from 'components/common/StepControls';
import { FormikProvider, useFormik } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { QuotePayload } from 'models/Quotes';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useCreateAttachmentMutation, useCreateQuoteMutation } from 'redux/services/samTheRobot/samTheRobotApi';
import { setQuoteSubmissionStatusMessage } from 'redux/slices/quoteSlice';
import { useAppSelector } from 'redux/store';

const styles: InlineStylesModel = {
  title: {
    marginTop: 500,
    marginBottom: 0
  }
};

export const CreateLayout = (): JSX.Element => {
  const dispatch = useDispatch();
  const [createQuote, { isLoading: quoteSubmitting }] = useCreateQuoteMutation();
  const { pathname } = useLocation();
  const nav = useNavigate();

  const { quoteSubmissionStatusMessage, selectedCompanyDataAreaId } = useAppSelector((state) => state.orders);

  const isHeaderInfo = pathname.includes('header-info');
  const isDeliveryAddress = pathname.includes('delivery-address');
  const isProducts = pathname.includes('products');
  const isOverview = pathname.includes('quote');
  const currentPathname = isHeaderInfo ? 'header-info' : isDeliveryAddress ? 'delivery-address' : isProducts ? 'products' : isOverview ? 'quote' : '';
  const [createAttachment, { isLoading: isCreatingAttachment }] = useCreateAttachmentMutation();

  const formik = useFormik<QuotePayload>({
    initialValues: {
      alternateCustomerDisplayName: '',
      attachments: [],
      buyerId: '',
      buyerName: '',
      customerErpId: '',
      customerAccount: '',
      customerName: '',
      customerDataAreaId: '',
      customerContactName: '',
      customerContactPhone: '',
      customerEmailAddress: '',
      customerEmailAddresses: [],
      emailMessage: '',
      dataAreaId: '',
      isActive: true,
      lines: [],
      notes: '',
      quoteExpiresInDays: 45,
      quoteExpirationDate: '',
      quoteName: '',
      totalCost: 0,
      totalPrice: 0,
      totalProfit: 0,
      totalMargin: 0,
      salesPersonName: '',
      salesPersonId: '',
      salesPersonEmail: '',
      deliveryAddressStreet1: '',
      deliveryAddressCity: '',
      deliveryAddressCountry: '',
      deliveryAddressCounty: '',
      deliveryAddressSubdivision: '',
      deliveryAddressPostalCode: '',
      deliveryAddressName: '',
      newQuote: true,
      closeWizard: true
    },
    onSubmit: async (values) => {
      if (!selectedCompanyDataAreaId) return message.error('Legal entity not selected, please refresh the page and try again');

      const totalPrice = values.lines.reduce((accumulator, quoteLineObject) => {
        return accumulator + Number(quoteLineObject.extendedPrice || 0);
      }, 0);

      try {
        const data = await createQuote({
          ...values,
          dataAreaId: selectedCompanyDataAreaId,
          customerErpId: values.customerAccount,
          lines: values.lines,
          totalPrice
        }).unwrap();

        if (values.attachments && values.attachments.length) {
          dispatch(setQuoteSubmissionStatusMessage('Creating attachments'));
          const formData = new FormData();

          for (const attachment of values.attachments) {
            let index = 1;

            dispatch(setQuoteSubmissionStatusMessage(`Creating attachments ${index}/${values.attachments.length}`));

            formData.append(attachment.name, attachment);

            await createAttachment({ file: formData, quoteId: data?.id as string } ?? { skip: !data.id }).unwrap();

            formData.delete(attachment.name);
            index++;
          }
        }

        Modal.success({
          afterClose: () => {
            nav(values.closeWizard ? `/search` : `/edit/${data.id}/${currentPathname}`);
            window.location.reload();
          },
          title: 'Quote successfully created',
          content: `Quote Number: ${data.quoteId}`
        });
      } catch (error) {
        console.log(error);
        /******** THIS IS THE PROPER ERROR HANDLING PLEASE DO NOT DELETE AGAIN, IT IS NOT ERROR.DATA >:( ********/

        message.error((error as { data: { errorMessage: string } }).data.errorMessage);
      }
    }
  });

  return (
    <FormikProvider value={formik}>
      <Spin spinning={quoteSubmitting || isCreatingAttachment} indicator={<Loader message={quoteSubmissionStatusMessage || 'Creating Sales Quote...'} />}>
        <Form style={styles.container} layout="vertical">
          <ActionControls />
          <QuoteBreadcrumb />
          <StepControls />
          <Outlet />
        </Form>
      </Spin>
    </FormikProvider>
  );
};
