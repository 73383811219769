import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, InputNumber, message, Modal, Space, Spin, Typography } from 'antd';
import { Loader } from 'components/common/Loader';
import { QuotePayload } from 'models/Quotes';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { samTheRobotApi, useCreateQuoteMutation, useDuplicateQuoteMutation, useUpdateQuoteAfterDuplicateMutation } from 'redux/services/samTheRobot/samTheRobotApi';
import { setDuplicateMode } from 'redux/slices/duplicateQuoteSlice';
import { setQuoteSubmissionStatusMessage } from 'redux/slices/quoteSlice';
import { useAppSelector } from 'redux/store';

interface Props {
  isDuplicateModalOpen: boolean;
  setIsDuplicateOpen: (isDuplicateModalOpen: boolean) => void;
  values?: QuotePayload;
}

export const DuplicateModalFromHome: FC<Props> = ({ isDuplicateModalOpen, setIsDuplicateOpen, values }) => {
  const [quantityInput, setQuantityInput] = useState(1);
  const nav = useNavigate();

  const { selectedCompanyDataAreaId, quoteSubmissionStatusMessage } = useAppSelector((state) => state.orders);
  const dispatch = useDispatch();
  const [createQuote, { isLoading: quoteSubmitting }] = useCreateQuoteMutation();
  const [duplicateQuote, { isLoading: isDuplicatingQuote }] = useDuplicateQuoteMutation();
  const [updateQuote, { isLoading: isUpdating }] = useUpdateQuoteAfterDuplicateMutation();

  const isHandlingLoad = Boolean(quoteSubmitting || isDuplicatingQuote || isUpdating);

  const handleSubmitWithAttachments = async (): Promise<void> => {
    if (!selectedCompanyDataAreaId) return message.error('Legal entity could not be found, please refresh the page.');
    if (values) {
      dispatch(setDuplicateMode(true));
      const totalPrice = values.lines.reduce((accumulator, quoteLineObject) => {
        return accumulator + Number(quoteLineObject.extendedPrice || 0);
      }, 0);

      try {
        for (let i = 1; i <= quantityInput; i++) {
          dispatch(setQuoteSubmissionStatusMessage(`Creating copy ${i}/${quantityInput}`));
          const duplicateQuoteData = await duplicateQuote((values.id as string) ?? skipToken).unwrap();

          console.log(duplicateQuoteData, i, '<<<<');

          await updateQuote({
            quoteId: duplicateQuoteData.id as string,
            body: {
              ...values,
              dataAreaId: selectedCompanyDataAreaId || 'mdsi',
              customerErpId: '',
              quoteName: values.quoteName ? `${values.quoteName}-copy${i}` : `${values.quoteId}-copy${i}`,
              totalPrice
            } ?? { skip: !duplicateQuoteData.id }
          }).unwrap();

          if (quantityInput === 1) {
            nav(`/edit/${duplicateQuoteData.id}`);
            message.success('Quote successfully duplicated');

            break;
          }
        }
        dispatch(samTheRobotApi.util.invalidateTags(['Quotes']));

        if (quantityInput > 1) {
          nav('/');
          message.success('Copies successfully created');
        }
        dispatch(setQuoteSubmissionStatusMessage(''));
      } catch (err) {
        console.log(err);
        message.error((err as any).data);
      }
    }
  };
  const handleSubmitWithoutAttachments = async (): Promise<void> => {
    if (!selectedCompanyDataAreaId) return message.error('Legal entity could not be found, please refresh the page.');
    if (values) {
      dispatch(setDuplicateMode(true));
      const totalPrice = values.lines.reduce((accumulator, quoteLineObject) => {
        return accumulator + Number(quoteLineObject.extendedPrice || 0);
      }, 0);

      try {
        for (let i = 1; i <= quantityInput; i++) {
          dispatch(setQuoteSubmissionStatusMessage(`Creating copy ${i}/${quantityInput}`));
          const newQuoteData = await createQuote({
            ...values,
            dataAreaId: selectedCompanyDataAreaId,
            customerErpId: values.customerAccount,
            quoteName: values.quoteName ? `${values.quoteName}-copy${i}` : `${values.quoteId}-copy${i}`,
            totalPrice
          }).unwrap();

          if (quantityInput === 1) {
            nav(`/edit/${newQuoteData.id}`);
            message.success('Quote successfully duplicated');

            break;
          }
        }
        dispatch(samTheRobotApi.util.invalidateTags(['Quotes']));

        if (quantityInput > 1) {
          nav('/');
          message.success('Copies successfully created');
        }

        dispatch(setQuoteSubmissionStatusMessage(''));
      } catch (err) {
        console.log(err);
        message.error((err as any).data);
      }
    }
  };

  return (
    <Modal
      width={800}
      bodyStyle={{ height: isHandlingLoad ? 300 : undefined }}
      open={isDuplicateModalOpen}
      footer={
        <Space>
          <Button>Cancel</Button>
          <Button onClick={handleSubmitWithoutAttachments} type="primary">
            Duplicate without attachments
          </Button>
          <Button onClick={handleSubmitWithAttachments} type="primary">
            Duplciate with attachments
          </Button>
        </Space>
      }
      title="Duplicate Quote"
      onCancel={(): void => setIsDuplicateOpen(!isDuplicateModalOpen)}>
      <Spin spinning={isHandlingLoad} indicator={<Loader message={quoteSubmissionStatusMessage || 'Creating Sales Quote...'} />}>
        <Space size={20} direction="vertical">
          <Typography.Text>Number of copies to duplicate</Typography.Text>
          <InputNumber controls={false} min={1} value={quantityInput} onChange={(ev): void => setQuantityInput(ev ?? 1)} />
        </Space>
      </Spin>
    </Modal>
  );
};
