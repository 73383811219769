import { RedoOutlined } from '@ant-design/icons';
import { Button, Tooltip, message } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyGetCustomersQuery } from 'redux/services/beautifulDayMonster/beautifulDayMonsterApi';
import { setCachedCustomerList, setIsLazyFetchLoading } from 'redux/slices/customerCachingSlice';

export const RefetchButton = (): JSX.Element => {
  const dispatch = useDispatch();

  const lastRefetch = localStorage.getItem('currentDateCustomersProductQuotes');

  const [getCustomersLazy, { isFetching, isLoading }] = useLazyGetCustomersQuery();

  const [refetchDate, setRefetchDate] = useState(lastRefetch);

  const handleClick = async (): Promise<void> => {
    try {
      dispatch(setIsLazyFetchLoading(true));
      const response = await getCustomersLazy({
        orderByField: 'customerAccount',
        // TODO: NEEDS UPDATING, see PapaBear REDUXSTATE.salesOrder.selectedCompanyDataAreaId in sales orders and replicate
        dataAreaId: 'MDSI',
        overrideSkipTake: true
      }).unwrap();

      dispatch(setIsLazyFetchLoading(false));
      dispatch(setCachedCustomerList(response.data));
      setRefetchDate(localStorage.getItem('currentDateCustomersProductQuotes'));
      localStorage.setItem('currentDateCustomersProductQuotes', JSON.stringify(new Date()));
      message.success('Customers successfully refreshed.');
    } catch (err) {
      console.log(err);
      message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      dispatch(setIsLazyFetchLoading(false));
    }
  };

  const formatDate = (): string => {
    const dateObj = refetchDate ? new Date(JSON.parse(refetchDate)) : 'Data has never been refreshed, Click here to refresh the list of customers';

    if (typeof dateObj === 'string') return dateObj;
    const date = dateObj.toLocaleDateString();
    const time = dateObj.toLocaleTimeString();
    const formattedDate = `Data last cached on ${date} at ${time}. Click here to refresh the list of customers`;

    return formattedDate;
  };

  return (
    <Tooltip title={formatDate()}>
      <Button disabled={isLoading || isFetching} icon={<RedoOutlined />} onClick={handleClick}>
        Refresh Customer List
      </Button>
    </Tooltip>
  );
};
