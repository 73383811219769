import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Typography } from 'antd';
import useModal from 'antd/lib/modal/useModal';
import { ProductType } from 'common/helpers/dummyData';
import { SublineProductAdhocCard } from 'components/atoms/SublineProductAdhocCard';
import { useFormikContext } from 'formik';
import { SalesQuoteModel, SubLineQuotePayload } from 'models/Quotes';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setIsChild } from 'redux/slices/selectedProductsSlice';
import { ProductSearchTab } from './ProductSearchTab';
import { ProductsSearchFilters } from './ProductsSearchFilters';

interface Props {
  record: ProductType;
  isLineLevel?: boolean;
  showOptions?: string;
}

export interface SublineForm {
  quantity: string;
  listDiscountPercentage: string;
  listPrice: string;
  unitCost: string;
  itemId: string;
  productDescription: string;
}

export const AddChildModal: React.FC<Props> = ({ record, isLineLevel, showOptions }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isView = pathname.includes('/view');
  const [modal, contextHolder] = useModal();
  const [form] = Form.useForm<SubLineQuotePayload>();

  const {
    values: { lines }
  } = useFormikContext<SalesQuoteModel>();

  console.log(showOptions, '?????');

  const handleAdd = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
    dispatch(setIsChild(true));
    setIsOpen(true);
    event.stopPropagation();
  };

  const handleCloseModal = (): void => {
    dispatch(setIsChild(false));
    setIsOpen(false);
  };

  const handleAdhoc = (): void => {
    dispatch(setIsChild(true));
    modal.confirm({
      icon: undefined,
      bodyStyle: { paddingTop: 10 },
      title: (
        <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 25, padding: 10, marginBottom: 0 }}>
          Adhoc Submodule
        </Typography.Title>
      ),
      content: <SublineProductAdhocCard form={form} record={record} />,
      centered: true,
      okText: 'Submit',
      onOk: () => form.submit(),
      width: 600,
      closable: true,
      keyboard: true
    });
  };
  const titleJsx = (
    <Row align="middle" style={{ width: '100%', padding: '0 20px' }} justify="space-between">
      <Row gutter={[16, 16]} align="middle">
        <Col>
          <Typography.Title style={{ margin: 0, padding: 0, fontWeight: 150 }} level={4}>
            Add Submodules
          </Typography.Title>
        </Col>
        <Col>
          <ProductsSearchFilters />
        </Col>
        <Col>
          <Button style={{ marginLeft: 8 }} onClick={handleAdhoc} type="primary">
            Adhoc Product
          </Button>
        </Col>
      </Row>
    </Row>
  );

  return (
    <>
      {contextHolder}
      <Modal
        keyboard
        onCancel={handleCloseModal}
        closable
        title={titleJsx}
        // onOk={handleCloseModal}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={1400}
        open={isOpen}>
        <ProductSearchTab isLineLevel={isLineLevel} record={record} />
      </Modal>
      {isView ? null : (
        <PlusOutlined style={{ opacity: showOptions === lines.findIndex((line) => line === record).toString() ? 1 : 0 }} onClick={(event): void => handleAdd(event)} className="subline-add" />
      )}
    </>
  );
};
