/* eslint-disable jsx-a11y/label-has-associated-control */
import { Space } from 'antd';
import Select from 'antd/lib/select';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { samTheRobotApi } from 'redux/services/samTheRobot';
import { setOffset, setStatuses } from 'redux/services/samTheRobot/quoteParams';
import { ReduxState } from 'redux/store';

const styles: InlineStylesModel = {
  titleColor: {
    // color: '#2F5596'
  },
  selectContainer: {
    minWidth: 200,
    borderRadius: 0
  },
  labelUnfocused: {
    position: 'absolute',
    color: 'rgba(0,0,0,0.50)',
    top: 5,
    left: 10,
    zIndex: 2,
    transition: 'all 0.3s ease',
    pointerEvents: 'none',
    display: 'hidden',
    fontSize: 15,
    paddingLeft: 8
  },
  labelFocused: {
    backgroundColor: 'white',
    position: 'absolute',
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: 2,
    top: -15,
    left: 8,
    transition: 'all 0.3s ease',
    borderRadius: 5
  }
};

export const StatusFilter = (): JSX.Element => {
  const { quoteStatuses: statuses } = useSelector((state: ReduxState) => state.samTheRobotQuoteParams);

  const [inputValue, _setInputValue] = useState<string>(statuses || '');

  const [isLabelTop, _setIsLabelTop] = useState(inputValue !== '');

  const dispatch = useDispatch();

  /* ****************** Functions ****************** */
  const handleStatusChange = (value: string[]): void => {
    dispatch(setOffset(0));
    dispatch(setStatuses(String(value)));

    dispatch(samTheRobotApi.util.invalidateTags(['Quotes']));
  };

  const handleBlur = (): void => {
    if (inputValue.length === 0) {
      _setIsLabelTop(false);
    }
  };

  const handleClear = (): void => {
    dispatch(setStatuses(undefined));
  };

  // useEffect(() => {
  //   if (inputValue.length === 0) {
  //     _setIsLabelTop(false);
  //   } else {
  //     _setIsLabelTop(true);
  //   }
  //   if (inputValue.length === 2 || inputValue.length === 0) dispatch(setStatuses(undefined));
  // }, [inputValue, dispatch]);

  return (
    <Space direction="vertical" size="small">
      {/* <FancySelect onSelectChange={handleStatusChange} options={options} placeHolder="Status" defaultValue={options[0]} value={inputValue} width={150} /> */}
      <Space direction="vertical" size="small">
        {/* <Text>Status</Text> */}
        <Select
          allowClear
          onClear={handleClear}
          mode="multiple"
          filterOption={(input, option): boolean => (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
          style={styles.selectContainer}
          onChange={handleStatusChange}
          onBlur={handleBlur}
          onFocus={(): void => _setIsLabelTop(true)}>
          <Select.Option key="Draft" value="Draft">
            Draft
          </Select.Option>
          <Select.Option key="Lost" value="Lost">
            Lost
          </Select.Option>
          <Select.Option key="SentToCustomer" value="SentToCustomer">
            Sent To Customer
          </Select.Option>
          <Select.Option key="Won" value="Won">
            Won
          </Select.Option>
        </Select>
        <label style={isLabelTop || statuses ? styles.labelFocused : styles.labelUnfocused}>
          <span>Status</span>
        </label>
      </Space>
    </Space>
  );
};
