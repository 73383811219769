import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { CompanyModel } from 'models/CompanyModel';
import { FC, Fragment } from 'react';

interface Props {
  legalEntity?: CompanyModel;
}

const styles = StyleSheet.create({
  headerRow: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 16
  },
  title: {
    fontSize: 10
  },
  text: {
    fontSize: 8
  }
});

export const TOS: FC<Props> = ({ legalEntity }): JSX.Element => {
  return (
    <Fragment>
      <View style={styles.headerRow}>
        <Text style={{ fontSize: 20 }}>Terms Of Service</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Pricing</Text>
        <Text style={styles.text}>
          All Prices are exclusive of any freight, handling and shipping insurance charges, taxes, fees and duties or other similar amounts, however designated, including without limitation value
          added, sales, and withholding taxes which are levied or based upon the products delivered or services performed under this order.
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Tax</Text>
        <Text style={styles.text}>
          All taxes applicable to products ordered shall be paid by the Buyer, or in lieu thereof, Buyer shall provide {legalEntity?.dataAreaId ?? 'N/A'} with a tax exemption certificate acceptable to
          the taxing authorities. In the absence of a tax exemption certificate, taxes will be charged and payable until a valid tax exemption certificate is on file.
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Payment Terms</Text>
        <Text style={styles.text}>
          Payment terms to Buyers of satisfactory credit: Net 30 Days from Date of Invoice {legalEntity?.dataAreaId ?? 'N/A'} Inc., at its discretion, may require reasonable advance assurances of
          payment through irrevocable bank letters of credit or otherwise. A monthly interest charge of the lesser of 1 1/2 percent or the maximum permitted by law in the Buyer`s state may be added to
          all amounts not paid by the due date. Overdue and delinquent account balances are subject to being placed for collection, and Buyer shall pay all expenses incurred, including collection
          fees, court costs, and reasonable attorney fees. Buyer’s failure to make timely payment may result in such action as revocation of credit, stoppage of shipment, delay or cessation of future
          deliveries, repossession of unpaid delivered goods and termination of any one or more sales agreements. Buyer agrees that {legalEntity?.dataAreaId ?? 'N/A'}, its subsidiaries, parent, or
          affiliates may offset the account balance, or any portion thereof, against any funds due Buyer by {legalEntity?.dataAreaId ?? 'N/A'} Inc, its subsidiaries, parent, or affiliates,
          irrespective of whether the amounts arise out of the same transaction.
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Limited Warranty</Text>
        <Text style={styles.text}>
          {legalEntity?.dataAreaId ?? 'N/A'} is not the manufacturer of the products purchased by Buyer, and as such, any warranties and defense and indemnification obligations with respect thereto,
          if any, shall be limited solely to those provided by the third party manufacturer, vendor or proprietor thereof (“Third Party Vendor”) that run to the benefit of the end purchaser (and if
          such warranties and defense and indemnification obligations run to the benefit of {legalEntity?.dataAreaId ?? 'N/A'} as direct purchaser, {legalEntity?.dataAreaId ?? 'N/A'} will assign such
          warranties and defense and indemnification obligations, to Buyer, to the extent permitted by such Third Party Vendor or by applicable law.) {legalEntity?.dataAreaId ?? 'N/A'} shall not be
          deemed a party to, or have any liability with respect to or under, such warranties and defense and indemnification obligations, and, anything in this Agreement or any Order, Authorization
          Letter or Statement of Work to the contrary, {legalEntity?.dataAreaId ?? 'N/A'} MAKES NO EXPRESS AND/OR IMPLIED WARRANTIES WHETHER OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE,
          NONINFRINGEMENT, OR OTHERWISE (except as to title), and in no event does {legalEntity?.dataAreaId ?? 'N/A'} assure, nor shall it be liable for CONSEQUENTIAL OR SPECIAL damages, installation
          adjustments or other expenses, whether direct or indirect.
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Buyer`s Purchase Order - Conflict of Terms</Text>
        <Text style={styles.text}>
          In the event Buyer shall submit purchase orders and the written terms of which are at variance or conflict with the terms and conditions of sale contained herein, such purchase order terms
          shall have no effect to the extent that they may conflict, and the {legalEntity?.dataAreaId ?? 'N/A'} terms and conditions of sale shall prevail.
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Delivery</Text>
        <Text style={styles.text}>
          Deliveries shall be subject to and contingent upon timely receipt of order by {legalEntity?.dataAreaId ?? 'N/A'}, together with Buyer qualification of credit requirements; and{' '}
          {legalEntity?.dataAreaId ?? 'N/A'} shall not be liable for failure to meet required delivery due to credit clearance requirements or causes beyond its control, including and without
          limitation, unavailability of product from {legalEntity?.dataAreaId ?? 'N/A'} source of supply, strikes and other labor difficulty, riot, war, fire, delay or default of common carrier, or
          other delays beyond {legalEntity?.dataAreaId ?? 'N/A'} reasonable control.
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Discrepancy Claim - Failed Delivery Claims</Text>
        <Text style={styles.text}>
          Merchandise is shipped FOB shipping point. Risk of loss due to damage or shortage or non-delivery due to carrier fault lies with the Buyer. All claims for damage or shortages should be made
          by Buyer upon receipt of material and filed with the carrier handling the shipment. Claims resulting from discrepancies between invoiced descriptions or quantities and actual product
          received by Buyer due to error by {legalEntity?.dataAreaId ?? 'N/A'} must be made in writing within thirty (30) days of invoice date. Any such claim not presented by Buyer within the time
          limit specified will be waived and actual delivery of invoiced descriptions or quantities will be conclusively presumed. Any Buyer who wishes to dispute a delivery of merchandise may make
          written request upon {legalEntity?.dataAreaId ?? 'N/A'} for a copy of carriers` proof of delivery within thirty (30) days from date of invoice. Failure by Buyer to request such proof of
          delivery within the 30-day time period will result in a waiver of Buyer`s right to raise the issue of delivery, and thereafter delivery will be conclusively presumed.
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Product Installation and Operation</Text>
        <Text style={styles.text}>
          Buyer assumes all responsibility for the proper selection, installation, operation, and maintenance of the merchandise purchased from {legalEntity?.dataAreaId ?? 'N/A'}.{' '}
          {legalEntity?.dataAreaId ?? 'N/A'} SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY CONSEQUENTIAL, CONTINGENT, SPECIAL, OR INCIDENTAL DAMAGES whatsoever, except as specifically set forth in the
          Limited Warranty
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Returned Material</Text>
        <Text style={styles.text}>
          No product or equipment of any kind shall be returned without prior approval and specific shipping instructions from {legalEntity?.dataAreaId ?? 'N/A'}. Returns must be received by{' '}
          {legalEntity?.dataAreaId ?? 'N/A'} within 30 days of original receipt of goods in order to receive credit.
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Restock Charge</Text>
        <Text style={styles.text}>
          Unless otherwise agreed, a 20% restocking fee will be assessed upon the return of products because of Buyer ordering error, when the product has suffered damage while in Buyer`s possession,
          late cancellation of order, return beyond 30 days from original receipt, or when assessed by {legalEntity?.dataAreaId ?? 'N/A'} suppliers.
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Alteration of Terms and Conditions</Text>
        <Text style={styles.text}>No alteration or waiver of the terms contained herein shall be effective unless such authorization or waiver is in writing, signed by a duly authorization</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Presumption as to Authority of Buyer`s Personnel</Text>
        <Text style={styles.text}>
          {legalEntity?.dataAreaId ?? 'N/A'} assumes and is entitled to rely upon the apparent authority of all Buyers’ employees and agents in placing orders under Buyer`s account.
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Change of Buyer`s Name or Address; Reorganization</Text>
        <Text style={styles.text}>
          Buyer hereby agrees to notify {legalEntity?.dataAreaId ?? 'N/A'} Credit Department in writing of any changes of name or address, or of any corporate reorganization or change of ownership
          which results in a change of name or location of the Buyer.
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.title}>Acceptance of Sales Orders</Text>
        <Text style={styles.text}>All sales are subject to acceptance and no sales are final until accepted by {legalEntity?.dataAreaId ?? 'N/A'} at its principal place of business</Text>
      </View>
    </Fragment>
  );
};
