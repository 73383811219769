import { Col, Row, Space, Typography } from 'antd';
import { CustomerIdFilter } from 'components/atoms/CustomerIdFilter';
import { CustomerNameFilter } from 'components/atoms/CustomerNameFilter';
import { RefetchButton } from 'components/atoms/RefetchButton';
import { CustomerSelectionGrid } from 'components/molecules/CustomerSelectionGrid';
import { InlineStylesModel } from 'models/InlineStylesModel';

const styles: InlineStylesModel = {
  searchRow: {
    padding: 14,

    backgroundColor: '#b0b2bc',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  searchTitle: {
    marginBottom: 0
  }
};

export const CustomerSelect = (): JSX.Element => {
  const isMobileScreen = Boolean(window.screen.width < 420);

  /* ****************** Render ****************** */
  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Space style={styles.searchRow}>
          <Row>
            <Space>
              <Typography.Title level={4} style={styles.searchTitle}>
                Search Customers
              </Typography.Title>
              <RefetchButton />
            </Space>
          </Row>
          <div>
            {isMobileScreen ? null : <Typography.Text style={{ color: 'black' }}>Search Filters &nbsp;</Typography.Text>}
            <CustomerIdFilter />
            <CustomerNameFilter />
          </div>
        </Space>
      </Space>
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <CustomerSelectionGrid />
        </Col>
      </Row>
    </>
  );
};
