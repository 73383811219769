import { StyleSheet, View } from '@react-pdf/renderer';
import { ProductType } from 'common/helpers/dummyData';
import { QuotePayload } from 'models/Quotes';
import InvoiceTableFooterDiscount from './InvoiceTableFooterDiscounts';
import InvoiceTableHeaderDiscount from './InvoiceTableHeaderDiscount';
import InvoiceTableRowDiscount from './InvoiceTableRowDiscount';

// const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderColor: 'rgb(132, 133, 154)'
  }
});

const InvoiceItemsTableDiscount = ({ products }: { invoice: QuotePayload; products: ProductType[] }): JSX.Element => {
  return (
    <View style={styles.tableContainer}>
      <InvoiceTableHeaderDiscount />
      <InvoiceTableRowDiscount lines={products} />
      <InvoiceTableFooterDiscount items={products} />
    </View>
  );
};

export default InvoiceItemsTableDiscount;
