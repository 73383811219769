import { Menu, Space } from 'antd';
import { customerLottie, deliveryLottie, headerInfoLottie, productsLottie, reviewOrderLottie } from 'common/assets/customer-lottie';
import { LottieIcon } from 'components/atoms/LottieIcon';
import { useFormikContext } from 'formik';
import { useGetWizardStep } from 'hooks/useGetWizardStep';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { QuotePayload } from 'models/Quotes';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import attachmentLottie from '../../common/assets/attachment.json';
import emailLottie from '../../common/assets/email-lottie.json';

export enum CreateRequestMenu {
  CustomerSelection = 'customer-selection',
  HeaderInfo = 'header-info',
  DeliveryDetails = 'delivery-address',
  Products = 'products',
  Overview = 'quote',
  Email = 'email-info',
  Attachments = 'attachments'
}

const styles: InlineStylesModel = {
  wrapper: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    overflow: 'hidden'
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 40
  },
  navIcon: {
    paddingRight: 20,
    paddingLeft: 20,
    cursor: 'pointer',
    border: 0,
    backgroundColor: 'transparent'
  }
};

export const StepControls = (): JSX.Element => {
  const dispatch = useDispatch();
  const { wizardStep } = useGetWizardStep();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { values } = useFormikContext<QuotePayload>();

  const [playLottie, setPlayLottie] = useState(false);

  const customerStepGreen = Boolean(values.customerAccount);
  const headerInfoStepGreen = Boolean(values.quoteName);
  const deliveryStepGreen = Boolean(values.deliveryAddressLocationId);
  const productStepGreen = Boolean(values.lines.length > 0);
  const emailStepGreen = Boolean(values.customerEmailAddresses.length > 0);
  const attachmentStepGreen = Boolean(values.attachments && values.attachments.length > 0);

  const isDuplicatePage = pathname.includes('duplicate');
  const isViewPage = pathname.includes('view');

  const handleNavigationCreate = (info: { key: string }): void => {
    if (info.key === CreateRequestMenu.CustomerSelection) {
      navigate('/create');
    } else if (info.key === CreateRequestMenu.HeaderInfo) {
      navigate('/create/header-info');
    } else if (info.key === CreateRequestMenu.Email) {
      navigate('/create/email-info');
    } else if (info.key === CreateRequestMenu.Attachments) {
      navigate('/create/attachments');
    } else if (info.key === CreateRequestMenu.Products) {
      navigate('/create/products');
    } else if (info.key === CreateRequestMenu.DeliveryDetails) {
      navigate('/create/delivery-address');
    } else if (info.key === CreateRequestMenu.Overview) {
      navigate('/create/quote');
    }
  };

  const handleNavigationEdit = (info: { key: string }): void => {
    if (info.key === CreateRequestMenu.CustomerSelection) {
      navigate(`/edit/${id}`);
    } else if (info.key === CreateRequestMenu.HeaderInfo) {
      navigate(`/edit/${id}/header-info`);
    } else if (info.key === CreateRequestMenu.Email) {
      navigate(`/edit/${id}/email-info`);
    } else if (info.key === CreateRequestMenu.Attachments) {
      navigate(`/edit/${id}/attachments`);
    } else if (info.key === CreateRequestMenu.Products) {
      navigate(`/edit/${id}/products`);
    } else if (info.key === CreateRequestMenu.DeliveryDetails) {
      navigate(`/edit/${id}/delivery-address`);
    } else if (info.key === CreateRequestMenu.Overview) {
      navigate(`/edit/${id}/quote`);
    }
  };
  const handleNavigationView = (info: { key: string }): void => {
    if (info.key === CreateRequestMenu.CustomerSelection) {
      navigate(`/view/${id}`);
    } else if (info.key === CreateRequestMenu.HeaderInfo) {
      navigate(`/view/${id}/header-info`);
    } else if (info.key === CreateRequestMenu.Email) {
      navigate(`/view/${id}/email-info`);
    } else if (info.key === CreateRequestMenu.Attachments) {
      navigate(`/view/${id}/attachments`);
    } else if (info.key === CreateRequestMenu.Products) {
      navigate(`/view/${id}/products`);
    } else if (info.key === CreateRequestMenu.DeliveryDetails) {
      navigate(`/view/${id}/delivery-address`);
    } else if (info.key === CreateRequestMenu.Overview) {
      navigate(`/view/${id}/quote`);
    }
  };
  const handleNavigationDuplicate = (info: { key: string }): void => {
    if (info.key === CreateRequestMenu.CustomerSelection) {
      navigate(`/duplicate/${id}`);
    } else if (info.key === CreateRequestMenu.HeaderInfo) {
      navigate(`/duplicate/${id}/header-info`);
    } else if (info.key === CreateRequestMenu.Products) {
      navigate(`/duplicate/${id}/products`);
    } else if (info.key === CreateRequestMenu.Email) {
      navigate(`/duplicate/${id}/email-info`);
    } else if (info.key === CreateRequestMenu.Attachments) {
      navigate(`/duplicate/${id}/attachments`);
    } else if (info.key === CreateRequestMenu.DeliveryDetails) {
      navigate(`/duplicate/${id}/delivery-address`);
    } else if (info.key === CreateRequestMenu.Overview) {
      navigate(`/duplicate/${id}/quote`);
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Menu
        onClick={isDuplicatePage ? handleNavigationDuplicate : id ? (isViewPage ? handleNavigationView : handleNavigationEdit) : handleNavigationCreate}
        mode="horizontal"
        style={{ justifyContent: 'center', height: 48 }}
        selectedKeys={[wizardStep]}>
        <Menu.Item key={CreateRequestMenu.CustomerSelection} onMouseEnter={(): void => setPlayLottie(true)} onMouseLeave={(): void => setPlayLottie(false)}>
          <LottieIcon required title="Customer" complete={customerStepGreen} lottieFile={customerLottie} />
        </Menu.Item>
        <Menu.Item key={CreateRequestMenu.HeaderInfo}>
          <LottieIcon required title="General" speed={0.7} complete={headerInfoStepGreen} lottieFile={headerInfoLottie} />
        </Menu.Item>
        <Menu.Item key={CreateRequestMenu.Email}>
          <LottieIcon title="Email Info" complete={emailStepGreen} lottieFile={emailLottie} />
        </Menu.Item>
        <Menu.Item key={CreateRequestMenu.DeliveryDetails}>
          <LottieIcon title="Delivery Address" complete={deliveryStepGreen} lottieFile={deliveryLottie} />
        </Menu.Item>
        <Menu.Item key={CreateRequestMenu.Products}>
          <LottieIcon title="Products" complete={productStepGreen} lottieFile={productsLottie} />
        </Menu.Item>
        <Menu.Item key={CreateRequestMenu.Attachments}>
          <LottieIcon title="Attachments" complete={attachmentStepGreen} lottieFile={attachmentLottie} />
        </Menu.Item>
        <Menu.Item key={CreateRequestMenu.Overview}>
          <LottieIcon title="Review Quote" complete={null} lottieFile={reviewOrderLottie} />
        </Menu.Item>
      </Menu>
    </Space>
  );
};
