import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Form, message, Modal, Spin } from 'antd';
import { QuoteBreadcrumb } from 'components/atoms/QuoteBreadcrumb';
import { ActionControls } from 'components/common/ActionControls';
import { Loader } from 'components/common/Loader';
import { StepControls } from 'components/common/StepControls';
import { FormikProvider, useFormik } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { QuotePayload } from 'models/Quotes';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCreateAttachmentMutation, useCreateQuoteMutation, useGetQuoteQuery } from 'redux/services/samTheRobot/samTheRobotApi';
import { setQuoteSubmissionStatusMessage } from 'redux/slices/quoteSlice';
import { useAppSelector } from 'redux/store';

const styles: InlineStylesModel = {
  title: {
    marginTop: 500,
    marginBottom: 0
  }
};

export const DuplicateLayout = (): JSX.Element => {
  const dispatch = useDispatch();
  const [createQuote, { isLoading: quoteSubmitting }] = useCreateQuoteMutation();
  const { quoteSubmissionStatusMessage, selectedCompanyDataAreaId } = useAppSelector((state) => state.orders);
  const nav = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();

  const { quoteToDuplicate } = useAppSelector((state) => state.duplicateQuoteSlice);

  const isHeaderInfo = pathname.includes('header-info');
  const isDeliveryAddress = pathname.includes('delivery-address');
  const isProducts = pathname.includes('products');
  const isOverview = pathname.includes('quote');
  const currentPathname = isHeaderInfo ? 'header-info' : isDeliveryAddress ? 'delivery-address' : isProducts ? 'products' : isOverview ? 'quote' : '';
  const { data: dupeData, isLoading, isFetching, error, isError } = useGetQuoteQuery(id ?? skipToken);

  console.log(dupeData, 'dupe data');
  const [createAttachment, { isLoading: isCreatingAttachment }] = useCreateAttachmentMutation();

  const formik = useFormik<QuotePayload>({
    initialValues: {
      attachments: [],
      buyerId: dupeData?.buyerId ?? '',
      buyerName: dupeData?.buyerName ?? '',
      customerErpId: dupeData?.customerErpId ?? '',
      customerAccount: dupeData?.customerErpId ?? '',
      customerName: dupeData?.customerName ?? '',
      customerDataAreaId: dupeData?.dataAreaId ?? '',
      customerContactName: dupeData?.customerContactName ?? '',
      customerContactPhone: dupeData?.customerContactPhone ?? '',
      customerEmailAddress: dupeData?.customerEmailAddress ?? '',
      customerEmailAddresses: dupeData?.customerEmailAddresses ?? [],
      createdByUserFullName: dupeData?.createdByUserFullName ?? '',
      emailMessage: dupeData?.emailMessage ?? '',
      dataAreaId: dupeData?.dataAreaId ?? '',
      isActive: dupeData?.isActive ?? true,
      lines: dupeData?.lines ?? [],
      notes: dupeData?.notes ?? '',
      quoteName: dupeData?.quoteName ? dupeData?.quoteName : '',
      quoteExpiresInDays: dupeData ? dupeData.quoteExpiresInDays : 45,
      quoteExpirationDate: dupeData ? dupeData.quoteExpirationDate : '',
      totalCost: dupeData ? dupeData.totalCost : 0,
      totalPrice: dupeData ? dupeData.totalPrice : 0,
      totalProfit: dupeData ? dupeData.totalProfit : 0,
      totalMargin: dupeData ? dupeData.totalMargin : 0,
      salesPersonName: dupeData?.salesPersonName ?? '',
      salesPersonId: dupeData?.salesPersonId ?? '',
      deliveryAddressStreet1: dupeData ? dupeData?.deliveryAddress?.street1 : '',
      deliveryAddressCity: dupeData ? dupeData?.deliveryAddress?.city : '',
      deliveryAddressCountry: dupeData ? dupeData?.deliveryAddress?.country : '',
      deliveryAddressCounty: dupeData ? dupeData?.deliveryAddress?.country : '',
      deliveryAddressSubdivision: dupeData ? dupeData?.deliveryAddress?.subdivision : '',
      deliveryAddressPostalCode: dupeData ? dupeData?.deliveryAddress?.postalCode : '',
      deliveryAddressName: dupeData ? dupeData?.deliveryAddress?.name : '',
      deliveryAddressLocationId: dupeData ? dupeData?.deliveryAddress?.locationId : '',
      newQuote: true,
      closeWizard: true
    },
    onSubmit: async (values) => {
      if (!selectedCompanyDataAreaId) return message.error('Legal entity not selected, please refresh the page and try again');

      const totalPrice = values.lines.reduce((accumulator, quoteLineObject) => {
        return accumulator + Number(quoteLineObject.extendedPrice || 0);
      }, 0);

      try {
        const data = await createQuote({
          ...values,
          dataAreaId: selectedCompanyDataAreaId,
          customerErpId: values.customerAccount,
          totalPrice
        }).unwrap();

        if (values.attachments) {
          dispatch(setQuoteSubmissionStatusMessage('Creating attachments'));
          const formData = new FormData();

          for (const attachment of values.attachments) {
            let index = 0;

            dispatch(setQuoteSubmissionStatusMessage(`Creating attachments ${index}/${values.attachments.length}`));

            formData.append(attachment.name, attachment);

            const attachmentResp = await createAttachment({ file: formData, quoteId: data?.id as string } ?? { skip: !data.id }).unwrap();

            formData.delete(attachment.name);
            index++;
          }
        }

        Modal.success({
          afterClose: () => nav(values.closeWizard ? `/search` : `/edit/${data.id}/${currentPathname}`),
          title: 'Quote successfully created',
          content: `Quote Number: ${data.quoteId}`
        });
      } catch (error) {
        console.log(error);
        message.error((error as { data: { errorMessage: string } }).data.errorMessage);
      }
    }
  });

  console.log(formik.values, 'new dupe vals');

  if (isLoading || isFetching) return <Loader />;

  return (
    <FormikProvider value={formik}>
      <Spin spinning={quoteSubmitting} indicator={<Loader message={quoteSubmissionStatusMessage || ''} />}>
        <Form style={styles.container} layout="vertical">
          <ActionControls />
          <QuoteBreadcrumb />
          <StepControls />
          <Outlet />
        </Form>
      </Spin>
    </FormikProvider>
  );
};
