import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SalesQuoteModel } from 'models/Quotes';

interface InitialState {
  selectedSalesOrders: SalesQuoteModel[];
}

const initialState: InitialState = {
  selectedSalesOrders: []
};

export const selectedSalesOrdersSlice = createSlice({
  name: 'selectedSalesOrders',
  initialState,
  reducers: {
    setSelectedSalesOrders: (state, { payload }: PayloadAction<SalesQuoteModel[]>) => {
      state.selectedSalesOrders = payload;
    },
    addSelectedSalesOrder: (state, { payload }: PayloadAction<SalesQuoteModel>) => {
      state.selectedSalesOrders = [...state.selectedSalesOrders, payload];
    },
    removeSelectedSalesOrder: (state, { payload }: PayloadAction<SalesQuoteModel>) => {
      state.selectedSalesOrders = state.selectedSalesOrders.filter((item) => item.id !== payload.id);
    }
  }
});

export const { addSelectedSalesOrder, removeSelectedSalesOrder, setSelectedSalesOrders } = selectedSalesOrdersSlice.actions;
