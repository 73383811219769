import { EmailInfoStep } from 'components/molecules/EmailInfoStep';
import { AttachmentsStep } from 'components/organisms/AttachmentsStep';
import { About } from 'pages/about/About';
import { CreateLayout } from 'pages/create/CreateLayout';
import { CustomerSelect } from 'pages/create/CustomerSelect';
import { DeliveryAddress } from 'pages/create/DeliveryAddress';
import { HeaderInfoStep } from 'pages/create/HeaderInfoStep';
import { Overview } from 'pages/create/Overview';
import { Products } from 'pages/create/Products';
import { DuplicateLayout } from 'pages/duplicate/DuplicateLayout';
import { EditLayout } from 'pages/edit/EditLayout';
import { EditProfilePage } from 'pages/editProfile/EditProfile';
import { Home } from 'pages/home/Home';
import { Recover } from 'pages/recover/Recover';
import { ReleaseNotesPage } from 'pages/releaseNotes/ReleaseNotesPage';
import { ViewLayout } from 'pages/view/ViewLayout';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/release-notes/:version" element={<ReleaseNotesPage />} />
      <Route path="/search" element={<Home />} />
      <Route path="/profile" element={<EditProfilePage />} />
      <Route path="/recover" element={<Recover />} />
      <Route path="create" element={<CreateLayout />}>
        <Route index element={<CustomerSelect />} />
        <Route path="header-info" element={<HeaderInfoStep />} />
        <Route path="delivery-address" element={<DeliveryAddress />} />
        <Route path="products" element={<Products />} />
        <Route path="attachments" element={<AttachmentsStep />} />
        <Route path="email-info" element={<EmailInfoStep />} />
        <Route path="quote" element={<Overview />} />
      </Route>
      <Route path="edit/:id" element={<EditLayout />}>
        <Route index element={<CustomerSelect />} />
        <Route path="header-info" element={<HeaderInfoStep />} />
        <Route path="delivery-address" element={<DeliveryAddress />} />
        <Route path="attachments" element={<AttachmentsStep />} />
        <Route path="products" element={<Products />} />
        <Route path="email-info" element={<EmailInfoStep />} />
        <Route path="quote" element={<Overview />} />
      </Route>
      <Route path="view/:id" element={<ViewLayout />}>
        <Route index element={<CustomerSelect />} />
        <Route path="header-info" element={<HeaderInfoStep />} />
        <Route path="delivery-address" element={<DeliveryAddress />} />
        <Route path="attachments" element={<AttachmentsStep />} />
        <Route path="products" element={<Products />} />
        <Route path="email-info" element={<EmailInfoStep />} />
        <Route path="quote" element={<Overview />} />
      </Route>
      <Route path="duplicate/:id" element={<DuplicateLayout />}>
        <Route index element={<CustomerSelect />} />
        <Route path="header-info" element={<HeaderInfoStep />} />
        <Route path="attachments" element={<AttachmentsStep />} />
        <Route path="delivery-address" element={<DeliveryAddress />} />
        <Route path="products" element={<Products />} />
        <Route path="email-info" element={<EmailInfoStep />} />
        <Route path="quote" element={<Overview />} />
      </Route>
    </Routes>
  );
};
