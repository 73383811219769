import { Button, Form, Input } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useField, useFormik, useFormikContext } from 'formik';
import { rest } from 'lodash';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { QuotePayload } from 'models/Quotes';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

const styles: InlineStylesModel = {
  formItem: {
    marginBottom: 10
  },
  formItemMobile: {
    marginBottom: 10,
    backgroundColor: 'rgba(150,150,150,.1)',
    padding: 4,
    paddingLeft: 12,
    borderRadius: 5,
    border: '1px solid rgba(100,100,100,.1)'
  },
  formItemCol: {
    marginRight: '16px',
    maxHeight: '42px'
  },
  titleColor: {
    color: '#2F5596'
  }
};

const subscribeSchema = yup.object({
  email: yup.string().label('Email').email('Please enter a valid email address.')
});

export const SubscribeInput = (): JSX.Element => {
  const { setFieldValue, values: contextValues, setFieldError } = useFormikContext<QuotePayload>();
  const [a, { error }, c] = useField('customerEmailAddresses');
  const { pathname } = useLocation();
  const isView = pathname.includes('/view');

  const { touched, values, handleBlur, handleChange, errors, handleSubmit, setErrors } = useFormik({
    validationSchema: subscribeSchema,
    initialValues: {
      email: ''
    },
    onSubmit: async ({ email }, { resetForm }) => {
      if (contextValues.customerEmailAddresses.includes(email)) {
        setErrors({ email: 'Email already exists in list' });

        return;
      }
      setFieldValue('customerEmailAddresses' as keyof DeploymentRequestPayload, [...(contextValues.customerEmailAddresses ?? []), email]);
      resetForm();
    }
  });

  const handleEnterPressed = (): void => {
    if (!errors.email) {
      handleSubmit();
    }
  };

  // const handleEmailChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
  //   setVal(ev.target.value);
  // };

  return (
    <Form.Item labelAlign="left" validateStatus={errors.email ? 'error' : 'success'} help={touched ? errors.email : undefined} style={{ marginBottom: 0 }}>
      <Title level={5} style={styles.titleColor}>
        Customer Email Addreses
      </Title>
      {!isView && (
        <Input.Group style={{ display: 'flex' }} compact>
          <Input {...rest} disabled={isView} onPressEnter={handleEnterPressed} value={values.email || undefined} onChange={handleChange('email')} onBlur={handleBlur('email')} style={{ flex: 1 }} />
          <Button type="primary" disabled={Boolean(errors.email || isView)} onClick={(): void => handleSubmit()}>
            Add
          </Button>
        </Input.Group>
      )}
    </Form.Item>
  );
};
