import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  productsView: 'cart' | 'grid';
  spinnerActive: boolean;
}

const initialState: InitialState = {
  productsView: 'grid',
  spinnerActive: false
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setProductsView: (state, { payload }: PayloadAction<InitialState['productsView']>) => {
      state.productsView = payload;
    },
    setSpinnerActive: (state, { payload }: PayloadAction<InitialState['spinnerActive']>) => {
      state.spinnerActive = payload;
    }
  }
});

export const { setProductsView, setSpinnerActive } = formSlice.actions;
