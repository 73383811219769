import { GoldOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Popover, Row, Tooltip, Typography } from 'antd';
import { moneyFormatter } from 'common/helpers/CurrencyFormatter';
import { colors, toRgb } from 'common/styles/colors';
import { IconMenu } from 'components/molecules/IconMenu';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { SalesQuoteModel } from 'models/Quotes';
import { useState } from 'react';

interface Props {
  salesQuote: SalesQuoteModel;
}

const styles: InlineStylesModel = {
  card: {
    borderRadius: 5
  },
  cardBody: {
    padding: 10,
    paddingRight: 15,
    paddingLeft: 15
  },
  largeText: {
    fontSize: '18px',
    fontFamily: 'Poppins',
    fontWeight: 700,
    color: '#000'
  },
  title: {
    fontSize: '15px',
    fontWeight: 500,
    color: '#636770'
  },
  subTitle: {
    fontSize: '15px',
    color: '#A0A3AE',
    minHeight: '27px'
  },
  text: {
    fontSize: '13px',
    color: '#A0A3AE'
  },
  smallText: {
    fontSize: '11px',
    color: 'lightGrey'
  },
  status: {
    fontSize: '16px',
    fontWeight: 700
  },
  draft: {
    color: toRgb(colors.orangeWeb)
  },
  sent: {
    color: toRgb(colors.robinEggBlue)
  },
  awarded: {
    color: toRgb(colors.illuminatingEmerald)
  },
  cancelled: {
    color: toRgb(colors.redSalsa)
  },
  lost: {
    color: toRgb(colors.redSalsa)
  },
  soNumber: {
    fontSize: '14px',
    color: '#5868DD'
  },
  poNumber: {
    fontSize: '14px',
    color: 'gray'
  },
  customerId: {
    fontSize: '16px',
    color: '#636770'
  },
  iconMenuButton: {
    width: '100%'
  },
  iconMenuButtonDuplicate: {
    width: '100%',
    color: toRgb(colors.illuminatingEmerald)
  }
};

export const SalesQuoteLinkedCard: React.FC<Props> = ({ salesQuote }: Props) => {
  const [open, setOpen] = useState(false);

  /* ****************** Functions ****************** */

  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen);
  };
  const handleCloseChange = (): void => {
    setOpen(false);
  };

  return (
    <Card style={styles.card} bodyStyle={styles.cardBody} hoverable>
      <Row gutter={8} justify="space-between">
        <Col span={18}>
          <Typography.Text ellipsis={{ tooltip: `${salesQuote.quoteId}` }} style={styles.largeText}>
            <Tooltip title="Quote ID" placement="right" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {salesQuote.quoteId}
              {salesQuote.quoteName ? `--${salesQuote.quoteName}` : ''}
            </Tooltip>
          </Typography.Text>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Typography
            style={
              salesQuote.status === 'Draft'
                ? { ...styles.status, ...styles.draft }
                : salesQuote.status === 'Won'
                ? { ...styles.status, ...styles.awarded }
                : salesQuote.status === 'Lost'
                ? { ...styles.status, ...styles.lost }
                : salesQuote.status === 'SentToCustomer'
                ? { ...styles.status, ...styles.sent }
                : salesQuote.status === 'Cancelled'
                ? { ...styles.status, ...styles.cancelled }
                : styles.status
            }>
            <Tooltip title="Status" placement="left" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {salesQuote.convertedToSalesOrder ? 'SALE' : salesQuote.status === 'SentToCustomer' ? 'Sent' : salesQuote.status}
            </Tooltip>
          </Typography>
        </Col>
      </Row>
      <Row gutter={8} justify="space-between">
        <Col>
          <Typography style={styles.subTitle}>
            <Tooltip title="Customer Name" placement="right" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {salesQuote.customerName}
            </Tooltip>
          </Typography>
        </Col>
        <Col>
          <Typography style={styles.customerId}>
            <Tooltip title="Quote Total" placement="right" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {moneyFormatter.format(Number(salesQuote.totalPrice))}
            </Tooltip>
          </Typography>
        </Col>
      </Row>
      <Row gutter={8} justify="space-between">
        <Col>
          <Typography style={styles.subTitle}>
            <Tooltip title="Customer ID" placement="right" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {salesQuote.customerErpId}
            </Tooltip>
          </Typography>
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <Typography style={styles.text}>
            <Tooltip title="Created By" placement="left" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {salesQuote.createdByUserFullName}
            </Tooltip>
          </Typography>
        </Col>
      </Row>
      <Row gutter={8} justify="space-between">
        <Col></Col>
        <Col onClick={(e): void => e.stopPropagation()} style={{ textAlign: 'right' }}>
          <Popover
            content={<IconMenu closePopover={handleCloseChange} salesQuote={salesQuote} />}
            trigger="click"
            placement="left"
            open={open}
            onOpenChange={handleOpenChange}
            overlayInnerStyle={{ margin: 0, padding: 0, borderRadius: 10, width: 200 }}>
            <GoldOutlined
              onClick={(e): void => {
                e.stopPropagation();
              }}
              style={{ fontSize: 24 }}
            />
          </Popover>
        </Col>
      </Row>
      <Divider />
      <Row justify="space-between">
        <Col style={{ textAlign: 'start', marginLeft: 10 }} span={10}>
          <Typography.Paragraph style={{ fontSize: '14px', color: 'gray' }} ellipsis={{ tooltip: salesQuote.createdByUserFullName }}>
            <Tooltip title="Buyer" placement="top" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {salesQuote.buyerName ? salesQuote.buyerName : 'N/A'}
            </Tooltip>
          </Typography.Paragraph>
          <Typography.Paragraph style={{ fontSize: '14px', color: 'gray' }} ellipsis={{ tooltip: salesQuote.createdByUserFullName }}>
            <Tooltip title="Salesperson" placement="top" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {salesQuote.salesPersonName ? salesQuote.salesPersonName : 'N/A'}
            </Tooltip>
          </Typography.Paragraph>
        </Col>
        <Row>
          <Col span={2}>
            <Divider type="vertical" style={{ fontSize: 80 }} />
          </Col>
        </Row>

        <Col style={{ textAlign: 'end', marginRight: 10 }} span={10}>
          <Typography.Paragraph style={{ fontSize: '14px', color: 'gray' }}>
            <Tooltip title="Creation Date" placement="top" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {salesQuote.createdDateTime ? new Date(salesQuote.createdDateTime).toLocaleDateString() : 'N/A'}
            </Tooltip>
          </Typography.Paragraph>
          <Typography.Paragraph style={{ fontSize: '14px', color: 'gray' }}>
            <Tooltip title="Modified Date" placement="bottom" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {salesQuote.modifiedDateTime ? new Date(salesQuote.createdDateTime).toLocaleDateString() : 'N/A'}
            </Tooltip>
          </Typography.Paragraph>
        </Col>
      </Row>
    </Card>
  );
};
