import { Input, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { QuotePayload } from 'models/Quotes';
import { useLocation } from 'react-router-dom';
const { Title } = Typography;

const styles: InlineStylesModel = {
  formItem: {
    marginBottom: 10
  },
  formItemCol: {
    marginRight: '16px',
    maxHeight: '42px'
  },
  titleColor: {
    color: '#2F5596'
  }
};

export const QuoteIdInput = (): JSX.Element => {
  const {
    values: { quoteId },
    handleChange
  } = useFormikContext<QuotePayload>();

  const { pathname } = useLocation();
  const isView = pathname.includes('view');

  return (
    <>
      <Title level={5} style={styles.titleColor}>
        Quote ID
      </Title>
      <Input disabled={true} name="quoteId" defaultValue="AUTOGENERATED ID" value={quoteId ?? undefined} allowClear onChange={handleChange} maxLength={30} style={styles.formItem} />
    </>
  );
};
