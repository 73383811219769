import { Form, Input, InputNumber, Popover, Typography } from 'antd';
import { ProductType } from 'common/helpers/dummyData';
import { useState } from 'react';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'number' | 'text';
  record: ProductType;
  index: number;
  children: React.ReactNode;
}

export const SublineEditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, inputType, children, ...restProps }: EditableCellProps) => {
  const form = Form.useFormInstance();
  const smallInputList = ['Discount', 'Quantity'];
  const [errMessage, setErrorMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const handleInputChnage = (): void => {
    if (form.getFieldValue(dataIndex) === '' || form.getFieldValue(dataIndex) === null) {
      setErrorMessage(`${dataIndex} is required`);
      setIsOpen(true);
    } else if (form.getFieldValue(dataIndex) < 0) {
      setErrorMessage(`${dataIndex} cant be negative`);
      setIsOpen(true);
    } else setIsOpen(false);
  };

  const handleProductChange = (): void => {
    if (form.getFieldValue('productNumber').length > 30) {
      setErrorMessage('Product number cannot be longer than 30 characters');
      setIsOpen(true);
    } else if (!form.getFieldValue('productNumber')) {
      setErrorMessage('Product number cannot be empty');
      setIsOpen(true);
    } else setIsOpen(false);
  };

  const inputNode =
    dataIndex === 'quantity' ? (
      <InputNumber
        onKeyDown={(e): void => {
          if (e.key === '.') e.preventDefault();
        }}
        min={1}
        onChange={handleInputChnage}
        style={{ width: '100%', margin: 0 }}
        controls={false}
      />
    ) : dataIndex === 'productNumber' || dataIndex === 'productDescription' ? (
      <Input onChange={handleProductChange} />
    ) : inputType === 'number' ? (
      <InputNumber onChange={handleInputChnage} style={{ width: '100%', margin: 0 }} controls={false} />
    ) : (
      <Input onChange={handleInputChnage} />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Popover open={isOpen} content={<Typography.Text style={{ color: 'red' }}>{errMessage}</Typography.Text>}>
          <Form.Item name={dataIndex} style={{ margin: 0 }}>
            {inputNode}
          </Form.Item>
        </Popover>
      ) : (
        children
      )}
    </td>
  );
};
