import { CreateRequestMenu } from 'components/common/StepControls';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useGetWizardStep = (): { wizardStep: string } => {
  const { pathname } = useLocation();
  const wizardStep = useMemo(() => {
    if (pathname.includes(CreateRequestMenu.HeaderInfo)) {
      return CreateRequestMenu.HeaderInfo;
    } else if (pathname.includes(CreateRequestMenu.Products)) {
      return CreateRequestMenu.Products;
    } else if (pathname.includes(CreateRequestMenu.Email)) {
      return CreateRequestMenu.Email;
    } else if (pathname.includes(CreateRequestMenu.Attachments)) {
      return CreateRequestMenu.Attachments;
    } else if (pathname.includes(CreateRequestMenu.DeliveryDetails)) {
      return CreateRequestMenu.DeliveryDetails;
    } else if (pathname.includes(CreateRequestMenu.Overview)) {
      return CreateRequestMenu.Overview;
    } else {
      return CreateRequestMenu.CustomerSelection;
    }
  }, [pathname]);

  return { wizardStep };
};
