import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductType } from 'common/helpers/dummyData';

interface InitialState {
  selectedProducts: ProductType[];
  products: ProductType[];
  isChild: boolean;
  duplicateProducts: ProductType[];
  isSpin: boolean;
}

const initialState: InitialState = {
  selectedProducts: [],
  products: [],
  isChild: false,
  duplicateProducts: [],
  isSpin: false
};

export const selectedProducts = createSlice({
  name: 'selectedProducts',
  initialState,
  reducers: {
    setSelectedProducts: (state, { payload }: PayloadAction<ProductType[]>) => {
      state.selectedProducts = payload;
    },
    addProduct: (state, { payload }: PayloadAction<ProductType>) => {
      state.products = [...state.products, payload];
    },
    setProducts: (state, { payload }: PayloadAction<ProductType[] | undefined>) => {
      if (!payload) return;
      state.products = payload;
    },
    setDuplicateProducts: (state, { payload }: PayloadAction<ProductType[] | undefined>) => {
      if (!payload) return;
      state.products = payload;
    },
    setIsChild: (state, { payload }: PayloadAction<boolean>) => {
      state.isChild = payload;
    },
    setIsSpin: (state, { payload }: PayloadAction<boolean>) => {
      state.isSpin = payload;
    },
    updateProduct(state, { payload }: PayloadAction<ProductType>) {
      const index = state.products.findIndex((product) => `${product.productNumber} ${product.condition}` === `${payload.productNumber} ${product.condition}`);

      state.products[index] = payload;
    }
  }
});

export const { setSelectedProducts, addProduct, setProducts, setIsChild, setDuplicateProducts, setIsSpin, updateProduct } = selectedProducts.actions;
