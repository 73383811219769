import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { moneyFormatter } from 'common/helpers/CurrencyFormatter';
import { ProductType, ProductTypePDF } from 'common/helpers/dummyData';
import { Fragment } from 'react';

const borderColor = '#1f1e2e';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#1f1e2e',
    borderBottomWidth: 1,
    alignItems: 'center',
    fontStyle: 'bold',
    borderLeftColor: '1f1e2e',
    borderLeftWidth: 1,
    borderRightColor: '1f1e2e',
    borderRightWidth: 1,
    borderTopColor: '1f1e2e',
    borderTopWidth: 1,
    wordBreak: 'break-all',
    height: 30
  },
  description: {
    width: '50%',
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    fontSize: 8
  },
  childDescription: {
    width: '50%',
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    fontSize: 8
  },
  col: {
    width: '14%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 8,
    fontSize: 8
  },
  moneyCol: {
    width: '14%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
    fontSize: 8
  },
  biggerCol: {
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 8,
    fontSize: 8
  },
  descriptionCol: {
    width: '35%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 8,
    fontSize: 8
  },
  smallCol: {
    width: '8%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 8,
    fontSize: 8
  },
  conditionCol: {
    width: '11%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 8,
    fontSize: 8
  },
  lineNumCol: {
    width: '6%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 8,
    fontSize: 8
  },
  discountCol: {
    width: '6%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
    fontSize: 8
  },
  listPrice: {
    width: '8%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    fontSize: 8,
    paddingRight: 8
  },
  lastCol: {
    width: '11%',
    textAlign: 'right',
    paddingRight: 8,
    fontSize: 8
  },
  paddingRow: {
    marginBottom: 70,
    flexDirection: 'row',
    borderBottomColor: '#1f1e2e',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
    borderLeftColor: '1f1e2e',
    borderLeftWidth: 1,
    borderRightColor: '1f1e2e',
    borderRightWidth: 1
  }
});

const InvoiceTableRowDiscount = ({ lines }: { lines: ProductType[] }): JSX.Element => {
  const totalLines = lines.flatMap((obj) => {
    return [obj, ...(obj?.subLines?.map((child, i) => ({ ...child, quantity: (child.quantity ?? 0) * (obj.quantity ?? 0), lineNumber: `${obj.lineNumber}.${i + 1}` })) as ProductTypePDF[])];
  });

  const rows = totalLines.map((item, index) => {
    const totalPrice = moneyFormatter.format(Number(item?.quantity ?? 0) * Number(item?.unitPrice ?? 0));

    return (
      <View style={index === 13 || (index - 13) % 26 === 0 ? styles.paddingRow : styles.row} key={item.lineNumber}>
        <Text style={styles.lineNumCol}>{item.lineNumber || '-'}</Text>
        <Text style={styles.biggerCol}>{item.productNumber || '-'}</Text>
        <Text style={styles.descriptionCol}>{item.productDescription || '-'}</Text>
        <Text style={styles.conditionCol}>{item.condition || '-'}</Text>
        <Text style={styles.smallCol}>{item.quantity || '-'}</Text>
        <Text style={styles.listPrice}>{item.listPrice ? `${moneyFormatter.format(item.listPrice)}` : '-'}</Text>
        <Text style={styles.discountCol}>{item.listDiscountPercentage ? `${item.listDiscountPercentage} %` : '-'}</Text>
        <Text style={styles.listPrice}>{item.unitPrice ? `${moneyFormatter.format(Number(item.unitPrice))}` : '-'}</Text>
        <Text style={styles.lastCol}>{totalPrice ? String(totalPrice) : '-'}</Text>
      </View>
    );
  });

  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRowDiscount;
