import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'row',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  reportTitle: {
    color: '#1f1e2e',
    letterSpacing: 4,
    fontSize: 25,
    textAlign: 'center',
    textTransform: 'uppercase'
  }
});

const InvoiceTitle = ({ title }: { title: string }): JSX.Element => (
  <View style={styles.titleContainer}>
    <Text style={styles.reportTitle}>{title}</Text>
  </View>
);

export default InvoiceTitle;
