import { InfoCircleOutlined } from '@ant-design/icons';
import { DatePicker, DatePickerProps, Form, Tooltip, Typography } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useFormikContext } from 'formik';
import { QuotePayload } from 'models/Quotes';
import moment from 'moment';
import React, { useEffect } from 'react';

const { Title } = Typography;

export const ExpirationSelection: React.FC = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<QuotePayload>();

  const today = moment();
  const dateValue = values.quoteExpirationDate ? moment(values.quoteExpirationDate) : today.add(45, 'days');

  const onChange: DatePickerProps['onChange'] = (date) => {
    if (date) {
      setFieldValue('quoteExpirationDate', date?.toISOString() ?? '');
    }
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  };

  useEffect(() => {
    if (values.quoteExpirationDate === '0001-01-01T00:00:00' || !values.quoteExpirationDate) setFieldValue('quoteExpirationDate', today.add(45, 'days').toISOString());
  }, []);

  return (
    <>
      <Form.Item style={{ marginBottom: 10 }}>
        <Title level={5} style={{ color: '#2F5596' }}>
          Expiration Date
          <Tooltip
            title={
              <>
                <Typography.Text style={{ color: 'white' }}>Defaults to 45 days if not selected.</Typography.Text>
              </>
            }>
            <InfoCircleOutlined style={{ marginLeft: 6 }} />
          </Tooltip>
        </Title>
        {/* <Select
          value={'45'}
          allowClear
          disabled={true}
          // filterOption={false}
          filterOption={(input, option): boolean => (option?.value as unknown as string).toLowerCase().includes(input.toLowerCase())}
          // onPopupScroll={handleScroll}
          // onSearch={handleSearch}
          showSearch>
          <Option value="45">45 Days</Option>
        </Select> */}
        <DatePicker style={{ width: '100%' }} onChange={onChange} value={dateValue} disabledDate={disabledDate} format={'MM-DD-YYYY'} />
      </Form.Item>
    </>
  );
};
