import { Input, Typography } from 'antd';
import { Input as MobileInput } from 'antd-mobile';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { QuotePayload } from 'models/Quotes';
import { useLocation } from 'react-router-dom';
const { Title } = Typography;

const styles: InlineStylesModel = {
  formItem: {
    marginBottom: 10
  },
  formItemMobile: {
    marginBottom: 10,
    backgroundColor: 'rgba(150,150,150,.1)',
    padding: 4,
    paddingLeft: 12,
    borderRadius: 5,
    border: '1px solid rgba(100,100,100,.1)'
  },
  formItemCol: {
    marginRight: '16px',
    maxHeight: '42px'
  },
  titleColor: {
    color: '#2F5596'
  }
};

export const CustomerContactName = (): JSX.Element => {
  const {
    values: { customerContactName },
    handleChange,
    setFieldValue
  } = useFormikContext<QuotePayload>();

  const { pathname } = useLocation();
  const isView = pathname.includes('/view');
  const isMobileScreen = Boolean(window.screen.width < 420);

  return (
    <>
      <Title level={5} style={styles.titleColor}>
        Contact Name
      </Title>
      {isMobileScreen ? (
        <MobileInput placeholder={'Enter Name...'} onChange={(val) => setFieldValue('customerContactName', val)} style={styles.formItemMobile} />
      ) : (
        <Input name="customerContactName" value={customerContactName ?? undefined} allowClear onChange={handleChange} maxLength={30} style={styles.formItem} disabled={isView} />
      )}
    </>
  );
};
