import { Button, Col, Form, Modal, Row, Select, Typography } from 'antd';
import { Loader } from 'components/common/Loader';
import { ConvertQuotePayload } from 'models/Quotes';
import { useState } from 'react';
import { useGetDispositionsQuery, useGetOwnersQuery } from 'redux/services/animals/animalsApi';
import { useGetSitesQuery, useGetWarehousesQuery } from 'redux/services/kingstonLivingston/kingstonLivingstonApi';
import { useAppSelector } from 'redux/store';

interface Props {
  convertQuote: any;
  closeModal: any;
  isModalOpen: boolean;
  isConvertingQuote: boolean;
}

export const ConvertModal = ({ convertQuote, closeModal, isModalOpen, isConvertingQuote }: Props): JSX.Element => {
  const [form] = Form.useForm<ConvertQuotePayload>();
  const [isModalSending, setIsModalSending] = useState(false);

  const { selectedCompanyDataAreaId } = useAppSelector((state) => state.orders);
  const { animalsOwnersParams, animalsDispositionsParams } = useAppSelector((state) => state);
  const { data: ownersData, isLoading: isLoadingOwners } = useGetOwnersQuery(animalsOwnersParams);
  const { data: dispositionData, isLoading: isLoadingDispositions } = useGetDispositionsQuery(animalsDispositionsParams);
  const { data: sitesData, isLoading: isLoadingSites } = useGetSitesQuery({ offset: 0, dataAreaId: selectedCompanyDataAreaId, overrideSkipTake: true });
  const [warehouseSiteParam, setWarehouseSiteParam] = useState('');
  const { data: warehousesData, isLoading: isLoadingWarehouses } = useGetWarehousesQuery({
    offset: 0,
    dataAreaId: selectedCompanyDataAreaId,
    site: warehouseSiteParam,
    overrideSkipTake: true
  });

  const convertInitialValues: ConvertQuotePayload = {
    quoteId: '',
    ownerId: '',
    dispositionId: '',
    siteId: '',
    warehouseId: ''
  };

  const closeConvertModal = (): void => {
    setWarehouseSiteParam('');
    form.resetFields();
    closeModal();
  };

  return (
    <Modal
      title={isModalSending ? 'Converting Quote...' : 'Convert Quote'}
      bodyStyle={{ paddingTop: 20 }}
      closable={!isModalSending}
      onCancel={closeConvertModal}
      open={isModalOpen}
      confirmLoading={isModalSending}
      footer={[
        <Button
          disabled={isModalSending}
          onClick={async (): Promise<void> => {
            setIsModalSending(true);
            const { quoteId, ownerId, dispositionId, siteId, warehouseId } = form.getFieldsValue();

            await convertQuote({ quoteId, ownerId, dispositionId, siteId, warehouseId }).then(() => setIsModalSending(false));
          }}
          key={1}>
          Convert
        </Button>
      ]}>
      {isConvertingQuote ? (
        <Loader style={{ marginTop: 50 }} />
      ) : (
        <Row gutter={[0, 20]}>
          <Form
            className="convert-quote-form"
            layout="vertical"
            name="convertQuoteForm"
            form={form}
            initialValues={convertInitialValues}
            onFieldsChange={(changedFields, allFields): void => {
              setWarehouseSiteParam(allFields[2].value); // index 2 is Form Item for Site
            }}>
            <Col span={24}>
              <Form.Item label={<Typography.Paragraph style={{ marginBottom: 0 }}>Owner</Typography.Paragraph>} name={'ownerId'}>
                <Select
                  loading={isLoadingOwners}
                  disabled={isLoadingOwners}
                  style={{ width: 450 }}
                  onSelect={(owner: string): void => {
                    form.setFieldValue('ownerId', owner);
                  }}
                  showSearch
                  options={ownersData?.data.map((owner) => ({ label: owner.name, value: owner.name }))}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={<Typography.Paragraph style={{ marginBottom: 0 }}>Disposition</Typography.Paragraph>} name={'dispositionId'}>
                <Select
                  loading={isLoadingDispositions}
                  disabled={isLoadingDispositions}
                  style={{ width: 450 }}
                  onSelect={(disposition: string): void => {
                    form.setFieldValue('dispositionId', disposition);
                  }}
                  showSearch
                  options={dispositionData?.data.map((disposition) => ({ label: disposition.name, value: disposition.name }))}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={<Typography.Paragraph style={{ marginBottom: 0 }}>Site</Typography.Paragraph>} name={'siteId'}>
                <Select
                  loading={isLoadingSites}
                  disabled={isLoadingSites}
                  style={{ width: 450 }}
                  onSelect={(site: string): void => {
                    form.setFieldValue('siteId', site);
                    form.setFieldValue('warehouseId', '');
                  }}
                  showSearch
                  options={sitesData?.data.map((site) => ({ label: site.siteId, value: site.siteId }))}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={<Typography.Paragraph style={{ marginBottom: 0 }}>Warehouse</Typography.Paragraph>} name={'warehouseId'}>
                <Select
                  loading={isLoadingWarehouses}
                  disabled={isLoadingWarehouses || warehouseSiteParam === ''}
                  style={{ width: 450 }}
                  onSelect={(warehouse: string): void => {
                    form.setFieldValue('warehouseId', warehouse);
                  }}
                  showSearch
                  options={warehousesData?.data.map((warhouse) => ({ label: warhouse.warehouseId, value: warhouse.warehouseId }))}
                />
              </Form.Item>
            </Col>
          </Form>
        </Row>
      )}
    </Modal>
  );
};
