import { Input, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { QuotePayload } from 'models/Quotes';
const { Title } = Typography;

const styles: InlineStylesModel = {
  formItem: {
    marginBottom: 10
  },
  formItemMobile: {
    marginBottom: 10,
    backgroundColor: 'rgba(150,150,150,.1)',
    padding: 4,
    paddingLeft: 12,
    borderRadius: 5,
    border: '1px solid rgba(100,100,100,.1)'
  },
  formItemCol: {
    marginRight: '16px',
    maxHeight: '42px'
  },
  titleColor: {
    color: '#2F5596'
  }
};

export const AlternateDisplayName = (): JSX.Element => {
  const {
    values: { alternateCustomerDisplayName },
    setFieldValue
  } = useFormikContext<QuotePayload>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFieldValue('alternateCustomerDisplayName', e.target.value);
  };

  return (
    <>
      <Title level={5} style={styles.titleColor}>
        Customer Account
      </Title>

      <Input name="alternateCustomerDisplayName" value={alternateCustomerDisplayName} allowClear onChange={handleChange} maxLength={30} style={styles.formItem} />
    </>
  );
};
