import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import { Col, Form, Row, Spin, message } from 'antd';
import { QuoteBreadcrumb } from 'components/atoms/QuoteBreadcrumb';
import { WimsicalError } from 'components/atoms/WimsicalError';
import { ActionControls } from 'components/common/ActionControls';
import { Loader } from 'components/common/Loader';
import { StepControls } from 'components/common/StepControls';
import { FormikProvider, useFormik } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { QuotePayload } from 'models/Quotes';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { samTheRobotApi, useCreateAttachmentMutation, useGetQuoteQuery, useUpdateQuoteMutation } from 'redux/services/samTheRobot/samTheRobotApi';
import { setQuoteSubmissionStatusMessage } from 'redux/slices/quoteSlice';
import { setProducts, setSelectedProducts } from 'redux/slices/selectedProductsSlice';
import { useAppSelector } from 'redux/store';

const styles: InlineStylesModel = {
  title: {
    marginTop: 500,
    marginBottom: 0
  }
};

export const EditLayout = (): JSX.Element => {
  const { id } = useParams();
  const { data, isLoading, isFetching, error, isError } = useGetQuoteQuery(id ?? skipToken);
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [decimalError, setDecimalError] = useState('');

  const { selectedCompanyDataAreaId } = useAppSelector((state) => state.orders);

  const { quoteSubmissionStatusMessage } = useAppSelector((state) => state.orders);

  const [updateQuote, { isLoading: quoteSubmitting }] = useUpdateQuoteMutation();
  const [createAttachment, { isLoading: isCreatingAttachment }] = useCreateAttachmentMutation();

  useEffect(() => {
    dispatch(setProducts(data?.lines));
  }, [data, dispatch]);

  useEffect(() => {
    if (decimalError) message.error(decimalError);
  }, [decimalError]);

  const formik = useFormik<QuotePayload>({
    initialValues: {
      alternateCustomerDisplayName: data ? data.alternateCustomerDisplayName : '',
      attachments: [],
      id: id,
      buyerId: data ? data.buyerId : '',
      buyerName: data ? data.buyerName : '',
      customerErpId: data ? data.customerErpId || '' : '',
      customerAccount: data ? data.customerErpId || '' : '',
      customerName: data ? data.customerName || '' : '',
      customerDataAreaId: data ? data.dataAreaId || '' : '',
      customerContactName: data ? data.customerContactName || '' : '',
      customerContactPhone: data ? data.customerContactPhone || '' : '',
      customerEmailAddress: data ? data.customerEmailAddress : '',
      customerEmailAddresses: data ? data.customerEmailAddresses : [],
      createdByUserFullName: data?.createdByUserFullName ?? '',
      emailMessage: data?.emailMessage || '',
      dataAreaId: data ? data.dataAreaId : '',
      isActive: data ? data.isActive : true,
      lines: data ? data.lines : [],
      notes: data ? data.notes : '',
      quoteName: data ? data.quoteName : '',
      quoteExpiresInDays: data ? data.quoteExpiresInDays : 45,
      quoteExpirationDate: data ? data.quoteExpirationDate : '',
      quoteId: data ? data.quoteId : '',
      totalCost: data ? data.totalCost : 0,
      totalPrice: data ? data.totalPrice : 0,
      totalProfit: data ? data.totalProfit : 0,
      totalMargin: data ? data.totalMargin : 0,
      salesPersonName: data ? data.salesPersonName : '',
      salesPersonId: data ? data?.salesPersonId : '',
      deliveryAddressStreet1: data ? data?.deliveryAddress?.street1 : '',
      deliveryAddressCity: data ? data?.deliveryAddress?.city : '',
      deliveryAddressCountry: data ? data?.deliveryAddress?.country : '',
      deliveryAddressCounty: data ? data?.deliveryAddress?.country : '',
      deliveryAddressSubdivision: data ? data?.deliveryAddress?.subdivision : '',
      deliveryAddressPostalCode: data ? data?.deliveryAddress?.postalCode : '',
      deliveryAddressName: data ? data?.deliveryAddress?.name : '',
      deliveryAddressLocationId: data ? data?.deliveryAddress?.locationId : '',
      createdDateTime: data ? data?.createdDateTime : '',
      newQuote: false,
      closeWizard: true
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const totalPrice = values.lines.reduce((accumulator, quoteLineObject) => {
        return accumulator + Number(quoteLineObject.extendedPrice || 0);
      }, 0);

      try {
        // values.lines.forEach((line) => {
        //   if (String(line.quantity).includes('.')) {
        //     setDecimalError('Quantity cannot have decimals');
        //     throw Error();
        //   }
        // });
        await updateQuote({
          ...values,
          dataAreaId: selectedCompanyDataAreaId || 'mdsi',
          customerErpId: values.customerAccount,
          lines: values.lines,
          totalPrice
        }).unwrap();

        if (values.closeWizard) nav('/');
        message.success('Sales quote successfully updated', 5);
      } catch (error: any) {
        const errorMessage = error.data.errorMessage.includes('not a valid integer') ? 'Quantity cannot include decimals' : error.data.errorMessage;

        console.log(error);
        message.error(error.data.errorMessage ? errorMessage : 'An error occurred while submitting your product quote.');
      }
      try {
        if (values.attachments && values.attachments.length) {
          dispatch(setQuoteSubmissionStatusMessage('Saving attachments'));
          const formData = new FormData();

          for (const attachment of values.attachments) {
            let index = 1;

            dispatch(setQuoteSubmissionStatusMessage(`Creating attachments ${index}/${values.attachments.length}`));
            formData.append(attachment.name, attachment);

            await createAttachment({ file: formData, quoteId: id as string } ?? { skip: !id }).unwrap();

            formData.delete(attachment.name);
            index++;
          }
          dispatch(samTheRobotApi.util.invalidateTags(['Quote', 'Quotes']));
          message.success('Attachments successfully saved', 5);
        }
      } catch (error: any) {
        console.log(error);
        message.error(error.data ?? 'An error occurred while submitting your product quote.');
      }
    }
  });

  console.log(formik.values);

  useEffect(() => {
    return () => {
      dispatch(setSelectedProducts([]));
    };
  }, [dispatch]);

  if (isLoading || isFetching) return <Loader />;

  if (isError) {
    const queryError = error as FetchBaseQueryError;

    return (
      <Row justify="center" style={{ width: '100%' }}>
        <Col span={24}>
          <WimsicalError status={typeof queryError.status === 'number' ? queryError.status : 500} message={''} />
        </Col>
      </Row>
    );
  }

  return (
    <FormikProvider value={formik}>
      <Spin spinning={quoteSubmitting || isCreatingAttachment} indicator={<Loader message={quoteSubmissionStatusMessage || 'Updating Sales Quote...'} />}>
        <Form style={styles.container} layout="vertical">
          <ActionControls />
          <QuoteBreadcrumb />
          <StepControls />
          <Outlet />
        </Form>
      </Spin>
    </FormikProvider>
  );
};
