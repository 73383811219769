import { Card, Col, Row, Typography } from 'antd';
import { EmailMessageInput } from 'components/atoms/EmailMessageInput';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { QuotePayload } from 'models/Quotes';
import { SubscribeInput } from './SubscribeInput';
import { SubscriberList } from './SubscriberList';
const styles: InlineStylesModel = {
  mainContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: 0,
    width: '100%'
    // height: 'calc(100vh - 112px - 46px - 50px - 110px - 25px )'
  },
  cardsContainer: {
    padding: '0px',
    margin: '0px'
  },

  infoTitle: {
    fontSize: '16px',
    fontWeight: 600
  },
  infoText: {
    fontSize: '15px',
    minHeight: '27px'
  },
  cardHead: {
    fontSize: '18px',
    fontWeight: 600
  },
  cardBody: {
    padding: '25px 20px 20px 25px'
  },
  headerCard: {
    backgroundColor: 'rgb(176, 178, 188)'
  },
  deliveryCard: {
    backgroundColor: '#CED5F2'
  },
  productsTableCard: {
    backgroundColor: '#b0b2bc'
  },
  tableBody: {
    padding: 0
  },
  extra: {
    fontSize: '15px',
    fontWeight: 500
  },
  addressLine: {
    marginBottom: 0
  },
  attention: {
    maxWidth: '300px'
  }
};

export const EmailInfoStep = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<QuotePayload>();

  return (
    <Card
      style={{ height: '100%', width: '100%' }}
      title={
        <Typography.Title level={4} style={styles.title}>
          Email Info
        </Typography.Title>
      }
      bordered={false}
      headStyle={{ ...styles.cardHead, ...styles.headerCard }}
      bodyStyle={styles.cardBody}>
      <Row gutter={16}>
        <Col xs={24} sm={12} md={12}>
          {<SubscribeInput />}
          {!!values.customerEmailAddresses?.length && <SubscriberList />}
        </Col>
        <Col xs={24} sm={12} md={12}>
          <EmailMessageInput />
        </Col>
      </Row>
    </Card>
  );
};
