import { Form, Select, Space, Spin, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { QuotePayload } from 'models/Quotes';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useGetEmployeesQuery } from 'redux/services/leftyTheSalesman/leftyTheSalesmanApi';
import { ReduxState } from 'redux/store';
import { BlockLoader } from './BlockLoader';
const { Title } = Typography;

const styles: InlineStylesModel = {
  formItem: {
    marginBottom: 10
  },
  formItemCol: {
    marginRight: '16px',
    maxHeight: '42px'
  },
  titleColor: {
    color: '#2F5596'
  }
};

export const SalespersonInput = (): JSX.Element => {
  const {
    values: { customerAccount, customerPersonnelNumber, salesPersonName, salesPersonId, customerEmailAddresses },
    setFieldValue
  } = useFormikContext<QuotePayload>();
  const [offset, _setOffset] = useState(0);
  const { pathname } = useLocation();
  const isView = pathname.includes('view');
  const { selectedCompanyDataAreaId } = useSelector((state: ReduxState) => state.orders);

  /* ****************** Hook API ****************** */
  const { data: employeesResponse, isFetching, isLoading } = useGetEmployeesQuery({ overrideSkipTake: true, dataAreaId: selectedCompanyDataAreaId, offset });

  /* ****************** JSX / Variables ****************** */
  const salesPersonSearchOptions = useMemo(
    () =>
      employeesResponse?.data?.map((employee) => ({
        label: `${employee.name} - ${employee.personnelNumber}`,
        value: `${employee.name} - ${employee.personnelNumber}`
      })),
    [employeesResponse]
  );

  /* ****************** Functions ****************** */
  useEffect(() => {
    if (employeesResponse && customerPersonnelNumber && customerPersonnelNumber !== '') {
      const customerPersonnelNumberSalesman = employeesResponse?.data.find((each) => each.personnelNumber === customerPersonnelNumber);

      setFieldValue('salesPersonName' as keyof QuotePayload, customerPersonnelNumberSalesman?.name);
      setFieldValue('salesPersonId' as keyof QuotePayload, customerPersonnelNumberSalesman?.personnelNumber);
    }
  }, [employeesResponse]);

  const handleClear = (): void => {
    _setOffset(0);
    setFieldValue('salesPersonName' as keyof QuotePayload, null);
    setFieldValue('salesPersonId' as keyof QuotePayload, null);
  };

  // const handleScroll = (event: React.UIEvent<HTMLDivElement>): void => {
  //   const target = event.currentTarget;

  //   if (!isLoading && !isFetching && target.scrollTop + target.offsetHeight === target.scrollHeight && employeesResponse && employeesResponse?.totalCount - offset > 25) {
  //     _setOffset(offset + 25);
  //   }
  // };

  const handleSelect = (value: string): void => {
    const salesPerson = employeesResponse?.data.find((seller) => `${seller.name} - ${seller.personnelNumber}` === value);

    const addEmail = (email: string | undefined): string[] => {
      const filteredEmails = customerEmailAddresses.filter((emailVal) => emailVal !== employeesResponse?.data.find((b) => b.personnelNumber === salesPersonId)?.primaryContactEmail);

      // 1. Selected value has no email - done
      // 2. Selected value already exists in emails - half done
      // 3. Selected email value was changed to a new email value
      // 4. New selected value shouls be added to array - done

      // Do nothing if email not set
      if (!email) {
        return filteredEmails;
      }
      // Do nothing if selected value is the same
      if (customerEmailAddresses.includes(email)) return customerEmailAddresses;
      // ADD: Brand new value selected and email isnt empty string
      if (email && !customerEmailAddresses?.includes(email)) {
        // Remove previous selected value if it exists

        return [...filteredEmails, email];
      }

      return [];
    };

    setFieldValue('salesPersonName' as keyof QuotePayload, salesPerson?.name);
    setFieldValue('salesPersonId' as keyof QuotePayload, salesPerson?.personnelNumber);
    setFieldValue('customerEmailAddresses', addEmail(salesPerson?.primaryContactEmail));
    // setFieldValue('salesPersonEmail' as keyof QuotePayload, value.split(' - ')[2]);
    // setFieldValue('customerEmailAddresses', customerEmailAddresses && value.split(' - ')[2] !== '' ? [...customerEmailAddresses, value.split(' - ')[2]] : []);
  };

  const isInvalid = !customerAccount;

  const dropDownRender = (menu: JSX.Element): JSX.Element => {
    if ((isLoading || isFetching) && !offset)
      return (
        <div style={{ textAlign: 'center', padding: 10 }}>
          <Spin indicator={<BlockLoader direction="loader loader--slideUp" />} spinning />
        </div>
      );

    const moreResultsJSX =
      (isLoading || isFetching) && offset ? (
        <div style={{ padding: '5px 10px' }}>
          <Space>
            <Spin size="small" indicator={<BlockLoader direction="loader loader--slideUp" />} />
          </Space>
        </div>
      ) : null;

    return (
      <div>
        {menu}
        {moreResultsJSX}
      </div>
    );
  };

  /* ****************** Render ****************** */
  return (
    <>
      <Title level={5} style={styles.titleColor}>
        Salesperson
      </Title>
      <Form.Item
        style={styles.formItem}
        validateStatus={isInvalid ? 'error' : undefined}
        help={customerAccount && !salesPersonName ? `No personnel number found for customer ${customerAccount}` : isInvalid ? 'Customer selection required' : undefined}>
        <Select
          value={salesPersonName ? `${salesPersonName} - ${salesPersonId}` : undefined}
          allowClear
          disabled={isInvalid || isView}
          dropdownRender={dropDownRender}
          // filterOption={false}
          filterOption={(input, option): boolean => (option?.value as unknown as string).toLowerCase().includes(input.toLowerCase())}
          loading={isLoading || isFetching}
          onClear={handleClear}
          // onPopupScroll={handleScroll}
          // onSearch={handleSearch}
          onSelect={handleSelect}
          options={salesPersonSearchOptions}
          showSearch
        />
      </Form.Item>
    </>
  );
};
