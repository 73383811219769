import { EnvironmentOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { AddressLabels } from 'models/Address';
import { DefaultOptionType } from 'rc-cascader';
import { RawValueType } from 'rc-select/lib/BaseSelect';
import { LabelInValueType } from 'rc-select/lib/Select';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useGetWorldsQuery } from 'redux/services/chuckieSue/worldsApi';
import { setFilterCountry, setFilterState } from 'redux/slices/quoteSlice';
import { useAppSelector } from 'redux/store';
import { FancySelect } from './FancySelect';

export const CountryStateInputs: React.FC = () => {
  const { pathname } = useLocation();
  const { filterCountry, filterState } = useAppSelector((state) => state.orders);
  const { data: countries } = useGetWorldsQuery();
  const isView = pathname.includes('/view/');

  const [selectedCountry, setSelectedCountry] = useState<RawValueType | LabelInValueType>('United States of America');
  const [selectedState, setSelectedState] = useState<RawValueType | LabelInValueType>();

  const { data } = useGetWorldsQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({ data: data?.data[0].countries.find((country) => country.shortName === selectedCountry), ...rest })
  });

  const dispatch = useDispatch();

  const countryNames = countries?.data[0].countries.map((country) => ({ label: country.shortName, value: country.shortName }));

  const stateOptions: DefaultOptionType[] | undefined = data?.subdivisions.map((state) => ({ label: state.name, value: state.localCode }));

  useEffect(() => {
    dispatch(setFilterCountry('US'));
    dispatch(setFilterState(undefined));
  }, []);

  const handleStateChange = (value: string): void => {
    dispatch(setFilterState(value));
  };

  const handleCountryChange = (value: string): void => {
    const countryAlpha2Code = (): string | undefined => {
      const country = countries?.data[0].countries.find((country) => country.shortName === value);

      return country?.alpha2Code;
    };

    dispatch(setFilterCountry(countryAlpha2Code()));
    dispatch(setFilterState(undefined));
  };

  return (
    <Row gutter={[8, 8]}>
      <Col>
        <FancySelect
          disabled={isView}
          width={160}
          dropdownStyle={{ minWidth: '20%' }}
          onSelect={(value): void => setSelectedCountry(value)}
          placeholder={isView ? '' : 'Country'}
          style={{ margin: 0 }}
          defaultActiveFirstOption={true}
          defaultOpen={false}
          value={filterCountry}
          onChange={handleCountryChange}
          defaultValue="United States of America"
          showSearch
          options={countryNames ? countryNames : undefined}
        />
      </Col>
      <Col>
        <FancySelect
          disabled={isView}
          width={145}
          dropdownStyle={{ minWidth: '20%' }}
          showSearch
          suffixIcon={<EnvironmentOutlined style={{ color: 'black' }} />}
          placeholder={AddressLabels.subdivisionLocalCode}
          onSelect={(value): void => setSelectedState(value)}
          onChange={handleStateChange}
          onDeselect={(): void => {
            dispatch(setFilterState(undefined));
          }}
          options={stateOptions}
          value={filterState}
        />
      </Col>
    </Row>
  );
};
