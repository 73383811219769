import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Select, Spin, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { QuotePayload } from 'models/Quotes';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useGetBuyersQuery } from 'redux/services/beautifulDayMonster/beautifulDayMonsterApi';
import { ReduxState } from 'redux/store';
import { AddBuyerModal } from './AddBuyerModal';
import { BlockLoader } from './BlockLoader';
const styles: InlineStylesModel = {
  formContainer: {
    paddingRight: 10,
    paddingLeft: 10
  },
  formItem: {
    marginBottom: 10
  },
  titleColor: {
    color: '#2F5596'
  },
  buyerButton: {
    width: '100%'
  }
};

const { Title } = Typography;

export const BuyerInput = (): JSX.Element => {
  const { values, setFieldValue, setValues } = useFormikContext<QuotePayload>();
  const { pathname } = useLocation();
  const isView = pathname.includes('view');

  const [inputValue, _setInputValue] = useState<string | undefined>(undefined);
  const [offset, _setOffset] = useState(0);
  const [showBuyerInputs, _setShowBuyerInputs] = useState(false);
  const { selectedCompanyDataAreaId } = useSelector((state: ReduxState) => state.orders);

  /* ****************** Hook API ****************** */
  const { data: buyersResponse, isLoading } = useGetBuyersQuery(
    { dataAreaId: selectedCompanyDataAreaId as string, buyerNameContains: inputValue, offset, customerId: values.customerAccount, overrideSkipTake: true },
    { skip: !selectedCompanyDataAreaId }
  );

  /* ****************** Variables ****************** */
  const buyerSearchOptions = useMemo(
    () =>
      buyersResponse?.data?.map((buyer) => ({
        label: `${buyer.buyerName} - ${buyer.buyerId} - ${buyer.emailAddress || 'Not set'}`,
        value: `${buyer.buyerName} - ${buyer.buyerId}`,
        buyerObject: buyer
      })),
    [buyersResponse]
  );

  /* ****************** Functions ****************** */
  const handleClear = (): void => {
    _setInputValue(undefined);
    _setOffset(0);
    // setFieldValue('buyerName' as keyof QuotePayload, null);
    // setFieldValue('buyerId' as keyof QuotePayload, null);
    setValues({
      ...values,
      buyerId: '',
      buyerName: '',
      customerContactName: '',
      customerContactPhone: '',
      customerEmailAddress: ''
    });
  };

  const handleSelect = (value: string): void => {
    const buyer = buyerSearchOptions?.find((buyer) => buyer.value === value)?.buyerObject;

    const addEmail = (email: string | undefined): string[] => {
      const filteredEmails = values.customerEmailAddresses.filter((emailVal) => emailVal !== buyersResponse?.data.find((b) => b.buyerId === values.buyerId)?.emailAddress);

      // 1. Selected value has no email - done
      // 2. Selected value already exists in emails - half done
      // 3. Selected email value was changed to a new email value
      // 4. New selected value shouls be added to array - done

      // Do nothing if email not set
      if (!email) {
        return filteredEmails;
      }
      // Do nothing if selected value is the same
      if (values.customerEmailAddresses.includes(email)) return values.customerEmailAddresses;
      // ADD: Brand new value selected and email isnt empty string
      if (email && !values?.customerEmailAddresses?.includes(email)) {
        // Remove previous selected value if it exists

        return [...filteredEmails, email];
      }

      return [];
    };

    setValues({
      ...values,
      buyerId: buyer?.buyerId || '',
      buyerName: buyer?.buyerName || '',
      customerContactName: buyer?.buyerName || '',
      customerContactPhone: buyer?.phoneNumber || '',
      customerEmailAddresses: addEmail(buyer?.emailAddress)
    });

    _setInputValue(undefined);
  };

  const isInvalid = !values.customerAccount && !values.buyerId;

  /* ****************** JSX ****************** */
  const showCreateBuyerButtonJSX = (
    <Button icon={<PlusOutlined />} style={styles.buyerButton} onClick={(): void => _setShowBuyerInputs(true)}>
      Add New Buyer
    </Button>
  );

  const dropDownRenderJSX = (menu: JSX.Element): JSX.Element => {
    if (isLoading && !offset)
      return (
        <div style={{ textAlign: 'center', padding: 10 }}>
          <Spin indicator={<BlockLoader direction="loader loader--slideUp" />} spinning />
        </div>
      );

    return (
      <div>
        {menu}
        <Divider style={{ margin: '4px 0' }} />
        <div style={styles.formContainer}>{showCreateBuyerButtonJSX}</div>
      </div>
    );
  };

  const helperText = (): string | undefined => {
    if (isInvalid) {
      if (values.customerAccount && !values.buyerId) {
        return `No buyer found for customer ${values.customerAccount}`;
      } else {
        return 'Customer selection required';
      }
    }

    return undefined;
  };

  /* ****************** Render ****************** */
  return (
    <>
      <AddBuyerModal show={showBuyerInputs} onClose={(): void => _setShowBuyerInputs(false)} />
      <Title level={5} style={styles.titleColor}>
        Buyer
      </Title>
      <Form.Item style={styles.formItem} validateStatus={isInvalid ? 'error' : undefined} help={helperText()}>
        <Select
          allowClear
          value={values.buyerName && values.buyerId ? `${values.buyerName} - ${values.buyerId}` : undefined}
          disabled={isInvalid || isView}
          dropdownRender={dropDownRenderJSX}
          filterOption={(input, option): boolean => (option?.value as unknown as string).toLowerCase().includes(input.toLowerCase())}
          loading={isLoading}
          onClear={handleClear}
          onSelect={handleSelect}
          options={buyerSearchOptions}
          showSearch
        />
      </Form.Item>
    </>
  );
};
