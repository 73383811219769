import { Col, Row, Space, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { QuotePayload } from 'models/Quotes';
import React from 'react';

const styles: InlineStylesModel = {
  headerTitle: {
    fontWeight: 'bold',
    color: '#2F5596'
  }
};

export const QuoteBreadcrumb = (): JSX.Element => {
  const { values } = useFormikContext<QuotePayload>();
  const { md } = useBreakpoint();

  return (
    <Row>
      <Col style={{ marginBottom: 8 }}>
        <Space size="large" direction={md ? 'horizontal' : 'vertical'}>
          <Col>
            <Space>
              <Typography.Text style={styles.headerTitle}>Quote Id: </Typography.Text>
              <Typography.Text>
                {values.quoteId ? `${values.quoteId}${values.quoteName ? '--' + values.quoteName : ''}` : `AUTOGENERATED${values.quoteName ? '--' + values.quoteName : ''}`}
              </Typography.Text>
            </Space>
          </Col>
          {values.customerName && (
            <Space>
              <Typography.Text style={styles.headerTitle}>Customer Name: </Typography.Text>
              <Typography.Text>{values.customerName}</Typography.Text>
            </Space>
          )}
          {values.customerAccount && (
            <Space>
              <Typography.Text style={styles.headerTitle}>Customer Account: </Typography.Text>
              <Typography.Text>{values.customerAccount}</Typography.Text>
            </Space>
          )}
          {values.buyerName && (
            <Space>
              <Typography.Text style={styles.headerTitle}>Buyer: </Typography.Text>
              <Typography.Text>{values.buyerName}</Typography.Text>
            </Space>
          )}
        </Space>
      </Col>
    </Row>
  );
};
