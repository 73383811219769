export const customerLottie = {
  v: '5.8.1',
  fr: 30,
  ip: 0,
  op: 49,
  w: 576,
  h: 576,
  nm: 'Team',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: 'NULL CONTROL',
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 0 },
        r: { a: 0, k: 0 },
        p: {
          k: [
            { s: [0, -101.317, 0], t: 13, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -100.749, 0], t: 14, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -99.048, 0], t: 15, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -96.212, 0], t: 16, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -92.242, 0], t: 17, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -87.137, 0], t: 18, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -80.898, 0], t: 19, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -73.524, 0], t: 20, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -74.017, 0], t: 21, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -76.749, 0], t: 22, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -78.348, 0], t: 23, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -78.812, 0], t: 24, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -78.142, 0], t: 25, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -76.337, 0], t: 26, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -73.398, 0], t: 27, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -72.137, 0], t: 28, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -73.117, 0], t: 29, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -72.962, 0], t: 30, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -71.673, 0], t: 31, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -71.781, 0], t: 32, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -71.317, 0], t: 33, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } }
          ]
        },
        a: { a: 0, k: [60, 60, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Layer 1',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.313], y: [1] }, o: { x: [0.333], y: [0] }, t: 3, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.641], y: [0] }, t: 15, s: [6] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 27, s: [-4] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 33, s: [2] },
            { t: 39, s: [0] }
          ]
        },
        p: {
          a: 1,
          k: [
            { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 3, s: [60, 60, 0], to: [0, -5, 0], ti: [0, 5, 0] },
            { t: 13, s: [60, 30, 0] }
          ]
        },
        a: { a: 0, k: [0, 69.161, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -38.197],
                    [38.197, 0],
                    [0, 38.197],
                    [-38.197, 0]
                  ],
                  o: [
                    [0, 38.197],
                    [-38.197, 0],
                    [0, -38.197],
                    [38.197, 0]
                  ],
                  v: [
                    [69.161, 0],
                    [0, 69.161],
                    [-69.161, 0],
                    [0, -69.161]
                  ],
                  c: true
                }
              },
              nm: 'Path 1',
              hd: false
            },
            { ty: 'st', c: { a: 0, k: [0, 0, 0] }, o: { a: 0, k: 100 }, w: { a: 0, k: 17.5 }, lc: 2, lj: 2, bm: 0, nm: 'Stroke 1', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 3,
      nm: 'NULL CONTROL',
      sr: 1,
      ks: {
        o: { a: 0, k: 0 },
        r: { a: 0, k: 0 },
        p: {
          k: [
            { s: [287.801, 407.897, 0], t: 10, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 408.464, 0], t: 11, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 410.166, 0], t: 12, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 413.002, 0], t: 13, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 416.972, 0], t: 14, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 422.077, 0], t: 15, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 428.316, 0], t: 16, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 435.689, 0], t: 17, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 435.197, 0], t: 18, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 432.464, 0], t: 19, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 430.866, 0], t: 20, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 430.402, 0], t: 21, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 431.072, 0], t: 22, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 432.877, 0], t: 23, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 435.816, 0], t: 24, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 437.077, 0], t: 25, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 436.097, 0], t: 26, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 436.252, 0], t: 27, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 437.541, 0], t: 28, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 437.433, 0], t: 29, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 437.897, 0], t: 30, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } }
          ]
        },
        a: { a: 0, k: [60, 60, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Layer 7',
      parent: 3,
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 0, s: [60, 90, 0], to: [0, -5, 0], ti: [0, 5, 0] },
            { t: 10, s: [60, 60, 0] }
          ]
        },
        a: { a: 0, k: [0, 60.697, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [68.356, 0],
                    [3.158, -67.575]
                  ],
                  o: [
                    [-3.158, -67.575],
                    [-68.356, 0],
                    [0, 0]
                  ],
                  v: [
                    [127.289, 60.697],
                    [0, -60.697],
                    [-127.289, 60.697]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            { ty: 'st', c: { a: 0, k: [0, 0, 0] }, o: { a: 0, k: 100 }, w: { a: 0, k: 17.5 }, lc: 2, lj: 2, bm: 0, nm: 'Stroke 1', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 3,
      nm: 'NULL CONTROL',
      parent: 8,
      sr: 1,
      ks: {
        o: { a: 0, k: 0 },
        r: { a: 0, k: 0 },
        p: {
          k: [
            { s: [-11.158, -75.647, 0], t: 15, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -75.08, 0], t: 16, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -73.378, 0], t: 17, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -70.542, 0], t: 18, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -66.572, 0], t: 19, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -61.467, 0], t: 20, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -55.228, 0], t: 21, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -47.855, 0], t: 22, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -48.347, 0], t: 23, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -51.08, 0], t: 24, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -52.678, 0], t: 25, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -53.142, 0], t: 26, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -52.472, 0], t: 27, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -50.667, 0], t: 28, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -47.728, 0], t: 29, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -46.467, 0], t: 30, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -47.447, 0], t: 31, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -47.292, 0], t: 32, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -46.003, 0], t: 33, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -46.111, 0], t: 34, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -45.647, 0], t: 35, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } }
          ]
        },
        a: { a: 0, k: [60, 60, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Layer 4',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.313], y: [1] }, o: { x: [0.333], y: [0] }, t: 5, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.641], y: [0] }, t: 17, s: [6] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 29, s: [-4] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 35, s: [2] },
            { t: 41, s: [0] }
          ]
        },
        p: {
          a: 1,
          k: [
            { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 5, s: [60, 60, 0], to: [0, -5, 0], ti: [0, 5, 0] },
            { t: 15, s: [60, 30, 0] }
          ]
        },
        a: { a: 0, k: [0, 39.778, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -21.969],
                    [21.969, 0],
                    [0, 21.969],
                    [-21.969, 0]
                  ],
                  o: [
                    [0, 21.969],
                    [-21.969, 0],
                    [0, -21.969],
                    [21.969, 0]
                  ],
                  v: [
                    [39.778, 0],
                    [0, 39.778],
                    [-39.778, 0],
                    [0, -39.778]
                  ],
                  c: true
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 3,
      nm: 'NULL CONTROL',
      sr: 1,
      ks: {
        o: { a: 0, k: 0 },
        r: { a: 0, k: 0 },
        p: {
          k: [
            { s: [436.627, 373.236, 0], t: 12, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 373.803, 0], t: 13, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 375.505, 0], t: 14, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 378.341, 0], t: 15, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 382.311, 0], t: 16, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 387.416, 0], t: 17, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 393.655, 0], t: 18, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 401.028, 0], t: 19, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 400.536, 0], t: 20, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 397.803, 0], t: 21, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 396.205, 0], t: 22, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 395.741, 0], t: 23, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 396.411, 0], t: 24, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 398.216, 0], t: 25, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 401.155, 0], t: 26, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 402.416, 0], t: 27, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 401.436, 0], t: 28, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 401.591, 0], t: 29, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 402.88, 0], t: 30, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 402.772, 0], t: 31, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 403.236, 0], t: 32, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } }
          ]
        },
        a: { a: 0, k: [60, 60, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Layer 5',
      parent: 7,
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 2, s: [60, 90, 0], to: [0, -5, 0], ti: [0, 5, 0] },
            { t: 12, s: [60, 60, 0] }
          ]
        },
        a: { a: 0, k: [0, 36.655, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-19.803, 0],
                    [0, -40.487]
                  ],
                  o: [
                    [13.166, -12.762],
                    [40.487, 0],
                    [0, 0]
                  ],
                  v: [
                    [-62.143, -16.008],
                    [-11.167, -36.655],
                    [62.143, 36.655]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 3,
      nm: 'NULL CONTROL',
      parent: 12,
      sr: 1,
      ks: {
        o: { a: 0, k: 0 },
        r: { a: 0, k: 0 },
        p: {
          k: [
            { s: [11.085, -75.647, 0], t: 17, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -75.08, 0], t: 18, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -73.378, 0], t: 19, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -70.542, 0], t: 20, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -66.572, 0], t: 21, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -61.467, 0], t: 22, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -55.228, 0], t: 23, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -47.855, 0], t: 24, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -48.347, 0], t: 25, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -51.08, 0], t: 26, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -52.678, 0], t: 27, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -53.142, 0], t: 28, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -52.472, 0], t: 29, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -50.667, 0], t: 30, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -47.728, 0], t: 31, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -46.467, 0], t: 32, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -47.447, 0], t: 33, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -47.292, 0], t: 34, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -46.003, 0], t: 35, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -46.111, 0], t: 36, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -45.647, 0], t: 37, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } }
          ]
        },
        a: { a: 0, k: [60, 60, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      ip: 0,
      op: 102,
      st: 12,
      bm: 0
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Layer 2',
      parent: 9,
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.313], y: [1] }, o: { x: [0.333], y: [0] }, t: 7, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.641], y: [0] }, t: 19, s: [6] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 31, s: [-4] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 37, s: [2] },
            { t: 43, s: [0] }
          ]
        },
        p: {
          a: 1,
          k: [
            { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 7, s: [60, 60, 0], to: [0, -5, 0], ti: [0, 5, 0] },
            { t: 17, s: [60, 30, 0] }
          ]
        },
        a: { a: 0, k: [0, 39.778, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -21.969],
                    [-21.969, 0],
                    [0, 21.969],
                    [21.969, 0]
                  ],
                  o: [
                    [0, 21.969],
                    [21.969, 0],
                    [0, -21.969],
                    [-21.969, 0]
                  ],
                  v: [
                    [-39.778, 0],
                    [0, 39.778],
                    [39.778, 0],
                    [0, -39.778]
                  ],
                  c: true
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 11,
      ty: 3,
      nm: 'NULL CONTROL',
      sr: 1,
      ks: {
        o: { a: 0, k: 0 },
        r: { a: 0, k: 0 },
        p: {
          k: [
            { s: [138.731, 373.236, 0], t: 14, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 373.803, 0], t: 15, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 375.505, 0], t: 16, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 378.341, 0], t: 17, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 382.311, 0], t: 18, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 387.416, 0], t: 19, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 393.655, 0], t: 20, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 401.028, 0], t: 21, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 400.536, 0], t: 22, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 397.803, 0], t: 23, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 396.205, 0], t: 24, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 395.741, 0], t: 25, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 396.411, 0], t: 26, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 398.216, 0], t: 27, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 401.155, 0], t: 28, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 402.416, 0], t: 29, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 401.436, 0], t: 30, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 401.591, 0], t: 31, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 402.88, 0], t: 32, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 402.772, 0], t: 33, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 403.236, 0], t: 34, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } }
          ]
        },
        a: { a: 0, k: [60, 60, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'Layer 3',
      parent: 11,
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 4, s: [60, 90, 0], to: [0, -5, 0], ti: [0, 5, 0] },
            { t: 14, s: [60, 60, 0] }
          ]
        },
        a: { a: 0, k: [0, 36.655, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-40.487, 0],
                    [-13.166, -12.872]
                  ],
                  o: [
                    [0, -40.487],
                    [19.877, 0],
                    [0, 0]
                  ],
                  v: [
                    [-62.216, 36.655],
                    [11.094, -36.655],
                    [62.216, -15.861]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const headerInfoLottie = {
  v: '5.7.14',
  fr: 30,
  ip: 0,
  op: 26,
  w: 576,
  h: 576,
  nm: 'Student Notes',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Layer 7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.27], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.75], y: [0] }, t: 10, s: [31] },
            { t: 20, s: [0] }
          ]
        },
        p: {
          a: 1,
          k: [
            { i: { x: 0.27, y: 1 }, o: { x: 0.333, y: 0 }, t: 0, s: [318.642, 390.457, 0], to: [0, 6.333, 0], ti: [0, 0, 0] },
            { i: { x: 0.667, y: 1 }, o: { x: 0.75, y: 0 }, t: 10, s: [318.642, 428.457, 0], to: [0, 0, 0], ti: [0, 6.333, 0] },
            { t: 20, s: [318.642, 390.457, 0] }
          ]
        },
        a: { a: 0, k: [318.642, 390.457, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-41.698, 47.271],
                    [18.765, -47.271],
                    [41.698, -32.604]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            { ty: 'st', c: { a: 0, k: [1, 1, 0.250980407, 1] }, o: { a: 0, k: 100 }, w: { a: 0, k: 17.5 }, lc: 2, lj: 2, bm: 0, nm: 'Stroke 1', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [389.189, 213.608] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-9.305, -5.951],
                    [0, 0],
                    [5.951, -9.305]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [5.951, -9.305],
                    [0, 0],
                    [9.305, 5.951],
                    [0, 0]
                  ],
                  v: [
                    [-42.122, 98.937],
                    [-73.788, 111.329],
                    [-75.82, 77.386],
                    [38.969, -102.102],
                    [66.593, -108.176],
                    [66.593, -108.176],
                    [72.667, -80.551]
                  ],
                  c: true
                }
              },
              nm: 'Path 1',
              hd: false
            },
            { ty: 'st', c: { a: 0, k: [0, 0, 0, 0] }, o: { a: 0, k: 100 }, w: { a: 0, k: 17.5 }, lc: 2, lj: 2, bm: 0, nm: 'Stroke 1', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [394.462, 279.128] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 2',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Layer 11',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [202.078, 368.327, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-41.482, 0],
                    [41.482, 0]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.5], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 14, s: [0] },
              { t: 24, s: [100] }
            ]
          },
          o: { a: 0, k: 0 },
          m: 1,
          nm: 'Trim In Path',
          hd: false
        }
      ],
      ip: 8,
      op: 98,
      st: 8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Layer 10',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [201.059, 314.624, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-41.482, 0],
                    [41.482, 0]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.5], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 12, s: [0] },
              { t: 22, s: [100] }
            ]
          },
          o: { a: 0, k: 0 },
          m: 1,
          nm: 'Trim In Path',
          hd: false
        }
      ],
      ip: 8,
      op: 98,
      st: 8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Layer 9',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [225.041, 260.92, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-66.482, 0],
                    [66.482, 0]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.5], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 10, s: [0] },
              { t: 20, s: [100] }
            ]
          },
          o: { a: 0, k: 0 },
          m: 1,
          nm: 'Trim In Path',
          hd: false
        }
      ],
      ip: 8,
      op: 98,
      st: 8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Layer 8',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [236.522, 207.216, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-78.982, 0],
                    [78.982, 0]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.5], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 8, s: [0] },
              { t: 18, s: [100] }
            ]
          },
          o: { a: 0, k: 0 },
          m: 1,
          nm: 'Trim In Path',
          hd: false
        }
      ],
      ip: 8,
      op: 98,
      st: 8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Layer 6',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [202.078, 368.327, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-41.482, 0],
                    [41.482, 0]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 100 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.5], y: [0] }, t: 6, s: [0] },
              { t: 16, s: [100] }
            ]
          },
          o: { a: 0, k: 0 },
          m: 1,
          nm: 'Trim Out Path',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Layer 5',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [201.059, 314.624, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-41.482, 0],
                    [41.482, 0]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 100 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.5], y: [0] }, t: 4, s: [0] },
              { t: 14, s: [100] }
            ]
          },
          o: { a: 0, k: 0 },
          m: 1,
          nm: 'Trim Out Path',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Layer 4',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [225.041, 260.92, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-66.482, 0],
                    [66.482, 0]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 100 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.5], y: [0] }, t: 2, s: [0] },
              { t: 12, s: [100] }
            ]
          },
          o: { a: 0, k: 0 },
          m: 1,
          nm: 'Trim Out Path',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Layer 3',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [236.522, 207.216, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-78.982, 0],
                    [78.982, 0]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 100 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.5], y: [0] }, t: 0, s: [0] },
              { t: 10, s: [100] }
            ]
          },
          o: { a: 0, k: 0 },
          m: 1,
          nm: 'Trim Out Path',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Layer 2',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [238.721, 287.735, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.27, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [-13.836, 0.04],
                          [0, 0],
                          [0, 13.779],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-13.807, 0],
                          [0, 0],
                          [0, 13.836],
                          [0, 0],
                          [13.779, -0.04],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [32.778, -180.786],
                          [-107.392, -180.786],
                          [-132.392, -155.786],
                          [-132.392, 155.786],
                          [-107.319, 180.786],
                          [107.465, 180.16],
                          [132.392, 155.16],
                          [132.392, 60.975]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.75, y: 0 },
                    t: 10,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [-13.836, 0.04],
                          [0, 0],
                          [0, 13.779],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-13.807, 0],
                          [0, 0],
                          [0, 13.836],
                          [0, 0],
                          [13.779, -0.04],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [32.778, -180.786],
                          [-107.392, -180.786],
                          [-132.392, -155.786],
                          [-132.392, 155.786],
                          [-107.319, 180.786],
                          [107.465, 180.16],
                          [132.392, 155.16],
                          [132.505, 143.475]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    t: 20,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [-13.836, 0.04],
                          [0, 0],
                          [0, 13.779],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-13.807, 0],
                          [0, 0],
                          [0, 13.836],
                          [0, 0],
                          [13.779, -0.04],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [32.778, -180.786],
                          [-107.392, -180.786],
                          [-132.392, -155.786],
                          [-132.392, 155.786],
                          [-107.319, 180.786],
                          [107.465, 180.16],
                          [132.392, 155.16],
                          [132.392, 60.975]
                        ],
                        c: false
                      }
                    ]
                  }
                ]
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'Layer 1',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [350.056, 165.445, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.27, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [13.807, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [21.057, 58.496],
                          [21.057, -33.496],
                          [-3.943, -58.496],
                          [-21.057, -58.496]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.75, y: 0 },
                    t: 10,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [13.807, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [21.17, 214.496],
                          [21.057, -33.496],
                          [-3.943, -58.496],
                          [-21.057, -58.496]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    t: 20,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [13.807, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [21.057, 58.496],
                          [21.057, -33.496],
                          [-3.943, -58.496],
                          [-21.057, -58.496]
                        ],
                        c: false
                      }
                    ]
                  }
                ]
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const deliveryLottie = {
  v: '5.7.3',
  fr: 30,
  ip: 0,
  op: 64,
  w: 576,
  h: 576,
  nm: 'Truck',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Layer 8',
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [142.361, 94.986, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -25.871],
                    [25.871, 0],
                    [0, 25.871],
                    [-25.871, 0]
                  ],
                  o: [
                    [0, 25.871],
                    [-25.871, 0],
                    [0, -25.871],
                    [25.871, 0]
                  ],
                  v: [
                    [46.844, 0],
                    [0, 46.844],
                    [-46.844, 0],
                    [0, -46.844]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 0], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Layer 7',
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-113.156, 94.986, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -25.871],
                    [25.871, 0],
                    [0, 25.871],
                    [-25.871, 0]
                  ],
                  o: [
                    [0, 25.871],
                    [-25.871, 0],
                    [0, -25.871],
                    [25.871, 0]
                  ],
                  v: [
                    [46.844, 0],
                    [0, 46.844],
                    [-46.844, 0],
                    [0, -46.844]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 0], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Layer 6',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [75.725, 96.383, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-61.637, 15.417],
                    [66.637, 15.417],
                    [66.637, -15.417]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 3,
      nm: 'Null 27',
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            { i: { x: 0.667, y: 1 }, o: { x: 0.167, y: 0.167 }, t: 0, s: [288, 288, 0], to: [-4.333, 0, 0], ti: [-4, 0, 0] },
            { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 20, s: [262, 288, 0], to: [4, 0, 0], ti: [-4.333, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.333, y: 0 }, t: 44, s: [312, 288, 0], to: [4.333, 0, 0], ti: [4, 0, 0] },
            { t: 64, s: [288, 288, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Layer 5',
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 20, s: [0.2] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 40, s: [-0.3] },
            { t: 64, s: [0] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 0, s: [23.472, 1.444, 0], to: [0, -0.167, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 4, s: [23.472, 0.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 8, s: [23.472, 1.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 12, s: [23.472, 0.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 16, s: [23.472, 1.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 20, s: [23.472, 0.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 24, s: [23.472, 1.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 28, s: [23.472, 0.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 32, s: [23.472, 1.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 36, s: [23.472, 0.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 40, s: [23.472, 1.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 44, s: [23.472, 0.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 48, s: [23.472, 1.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 52, s: [23.472, 0.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 56, s: [23.472, 1.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 60, s: [23.472, 0.444, 0], to: [0, 0, 0], ti: [0, -0.167, 0] },
            { t: 64, s: [23.472, 1.444, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [101.11, 3.914, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [13.807, 0],
                    [0, 0],
                    [0, -13.807],
                    [0, 0],
                    [-13.807, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, -13.807],
                    [0, 0],
                    [-13.807, 0],
                    [0, 0],
                    [0, 13.807],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [142.363, 22.819],
                    [142.363, -86.8],
                    [117.363, -111.8],
                    [-117.363, -111.8],
                    [-142.363, -86.8],
                    [-142.363, 86.8],
                    [-117.363, 111.8],
                    [-85.113, 111.8]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Layer 4',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [219.304, 1.8, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [75.556, 0],
                    [-75.556, 0]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Layer 3',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [158.887, 111.8, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [11.525, 0],
                    [-16.525, 0]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Layer 2',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [220, 18.05, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-4.572, -4.05],
                    [0, 0],
                    [0, -7.156],
                    [0, 0],
                    [13.807, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [6.108, 0],
                    [0, 0],
                    [5.357, 4.745],
                    [0, 0],
                    [0, 13.807],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-77.637, -93.75],
                    [-19.342, -93.75],
                    [-2.766, -87.465],
                    [69.213, -23.711],
                    [77.637, -4.997],
                    [77.637, 68.75],
                    [52.637, 93.75],
                    [49.587, 93.75]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const productsLottie = {
  v: '5.8.1',
  fr: 30,
  ip: 0,
  op: 40,
  w: 576,
  h: 576,
  nm: 'Label',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Layer 4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.414], y: [1] }, o: { x: [0.88], y: [0] }, t: 0, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.535], y: [0] }, t: 10, s: [-15] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 22, s: [15] },
            { t: 32, s: [0] }
          ],
          ix: 10
        },
        p: { a: 0, k: [153.146, 120.392, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-25.856, -33.396, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [25.856, 33.396],
                    [-25.856, -33.396]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Layer 3',
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.414], y: [1] }, o: { x: [0.88], y: [0] }, t: 3, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.535], y: [0] }, t: 13, s: [-67.444] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 25, s: [67.95] },
            { t: 35, s: [0] }
          ],
          ix: 10
        },
        p: { a: 0, k: [-4.293, 4.459, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-2.192, -2.962],
                    [26.787, -19.828],
                    [19.828, 26.787],
                    [-26.787, 19.828],
                    [-6.671, 1.941]
                  ],
                  o: [
                    [2.592, 2.439],
                    [19.828, 26.787],
                    [-26.787, 19.828],
                    [-19.828, -26.787],
                    [5.911, -4.375],
                    [0, 0]
                  ],
                  v: [
                    [41.305, -45.203],
                    [48.502, -37.099],
                    [35.902, 47.305],
                    [-48.502, 34.705],
                    [-35.902, -49.699],
                    [-16.874, -59.15]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 0], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Layer 2',
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-76.721, -90.285, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.182, -9.293],
                    [9.293, 1.182],
                    [-1.182, 9.293],
                    [-9.293, -1.182]
                  ],
                  o: [
                    [-1.182, 9.293],
                    [-9.293, -1.182],
                    [1.182, -9.293],
                    [9.293, 1.182]
                  ],
                  v: [
                    [16.826, 2.141],
                    [-2.141, 16.826],
                    [-16.826, -2.141],
                    [2.141, -16.826]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Layer 1',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.414], y: [1] }, o: { x: [0.88], y: [0] }, t: 5, s: [0] },
            { i: { x: [0.509], y: [1] }, o: { x: [0.644], y: [0] }, t: 15, s: [-7.666] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.517], y: [0] }, t: 27, s: [7.526] },
            { t: 37, s: [0] }
          ],
          ix: 10
        },
        p: { a: 0, k: [5.964, 7.762, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-123, -150, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [10.917, -8.453],
                    [0, 0],
                    [8.453, 10.917],
                    [0, 0],
                    [-0.56, 6.281],
                    [0, 0],
                    [-12.371, -2.049],
                    [0, 0],
                    [-3.86, -4.986],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [-10.917, 8.453],
                    [0, 0],
                    [-3.86, -4.986],
                    [0, 0],
                    [1.114, -12.49],
                    [0, 0],
                    [6.221, 1.03],
                    [0, 0],
                    [8.453, 10.917]
                  ],
                  v: [
                    [130.989, 62.099],
                    [18.79, 148.968],
                    [-16.282, 144.505],
                    [-135.453, -9.415],
                    [-140.586, -26.941],
                    [-130.984, -134.561],
                    [-105.997, -153.907],
                    [0.598, -136.252],
                    [16.281, -126.893],
                    [135.452, 27.027]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const reviewOrderLottie = {
  v: '5.7.14',
  fr: 30,
  ip: 0,
  op: 31,
  w: 576,
  h: 576,
  nm: 'We Design Service',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Layer 7',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [287.861, 253.14, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.2, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [13.807, 0],
                          [0, 0],
                          [0, 13.807],
                          [0, 0],
                          [-13.807, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [13.807, 0],
                          [0, 0],
                          [0, 13.807],
                          [0, 0],
                          [-13.807, 0],
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [117.014, -145.567],
                          [155.887, -145.567],
                          [180.887, -120.567],
                          [180.887, 120.567],
                          [155.887, 145.567],
                          [-155.887, 145.567],
                          [-180.887, 120.567],
                          [-180.887, -120.567],
                          [-155.887, -145.567],
                          [59.329, -145.567]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.8, y: 0 },
                    t: 15,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [13.807, 0],
                          [0, 0],
                          [0, 13.807],
                          [0, 0],
                          [-13.807, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [13.807, 0],
                          [0, 0],
                          [0, 13.807],
                          [0, 0],
                          [-13.807, 0],
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-74.986, -145.831],
                          [155.887, -145.567],
                          [180.887, -120.567],
                          [180.887, 120.567],
                          [155.887, 145.567],
                          [-155.887, 145.567],
                          [-180.887, 120.567],
                          [-180.887, -120.567],
                          [-155.887, -145.567],
                          [-132.671, -145.831]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    t: 30,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [13.807, 0],
                          [0, 0],
                          [0, 13.807],
                          [0, 0],
                          [-13.807, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [13.807, 0],
                          [0, 0],
                          [0, 13.807],
                          [0, 0],
                          [-13.807, 0],
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [117.014, -145.567],
                          [155.887, -145.567],
                          [180.887, -120.567],
                          [180.887, 120.567],
                          [155.887, 145.567],
                          [-155.887, 145.567],
                          [-180.887, 120.567],
                          [-180.887, -120.567],
                          [-155.887, -145.567],
                          [59.329, -145.567]
                        ],
                        c: false
                      }
                    ]
                  }
                ]
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Layer 6',
      parent: 3,
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [33.733, -33.732, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [22.992, 22.992],
                    [-22.992, -22.992]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            { ty: 'st', c: { a: 0, k: [0, 0, 0, 0] }, o: { a: 0, k: 100 }, w: { a: 0, k: 17.5 }, lc: 2, lj: 2, bm: 0, nm: 'Stroke 1', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Layer 5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.2], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.8], y: [0] }, t: 15, s: [-89] },
            { t: 30, s: [0] }
          ]
        },
        p: {
          a: 1,
          k: [
            { i: { x: 0.2, y: 1 }, o: { x: 0.333, y: 0 }, t: 0, s: [191.311, 354.602, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.667, y: 1 }, o: { x: 0.8, y: 0 }, t: 15, s: [390.544, 354.602, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { t: 30, s: [191.311, 354.602, 0] }
          ]
        },
        a: { a: 0, k: [-96.55, 96.555, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [96.55, -50.566],
                          [50.561, -96.555],
                          [-84.561, 38.567],
                          [-96.55, 96.555],
                          [-38.572, 84.556]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 1, y: 0 },
                    t: 15,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [74.667, -28.705],
                          [28.678, -74.694],
                          [-84.561, 38.567],
                          [-96.55, 96.555],
                          [-38.572, 84.556]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    t: 30,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [96.55, -50.566],
                          [50.561, -96.555],
                          [-84.561, 38.567],
                          [-96.55, 96.555],
                          [-38.572, 84.556]
                        ],
                        c: true
                      }
                    ]
                  }
                ]
              },
              nm: 'Path 1',
              hd: false
            },
            { ty: 'st', c: { a: 0, k: [0, 0, 0, 0] }, o: { a: 0, k: 100 }, w: { a: 0, k: 17.5 }, lc: 2, lj: 2, bm: 0, nm: 'Stroke 1', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Layer 4',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [322.09, 433.678, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [0, -34.971],
                    [0, 34.971]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Layer 3',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [253.632, 433.678, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [0, 34.971],
                    [0, -34.971]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Layer 2',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [287.861, 468.65, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [91.696, 0],
                    [-91.696, 0]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
