import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SalesQuoteModel } from 'models/Quotes';

interface InitialState {
  quoteToDuplicate?: SalesQuoteModel;
  duplicateMode?: boolean;
}

const initialState: InitialState = {
  quoteToDuplicate: undefined,
  duplicateMode: false
};

export const duplicateQuoteSlice = createSlice({
  name: 'duplicateQuoteSlice',
  initialState,
  reducers: {
    setQuoteToDuplicate: (state, { payload }: PayloadAction<SalesQuoteModel | undefined>) => {
      state.quoteToDuplicate = payload;
    },
    setDuplicateMode: (state, { payload }: PayloadAction<boolean>) => {
      state.duplicateMode = payload;
    }
  }
});

export const { setQuoteToDuplicate, setDuplicateMode } = duplicateQuoteSlice.actions;
