import { StyleSheet, View } from '@react-pdf/renderer';
import { ProductType } from 'common/helpers/dummyData';
import { QuotePayload } from 'models/Quotes';
import InvoiceTableFooter from './InvoiceTableFooter';
import InvoiceTableHeader from './InvoiceTableHeader';
import InvoiceTableRow from './InvoiceTableRow';

// const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderColor: 'rgb(132, 133, 154)'
  }
});

const InvoiceItemsTable = ({ products }: { invoice: QuotePayload; products: ProductType[] }): JSX.Element => {
  return (
    <View style={styles.tableContainer}>
      <InvoiceTableHeader />
      <InvoiceTableRow lines={products} />
      <InvoiceTableFooter items={products} />
    </View>
  );
};

export default InvoiceItemsTable;
