import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { ProductType } from 'common/helpers/dummyData';
import BillTo from 'components/atoms/BillTo';
import InvoiceItemsTableDiscount from 'components/reports/InvoiceItemsTableDiscount';
import InvoiceTitle from 'components/reports/InvoiceTitle';
import { Signature } from 'components/reports/Signature';
import { TOS } from 'components/reports/TOS';
import { CompanyModel } from 'models/CompanyModel';
import { QuotePayload } from 'models/Quotes';

interface Props {
  pdfData: QuotePayload;
  products: ProductType[];
  legalEntity?: CompanyModel;
}

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 10,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column'
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  center: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  pageNum: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 30,
    marginBottom: 10
  }
});

export const PdfGeneratorDiscount: React.FC<Props> = ({ pdfData, products, legalEntity }: Props) => {
  const imgSource = legalEntity?.logoUrl;

  return (
    <Document>
      <Page orientation="landscape" size="A3" style={styles.page}>
        <View style={styles.center}>
          <Image style={{ height: 150, width: 200 }} source={imgSource} src={imgSource} />
        </View>
        <InvoiceTitle title="Sales Quote" />
        <BillTo legalEntity={legalEntity} invoice={pdfData} />
        <InvoiceItemsTableDiscount products={products} invoice={pdfData} />
        {pdfData.notes && (
          <View style={{ marginTop: 50 }}>
            <Text>Notes: {pdfData.notes ?? ''}</Text>
          </View>
        )}
        <View wrap break style={{ marginTop: 50 }}>
          <TOS legalEntity={legalEntity} />
        </View>
        <View style={{ marginTop: 50 }}>
          <Signature legalEntity={legalEntity} pdfData={pdfData} />
        </View>
        <View style={styles.pageNum} fixed>
          <Text render={({ pageNumber, totalPages }: { pageNumber: number; totalPages: number }): string => `Page ${pageNumber} / ${totalPages}`} fixed />
        </View>
      </Page>
    </Document>
  );
};
