import { PlusOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import { useState } from 'react';

export const SubtractButton = ({ ...rest }: ButtonProps): JSX.Element => {
  const [spin, setSpin] = useState(false);

  const animate = (): void => {
    setSpin(true);

    setTimeout(() => setSpin(false), 500);
  };

  return <Button disabled {...rest} icon={<PlusOutlined />} style={{ background: 'lightgray', borderRadius: '50%' }} onClick={animate} className={spin ? `btn-spin-danger` : undefined} />;
};
