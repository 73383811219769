import { Space, Tooltip, Typography } from 'antd';
import { ProductType } from 'common/helpers/dummyData';
import { useField } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'redux/store';

interface Props {
  record: ProductType;
  idx: number;
}

export const ProductNumberCell: FC<Props> = ({ record, idx }: Props) => {
  const { selectedCompanyDataAreaId } = useSelector((state: ReduxState) => state.orders);
  const [a, { value: currentProductNumber }, c] = useField(`lines[${idx}].productNumber`);
  const [x, y, { setValue: setManufacturer }] = useField(`lines[${idx}].manufacturer`);
  const [n, m, { setValue: setCategory }] = useField(`lines[${idx}].category`);

  return (
    <Space>
      <Tooltip title={record.productNumber}>
        <Typography.Text>{currentProductNumber}</Typography.Text>
      </Tooltip>
    </Space>
  );
};
