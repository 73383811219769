import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { moneyFormatter } from 'common/helpers/CurrencyFormatter';
import { ProductType } from 'common/helpers/dummyData';

const borderColor = '#1f1e2e';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#1f1e2e',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontSize: 12,
    fontStyle: 'bold',
    borderLeftColor: '1f1e2e',
    borderLeftWidth: 1,
    borderRightColor: '1f1e2e',
    borderRightWidth: 1
  },
  description: {
    width: '90%',
    textAlign: 'right',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8
  },
  total: {
    width: '10%',
    textAlign: 'right',
    paddingRight: 8
  }
});

const InvoiceTableFooterDiscount = ({ items }: { items: ProductType[] }): JSX.Element => {
  const total = items.map((item) => (item?.quantity ?? 0) * (item?.unitPrice ?? 0)).reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return (
    <View style={styles.row}>
      <Text style={styles.description}>TOTAL</Text>
      <Text style={styles.total}>{moneyFormatter.format(Number(total))}</Text>
    </View>
  );
};

export default InvoiceTableFooterDiscount;
