import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Card, Col, Divider, Modal, Row, Space, Table, Tooltip, Typography, message } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { moneyFormatter } from 'common/helpers/CurrencyFormatter';
import { ProductType } from 'common/helpers/dummyData';
import { colors, toRgb, toRgba } from 'common/styles/colors';
import { PdfDownloadButton } from 'components/atoms/PdfDownloadButton';
import { PdfDownloadButtonDiscount } from 'components/atoms/PdfDownloadButtonDiscount';
import { SublineTable } from 'components/molecules/SublineTable';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { QuotePayload } from 'models/Quotes';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDeleteQuoteMutation, useGetQuoteQuery } from 'redux/services/samTheRobot/samTheRobotApi';
import { useAppSelector } from 'redux/store';

const styles: InlineStylesModel = {
  mainContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: 0,
    width: '100%'
    // height: 'calc(100vh - 112px - 46px - 50px - 110px - 25px )'
  },
  cardsContainer: {
    padding: '0px',
    margin: '0px'
  },

  infoTitle: {
    fontSize: '16px',
    fontWeight: 600
  },
  infoText: {
    fontSize: '15px',
    minHeight: '27px'
  },
  cardHead: {
    fontSize: '18px',
    fontWeight: 600
  },
  cardBody: {
    padding: '25px 20px 20px 25px'
  },
  headerCard: {
    backgroundColor: '#FFEAC1'
  },
  deliveryCard: {
    backgroundColor: '#CED5F2'
  },
  productsTableCard: {
    backgroundColor: '#b0b2bc'
  },
  tableBody: {
    padding: 0
  },
  extra: {
    fontSize: '15px',
    fontWeight: 500
  },
  addressLine: {
    marginBottom: 0
  },
  attention: {
    maxWidth: '300px'
  },
  red: {
    color: toRgb(colors.redSalsa)
  }
};

interface EditableColumnType extends ColumnType<ProductType> {
  editable?: boolean;
}

export const Overview = (): JSX.Element => {
  const {
    values: {
      quoteId,
      buyerId,
      buyerName,
      customerErpId,
      customerAccount,
      createdByUserFullName,
      customerName,
      customerDataAreaId,
      dataAreaId,
      isActive,
      lines,
      notes,
      quoteExpiresInDays,
      quoteName,
      salesPersonName,
      salesPersonId,
      deliveryAddressStreet1,
      deliveryAddressCity,
      deliveryAddressCountry,
      deliveryAddressCounty,
      deliveryAddressSubdivision,
      deliveryAddressPostalCode,
      deliveryAddressName,
      totalCost,
      totalMargin,
      totalPrice,
      totalProfit,
      quoteExpirationDate
    }
  } = useFormikContext<QuotePayload>();

  const nav = useNavigate();
  const { quoteGUID, id } = useParams();
  const { pathname } = useLocation();
  const isView = pathname.includes('view');

  const { showExtraColumns } = useAppSelector((state) => state.productsInfo);
  const { data, isLoading, isFetching } = useGetQuoteQuery(id ?? skipToken);
  const [deleteQuote] = useDeleteQuoteMutation();

  /* ****************** Functions ****************** */
  const columns: EditableColumnType[] = [
    {
      title: 'Line',
      width: 40,
      render: (_, record, index): JSX.Element => {
        return <div>{`${index + 1}`}</div>;
      }
    },
    {
      title: 'Product Number',
      dataIndex: 'productNumber',
      key: 'productNumber',
      width: 175,
      editable: true,
      ellipsis: true,
      render: (_: string, record: ProductType): JSX.Element => (
        <Tooltip title={record.productNumber}>
          <Typography.Text>{record.productNumber}</Typography.Text>
        </Tooltip>
      )
    },
    {
      title: 'Description',
      dataIndex: 'productDescription',
      key: 'productDescription',
      ellipsis: true,
      editable: true,
      width: 250,
      render: (_: string, record: ProductType): JSX.Element => <Tooltip title={record.productDescription}>{record.productDescription}</Tooltip>
    },
    { title: 'Manufacturer', dataIndex: 'manufacturer', key: 'manufacturer', width: 100 },
    { title: 'Category', dataIndex: 'category', key: 'category', width: 100 },
    { title: 'Condition', dataIndex: 'condition', key: 'condition', width: 100, editable: true },
    { title: 'Vendor', dataIndex: 'vendorId', key: 'vendorId', width: 100, editable: true },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
      editable: true,
      width: 70
    },
    {
      title: 'List Price',
      dataIndex: 'listPrice',
      key: 'listPrice',
      editable: true,
      width: 125,
      align: 'right',
      render: (_: string, record: ProductType): JSX.Element => <>{moneyFormatter.format(Number(record.listPrice))}</>
    },
    {
      title: 'Discount',
      dataIndex: 'listDiscountPercentage',
      key: 'listDiscountPercentage',
      editable: true,
      align: 'right',
      width: 80,
      render: (_: string, record: ProductType): JSX.Element => <>{record.listDiscountPercentage}%</>
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      editable: true,
      width: 125,
      align: 'right',
      render: (_: string, record: ProductType): JSX.Element => (
        <>{record.unitPrice ? moneyFormatter.format(record.unitPrice) : moneyFormatter.format(Number(record.listPrice) - (Number(record.listPrice) * Number(record.listDiscountPercentage)) / 100)}</>
      )
    },
    {
      title: 'Unit Cost',
      dataIndex: 'unitCost',
      key: 'unitCost',
      editable: true,
      width: 125,
      align: 'right',
      render: (_: string, record: ProductType): JSX.Element => <>{moneyFormatter.format(Number(record.unitCost))}</>
    },
    {
      title: 'Ext Price',
      dataIndex: 'extendedPrice',
      key: 'extendedPrice',
      width: 125,
      align: 'right',
      render: (_: string, record: ProductType): JSX.Element => <>{moneyFormatter.format(Number(record.extendedPrice))}</>
    },
    {
      title: 'Ext Cost',
      dataIndex: 'extendedCost',
      key: 'extendedCost',
      width: 125,
      align: 'right',
      render: (_: string, record: ProductType): JSX.Element => <>{moneyFormatter.format(Number(record.extendedCost))}</>
    },
    {
      title: 'Margin',
      dataIndex: 'grossProfit',
      key: 'grossProfit',
      width: 125,
      align: 'right',
      render: (_: number, record: ProductType): JSX.Element => (
        <div
          style={{
            color: Number(record.grossProfit) >= 0 ? 'black' : 'red'
          }}>
          {moneyFormatter.format(Number(record.grossProfit))}
        </div>
      )
    },
    {
      title: 'Margin %',
      dataIndex: 'grossMargin',
      key: 'grossMargin',
      width: 100,
      align: 'right',
      render: (_: string, record: ProductType): JSX.Element => (
        <div
          style={{
            color: Number(record.grossMargin) < 0 ? 'red' : 'black'
          }}>
          {isNaN(Number(record.grossMargin)) || !isFinite(Number(record.grossMargin)) ? 0 : Number(record.grossMargin).toFixed(2)}%
        </div>
      )
    }
  ];
  /* ****************** JSX  / Variables ****************** */
  const formattedAddressJSX = (
    <Typography.Paragraph style={{ ...styles.infoText, ...styles.addressLine }}>
      {deliveryAddressStreet1} <br /> {deliveryAddressCity}, {deliveryAddressSubdivision} {deliveryAddressPostalCode} &nbsp;{deliveryAddressCountry}
    </Typography.Paragraph>
  );

  const handleDelete: React.MouseEventHandler<HTMLElement> = async (event): Promise<void> => {
    if (id) {
      Modal.error({
        width: 600,
        okText: 'Delete',
        okType: 'danger',
        onOk: async () => {
          try {
            event.stopPropagation();
            await deleteQuote(id).unwrap();
          } catch (error) {
            message.error((error as { data: { errorMessage: string } }).data.errorMessage);
          }
          nav('/search');
        },
        cancelText: 'Cancel',
        onCancel: () => {
          console.log('canceled popup');
        },
        closable: true,
        title: <Typography.Title level={5}>Delete Quote?</Typography.Title>,
        content: <Typography.Text>Are you sure you want to delete this Quote? You can still recover it in the Recycle Bin.</Typography.Text>
      });
    }
  };

  const headerInfoCardJSX = (
    <Card style={{ height: '100%', width: '100%' }} size="small" title="General" bordered={false} headStyle={{ ...styles.cardHead, ...styles.headerCard }} bodyStyle={styles.cardBody}>
      <Row gutter={16}>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Quote Id:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{quoteId || 'AUTOGENERATED'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Created By:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{createdByUserFullName || 'n/a'}</Typography.Paragraph>
        </Col>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Name:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{quoteName || 'n/a'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Salesperson:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{salesPersonName || 'n/a'}</Typography.Paragraph>
        </Col>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Sales Order Type:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{'Quote'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Buyer:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{buyerName || 'n/a'}</Typography.Paragraph>
        </Col>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Notes:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{notes || 'None'}</Typography.Paragraph>
        </Col>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Expiration Date:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{moment(quoteExpirationDate).format('MM-DD-YYYY')}</Typography.Paragraph>
        </Col>
        <Col span={24} style={{ position: 'absolute', bottom: 0, left: 0 }}>
          {quoteGUID ? <Typography.Paragraph style={{ color: toRgba(colors.raisinBlackDark, 0.3) }}>Quote GUID: {quoteGUID}</Typography.Paragraph> : null}
        </Col>
      </Row>
    </Card>
  );

  const deliveryInfoCardJSX = (
    <Card style={{ height: '100%', width: '100%' }} size="small" title="Delivery Info" bordered={false} headStyle={{ ...styles.cardHead, ...styles.deliveryCard }} bodyStyle={styles.cardBody}>
      <Row gutter={[32, 0]}>
        <Col span={16}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Customer:
          </Typography.Title>
          {customerName ? (
            <Typography.Paragraph style={styles.infoText}>{customerName}</Typography.Paragraph>
          ) : (
            <Button onClick={(): void => nav('/create')} style={{ minWidth: '200px', marginBottom: 10 }}>
              Select Customer
            </Button>
          )}
          <Typography.Title level={5} style={styles.infoTitle}>
            Delivery Address:
          </Typography.Title>
          {deliveryAddressCity && deliveryAddressSubdivision && deliveryAddressStreet1 ? (
            <Typography.Paragraph style={styles.infoText}>{formattedAddressJSX}</Typography.Paragraph>
          ) : isView ? (
            <Typography.Paragraph style={styles.infoText}>Saved Without an Address</Typography.Paragraph>
          ) : (
            <Button onClick={(): void => (id ? nav(`/edit/${id}/delivery-address`) : nav('/create/delivery-address'))} style={{ minWidth: '200px', marginBottom: 10 }}>
              Select Address
            </Button>
          )}
        </Col>

        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Account:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{customerAccount}</Typography.Paragraph>
        </Col>
      </Row>
    </Card>
  );

  const productInfoCardJSX = (
    <Card
      style={{ height: '100%', width: '100%' }}
      size="small"
      title={
        <Row align="middle" justify="space-between" style={{ width: '100%' }}>
          <Col>
            <Typography.Text>Line Items</Typography.Text>
          </Col>
          <Row align="middle" gutter={[5, 5]}>
            <Col>
              <Space align="center" size={12}>
                <Typography.Text style={{ fontSize: 14 }}>Quote Price: {moneyFormatter.format(totalPrice)}</Typography.Text>
                <Divider style={{ margin: '0 2px' }} type="vertical" />
                <Typography.Text style={{ fontSize: 14 }}>Cost: {moneyFormatter.format(totalCost)}</Typography.Text>
                <Divider style={{ margin: '0 2px' }} type="vertical" />
                <Typography.Text style={{ marginRight: 10, fontSize: 14 }}>Profit: {moneyFormatter.format(totalProfit)}</Typography.Text>
              </Space>
            </Col>
            <Col>
              <PdfDownloadButton />
            </Col>
            <Col>
              <PdfDownloadButtonDiscount />
            </Col>
            {/* <Col>
              <ColumnConfigModal />
            </Col> */}
          </Row>
        </Row>
      }
      bordered={false}
      headStyle={{ ...styles.cardHead, ...styles.productsTableCard }}
      bodyStyle={styles.cardBody}>
      <Table
        style={{ marginTop: 5 }}
        scroll={{ x: 3000 }}
        rowKey={(row): string => `${row.productNumber}-${row.condition ?? 'NEW'}`}
        columns={columns}
        pagination={false}
        dataSource={lines}
        expandable={{
          columnWidth: 30,
          expandIcon: ({ onExpand, expanded, record }): JSX.Element | undefined => {
            if (!(record as unknown as { subLines: typeof record.subLines[] }).subLines) return undefined;
            if ((record as unknown as { subLines: typeof record.subLines[] }).subLines.length === 0) return undefined;

            return expanded ? (
              <DownOutlined
                style={{
                  fontSize: 13
                }}
                onClick={(e): void => {
                  e.preventDefault();
                  e.stopPropagation();
                  onExpand(record, e);
                }}
              />
            ) : (
              <RightOutlined
                style={{
                  fontSize: 13
                }}
                onClick={(e): void => {
                  e.preventDefault();
                  e.stopPropagation();
                  onExpand(record, e);
                }}
              />
            );
          },
          expandedRowRender: (record, index): React.ReactNode => {
            return <SublineTable index={index} product={record} subLines={record.subLines ?? []} />;
          }
        }}
      />
    </Card>
  );

  /* ****************** Render ****************** */
  return (
    <Row style={styles.mainContainer} gutter={[0, 16]}>
      <Col span={24} style={styles.cardsContainer}>
        <Row gutter={[16, 16]}>
          <Col lg={24} xl={12} style={{ width: '100%' }}>
            {headerInfoCardJSX}
          </Col>
          <Col lg={24} xl={12} style={{ width: '100%' }}>
            {deliveryInfoCardJSX}
          </Col>
        </Row>
      </Col>
      <Col span={24} style={styles.cardsContainer}>
        <Row gutter={[0, 0]}>
          <Col span={24}>{productInfoCardJSX}</Col>
        </Row>
      </Col>
      {id && (
        <Col span={24} style={styles.cardsContainer}>
          <Row gutter={[0, 0]} style={{ margin: '5px 4px 15px 4px' }} justify="space-between">
            <Col span={12}>
              <Typography.Text style={{ color: 'rgba(0,0,0,.6)' }}>Created By: {data?.createdByUserFullName}</Typography.Text>
            </Col>
            <Button danger type="ghost" onClick={handleDelete}>
              Delete
            </Button>
          </Row>
        </Col>
      )}
    </Row>
  );
};
