import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QuoteParams } from 'models/Quotes';

const initialState: QuoteParams = {
  offset: 0,
  dataAreaId: undefined,
  quoteNameContains: undefined,
  quoteIdContains: undefined,
  customerErpIdContains: undefined,
  returnDeletedDataOnly: undefined,
  startDate: undefined,
  endDate: undefined,
  quoteStatuses: undefined,
  orderByDirection: 'descending',
  placedByUserNameContains: undefined,
  customerNameContains: undefined
};

export const samTheRobotQuoteParams = createSlice({
  name: 'samTheRobotQuoteParams',
  initialState,
  reducers: {
    setOffset: (state, { payload }: PayloadAction<QuoteParams['offset']>) => {
      state.offset = payload;
    },
    setQuoteNameContains: (state, { payload }: PayloadAction<QuoteParams['quoteNameContains']>) => {
      state.quoteNameContains = payload;
    },
    setQuoteIdContains: (state, { payload }: PayloadAction<QuoteParams['quoteIdContains']>) => {
      state.quoteIdContains = payload;
    },
    setPlacedByUserNameContains: (state, { payload }: PayloadAction<QuoteParams['placedByUserNameContains']>) => {
      state.placedByUserNameContains = payload;
    },
    setCustomerNameContains: (state, { payload }: PayloadAction<QuoteParams['customerNameContains']>) => {
      state.customerNameContains = payload;
    },
    setCustomerErpIdContains: (state, { payload }: PayloadAction<QuoteParams['customerErpIdContains']>) => {
      state.customerErpIdContains = payload;
    },
    setReturnDeletedDataOnly: (state, { payload }: PayloadAction<QuoteParams['returnDeletedDataOnly']>) => {
      state.returnDeletedDataOnly = payload;
    },
    setStartDate: (state, { payload }: PayloadAction<QuoteParams['startDate']>) => {
      state.startDate = payload;
      state.offset = 0;
    },
    setEndDate: (state, { payload }: PayloadAction<QuoteParams['endDate']>) => {
      state.endDate = payload;
      state.offset = 0;
    },
    setStatuses: (state, { payload }: PayloadAction<QuoteParams['quoteStatuses']>) => {
      state.quoteStatuses = payload;
      state.offset = 0;
    },
    resetParams: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const {
  setQuoteNameContains,
  setQuoteIdContains,
  setCustomerErpIdContains,
  setCustomerNameContains,
  setPlacedByUserNameContains,
  setStartDate,
  setEndDate,
  setStatuses,
  resetParams,
  setReturnDeletedDataOnly,
  setOffset
} = samTheRobotQuoteParams.actions;
