import { Col, Collapse, Row } from 'antd';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
const { Panel } = Collapse;

export const ReleaseNotesPage = (): JSX.Element => {
  const { version } = useParams();

  return (
    <Fragment>
      <Row justify="space-between" style={{ padding: 10, background: 'rgb(255, 234, 193)', borderRadius: 5 }}>
        <Col>
          <AntPageTitle text={'Release Notes'} />
        </Col>
      </Row>
      <Collapse defaultActiveKey={['4.3']}>
        <Panel header="Version 4.3" key="4.3">
          <h2>General</h2>
          <ol>
            <li>Users may now choose how many copies of a quote to make when duplicating it.</li>
            <li>All data is now properly segregated by the legal entity.</li>
            <li>The expiration date calculation has been corrected.</li>
            <li>Minor updates have been made to the tabs and organization of the site.</li>
          </ol>
          <h2>Line Items</h2>
          <ol>
            <li>Manufacturer details have been added to the tables.</li>
            <li>Vendor data has been added to the line items and is copied to the sales order when a quote is won.</li>
            <li>Line numbers for ad-hoc submodules now work correctly.</li>
          </ol>
          <h2>Email</h2>
          <ol>
            <li>A new field has been added for users to add a message to their email. This field has been restricted to 1,000 characters.</li>
            <li>Users may no longer enter duplicate email addresses when sending the quote.</li>
            <li>The validation message for email formatting has been updated.</li>
          </ol>
          <h2>PDFs</h2>
          <ol>
            <li>PDFs will now display the logo of the selected legal entity.</li>
            <li>PDFs will now use the selected legal entity&apos;s address.</li>
            <li>PDFs will now properly reference the legal entity&apos;s name in the copy.</li>
            <li>PDFs will now display two decimals places for all currency values.</li>
          </ol>
          <h2>Attachments</h2>
          <ol>
            <li>Users may now add attachments to the quote. These attachments are added to the sales order records when a quote is won.</li>
          </ol>{' '}
        </Panel>
        <Panel header="Version 4.2" key="4.2">
          <h2>General</h2>
          <ol>
            <li>Submodules are now working when editing an existing row.</li>
          </ol>
        </Panel>
        <Panel header="Version 4.1" key="4.1">
          <h2>General</h2>
          <ol>
            <li>Address auto complete is now working again.</li>
            <li>Users may now select the Legal Entity for which the quote is being created.</li>
            <li>Downloading the PDF before the quote has been saved will now display the proper quote ids instead of saying autogenerated.</li>
            <li>Downloading the PDF will no longer change the status of a quote to draft.</li>
            <li>A button has been added to revert a won or lost quote back to a draft status.</li>
            <li>Users may now view release notes for the app by clicking their profile icon and clicking the release notes menu item.</li>
            <li>The name of the product quotes API has been updated from project code name to the business name in the about page.</li>
          </ol>
          <h2>Email</h2>
          <ol>
            <li>Users may now enter multiple email addresses instead of just one for sending the quote to customers.</li>
            <li>The buyer&apos;s email address is now automatically added to the list of email addresses for sending quotes.</li>
            <li>The salesperson&apos;s email address is now automatically added to the list of email addresses for sending quotes.</li>
            <li>The quote name has been added to the subject of the email address.</li>
            <li>A new field has been added to the header tab called Email Message and is now included in the email.</li>
            <li>The line &quot;Attached below is the product quote&quot; has been removed from the email.</li>
          </ol>
          <h2>Quote PDF</h2>
          <ol>
            <li>&quot;Prepared By&quot; now displays the name of the user who created the quote.</li>
            <li>&quot;Salesperson&quot; name has been added to the PDF.</li>
            <li>&quot;Sales Support&quot; has been removed from the PDF.</li>
            <li>Various formatting updates have been made to the header.</li>
          </ol>
        </Panel>
        <Panel header="Version 4.0" key="4.0">
          <h2>General</h2>
          <ol>
            <li>Initial Implementation</li>
          </ol>
        </Panel>
      </Collapse>
    </Fragment>
  );
};
