import { MailOutlined } from '@ant-design/icons';
import { Button, List, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { QuotePayload } from 'models/Quotes';
import { useState } from 'react';
import Lottie from 'react-lottie-player';
import { useLocation } from 'react-router-dom';
import deleteLottie from '../../common/assets/delete-lottie.json';

export const SubscriberList = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<QuotePayload>();
  const [hover, setHover] = useState<string | null>();
  const { pathname } = useLocation();
  const isView = pathname.includes('view');

  const handleRemoveNotificationEmail = (value: string): void => {
    setFieldValue(
      'customerEmailAddresses' as keyof QuotePayload,
      values?.customerEmailAddresses?.filter((email) => email !== value)
    );
  };

  return (
    <List
      bordered
      style={{ marginTop: 8 }}
      dataSource={values.customerEmailAddresses}
      renderItem={(email): JSX.Element => (
        <List.Item
          style={{ padding: 5 }}
          actions={
            !isView
              ? [
                  <Button
                    onClick={(): void => handleRemoveNotificationEmail(email)}
                    key={'delete'}
                    className="quote-card__menu-item-delete"
                    style={{ borderRadius: '50%', borderColor: '#dc3545' }}
                    icon={
                      <Lottie
                        onMouseEnter={(): void => setHover('delete')}
                        onMouseLeave={(): void => setHover(null)}
                        animationData={deleteLottie}
                        loop={false}
                        goTo={!hover ? 0 : undefined}
                        play={hover === 'delete'}
                        style={{ width: 25, height: 25, display: 'inline-block', transform: 'scale(1.25)' }}
                      />
                    }
                  />
                ]
              : undefined
          }>
          <MailOutlined style={{ fontSize: 16, marginRight: 8 }} />
          <Typography.Text style={{ flex: 1 }}>{email}</Typography.Text>
        </List.Item>
      )}
    />
  );
};
