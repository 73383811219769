import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { CompanyModel } from 'models/CompanyModel';
import { QuotePayload } from 'models/Quotes';

interface Props {
  pdfData: QuotePayload;
  legalEntity?: CompanyModel;
}

const styles = StyleSheet.create({
  parentRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 16
  },
  title: {
    fontSize: 10
  },
  text: {
    fontSize: 8
  }
});

export const Signature: React.FC<Props> = ({ pdfData, legalEntity }: Props) => {
  return (
    <View style={styles.parentRow}>
      <View>
        <Text style={{ marginBottom: 30, fontSize: 18 }}>{legalEntity?.dataAreaId.toUpperCase() || 'MDSI'}</Text>
        <View style={styles.row}>
          <Text style={{ marginRight: 16, width: 70 }}>Signature: </Text>
          <View style={{ borderBottomColor: '#1f1e2e', borderBottomWidth: 1, width: 300 }} />
        </View>
        <View style={styles.row}>
          <Text style={{ marginRight: 16, width: 70 }}>Name: </Text>
          <View style={{ borderBottomColor: '#1f1e2e', borderBottomWidth: 1, width: 300 }} />
        </View>
        <View style={styles.row}>
          <Text style={{ marginRight: 16, width: 70 }}>Title: </Text>
          <View style={{ borderBottomColor: '#1f1e2e', borderBottomWidth: 1, width: 300 }} />
        </View>
        <View style={styles.row}>
          <Text style={{ marginRight: 16, width: 70 }}>Date: </Text>
          <View style={{ borderBottomColor: '#1f1e2e', borderBottomWidth: 1, width: 300 }} />
        </View>
      </View>
      <View>
        <Text style={{ marginBottom: 30, fontSize: 18 }}>{pdfData?.alternateCustomerDisplayName || 'Customer'}</Text>
        <View style={styles.row}>
          <Text style={{ marginRight: 16, width: 70 }}>Signature: </Text>
          <View style={{ borderBottomColor: '#1f1e2e', borderBottomWidth: 1, width: 300 }} />
        </View>
        <View style={styles.row}>
          <Text style={{ marginRight: 16, width: 70 }}>Name: </Text>
          <View style={{ borderBottomColor: '#1f1e2e', borderBottomWidth: 1, width: 300 }} />
        </View>
        <View style={styles.row}>
          <Text style={{ marginRight: 16, width: 70 }}>Title: </Text>
          <View style={{ borderBottomColor: '#1f1e2e', borderBottomWidth: 1, width: 300 }} />
        </View>
        <View style={styles.row}>
          <Text style={{ marginRight: 16, width: 70 }}>Date: </Text>
          <View style={{ borderBottomColor: '#1f1e2e', borderBottomWidth: 1, width: 300 }} />
        </View>
      </View>
    </View>
  );
};
