import { PDFDownloadLink } from '@react-pdf/renderer';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, message, Tooltip } from 'antd';
import { PdfGeneratorDiscount } from 'components/molecules/PdfGeneratorDiscount';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { QuotePayload } from 'models/Quotes';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetQuoteQuery } from 'redux/services/samTheRobot/samTheRobotApi';
import { setQuoteSubmissionStatusMessage } from 'redux/slices/quoteSlice';
import { useAppSelector } from 'redux/store';

export const PdfDownloadButtonDiscount: React.FC = () => {
  const dispatch = useDispatch();
  const { initialValues, values, submitForm, setFieldValue } = useFormikContext<QuotePayload>();
  const { id } = useParams();

  const { data, isLoading } = useGetQuoteQuery(id ?? skipToken);
  const { selectedLegalEntity } = useAppSelector((state) => state.app);

  const handleSave = async (): Promise<void> => {
    if (data?.status === 'Won' || data?.status === 'Lost') return;
    if (!data?.status) {
      try {
        dispatch(setQuoteSubmissionStatusMessage('Saving sales quote'));
        setFieldValue('closeWizard', false);
        await submitForm();
      } catch (err) {
        console.log(err);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      }
    }
    if (data?.status === 'SentToCustomer') {
      if (!_.isEqual(initialValues, values)) {
        try {
          dispatch(setQuoteSubmissionStatusMessage('Saving sales quote'));
          setFieldValue('closeWizard', false);
          await submitForm();
        } catch (err) {
          console.log(err);
          message.error((err as { data: { errorMessage: string } }).data.errorMessage);
        }
      }
    }
  };

  // Remove download link because of loading
  if (!selectedLegalEntity || isLoading) {
    return (
      <Fragment>
        <Tooltip trigger={['hover']} title={!data?.status ? 'You must save before downloading pdf' : ''}>
          <Button disabled={!data?.status} loading={!selectedLegalEntity} onClick={handleSave}>
            Download PDF With Discount
          </Button>
        </Tooltip>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Tooltip trigger={['hover']} title={!data?.status ? 'You must save before downloading pdf' : ''}>
        <Button disabled={!data?.status} loading={!selectedLegalEntity} onClick={handleSave}>
          <PDFDownloadLink
            document={<PdfGeneratorDiscount legalEntity={selectedLegalEntity} products={values.lines} pdfData={values} />}
            fileName={`${values.quoteId ?? `AUTOGENERATED-${values.quoteName}`}.pdf`}>
            Download PDF With Discount
          </PDFDownloadLink>
        </Button>
      </Tooltip>
    </Fragment>
  );
};
