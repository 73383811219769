import { Col, Divider, Row, Space, Typography } from 'antd';
import { moneyFormatter } from 'common/helpers/CurrencyFormatter';
import { ImportProducts } from 'components/atoms/ImportProducts';
import { ProductHeaderActions } from 'components/molecules/ProductHeaderActions';
import QuotedProductsNew from 'components/molecules/QuotedProductsNew';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { QuotePayload } from 'models/Quotes';
import { useEffect } from 'react';

const styles: InlineStylesModel = {
  searchRow: {
    padding: 14,
    backgroundColor: '#b0b2bc',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  searchTitle: {
    marginBottom: 0
  }
};

export const Products = (): JSX.Element => {
  const {
    values: { totalCost, totalPrice, totalProfit, lines },
    setFieldValue
  } = useFormikContext<QuotePayload>();

  useEffect(() => {
    if (lines && lines.length > 0) {
      const priceTotal = lines.reduce((accumulator, quoteLineObject) => {
        return accumulator + Number(quoteLineObject.extendedPrice || 0);
      }, 0);
      const costTotal = lines.reduce((accumulator, quoteLineObject) => {
        return accumulator + Number(quoteLineObject.extendedCost || 0);
      }, 0);
      const profitTotal = lines.reduce((accumulator, quoteLineObject) => {
        return accumulator + Number(quoteLineObject.grossProfit || 0);
      }, 0);

      setFieldValue('totalCost', costTotal);
      setFieldValue('totalPrice', priceTotal);
      setFieldValue('totalProfit', profitTotal);
    } else {
      setFieldValue('totalCost', 0);
      setFieldValue('totalPrice', 0);
      setFieldValue('totalProfit', 0);
    }
  }, [lines, setFieldValue]);

  return (
    <>
      <Space direction="vertical" style={{ width: '100%', paddingBottom: 15 }}>
        <Space style={styles.searchRow}>
          <Typography.Title level={4} style={styles.searchTitle}>
            Products
          </Typography.Title>
          <Space align="center" size={12}>
            <Typography.Text>Quote Price: {moneyFormatter.format(totalPrice)}</Typography.Text>
            <Divider style={{ margin: '0 2px' }} type="vertical" />
            <Typography.Text>Cost: {moneyFormatter.format(totalCost)}</Typography.Text>
            <Divider style={{ margin: '0 2px' }} type="vertical" />
            <Typography.Text style={{ marginRight: 10 }}>Profit: {moneyFormatter.format(totalProfit)}</Typography.Text>
          </Space>
        </Space>
      </Space>
      <Row justify="space-between" style={{ marginBottom: 50 }}>
        <Col span={12}>
          <ProductHeaderActions />
        </Col>
        <Col>
          <Space align="center" size={12}>
            <ImportProducts />
          </Space>
        </Col>
        <Col span={24}>
          {/* <QuotedProducts /> */}
          <QuotedProductsNew />
        </Col>
      </Row>
    </>
  );
};
