import { Button, Modal } from 'antd';
import { Loader } from 'components/common/Loader';
import { useState } from 'react';

interface Props {
  sendQuoteEmail: any;
  closeModal: any;
  isModalOpen: boolean;
  sendAddress?: string;
}

export const SendEmailModal = ({ sendQuoteEmail, closeModal, isModalOpen }: Props): JSX.Element => {
  const [isModalSending, setIsModalSending] = useState(false);

  return (
    <Modal
      title={isModalSending ? 'Sending Quote...' : 'Send Quote?'}
      bodyStyle={{ paddingTop: 20 }}
      closable={!isModalSending}
      onCancel={closeModal}
      open={isModalOpen}
      confirmLoading={isModalSending}
      footer={[
        <Button
          disabled={isModalSending}
          onClick={async () => {
            setIsModalSending(true);
            await sendQuoteEmail('discount').then(() => setIsModalSending(false));
          }}
          key={1}>
          Send Quote w/ Discounts
        </Button>,
        <Button
          disabled={isModalSending}
          onClick={async () => {
            setIsModalSending(true);
            await sendQuoteEmail('regular').then(() => setIsModalSending(false));
          }}
          key={2}>
          Send Quote
        </Button>
      ]}>
      {isModalSending ? <Loader style={{ marginTop: 50 }} /> : `Are you sure you are ready to send this quote?`}
    </Modal>
  );
};
