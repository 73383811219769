import { Col, Row, Space, Typography } from 'antd';
import { HeaderInfo } from 'components/molecules/HeaderInfo';
import { SalesInfo } from 'components/molecules/SalesInfo';
import { InlineStylesModel } from 'models/InlineStylesModel';

const styles: InlineStylesModel = {
  headerTitleRow: {
    height: 60,
    padding: '0px 20px',
    backgroundColor: '#b0b2bc'
  },
  salesTitleRow: {
    height: 60,
    padding: '0px 20px',
    backgroundColor: '#FFEAC1'
  },
  contactTitleRow: {
    height: 60,
    padding: '0px 20px',
    backgroundColor: '#CED5F2'
  },
  title: {
    marginBottom: 0
  },
  mainContainer: {
    backgroundColor: 'white',
    padding: '30px',
    paddingBottom: '0px',
    height: '100%'
  }
};

export const HeaderInfoStep = (): JSX.Element => {
  return (
    <Row gutter={[10, 70]}>
      <Col span={12}>
        <Row style={styles.headerTitleRow}>
          <Space size="large">
            <Col>
              <Typography.Title level={4} style={styles.title}>
                Quote Info
              </Typography.Title>
            </Col>
          </Space>
        </Row>

        <Row style={styles.mainContainer}>
          <HeaderInfo />
        </Row>
      </Col>
      <Col span={12}>
        <Row style={styles.salesTitleRow}>
          <Space size="large">
            <Col>
              <Typography.Title level={4} style={styles.title}>
                Sales Info
              </Typography.Title>
            </Col>
          </Space>
        </Row>

        <Row style={styles.mainContainer}>
          <SalesInfo />
        </Row>
      </Col>
    </Row>
  );
};
