import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import lodash from 'lodash';
import { ApiMultipleResponse } from 'models/ApiModels';
import { PdfModel } from 'models/PdfModel';
import { ConvertedQuoteModal, ConvertQuotePayload, QuoteParams, QuotePayload, SalesQuoteModel } from 'models/Quotes';
import { ReduxState } from 'redux/store';

/* ******************** Base Query ******************** */
const baseUrl = process.env.REACT_APP_SAM_THE_ROBOT_BASE_URL || '';
const functionsKey = process.env.REACT_APP_API_HOST_KEY_SAM_THE_ROBOT || '';

interface Attachment {
  name: string;
  url: string;
  size: number;
  extension: string;
  createdByUserId: string;
  createdByUserFullName: string;
  createdDateTime: string;
}
interface CreateAttachmentPayload {
  file: FormData;
  quoteId: string;
}

const rawBaseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as ReduxState).app.accessToken;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
      headers.set('x-functions-key', functionsKey);
    }

    return headers;
  }
});

export const samTheRobotApi = createApi({
  reducerPath: 'samTheRobotApi',
  baseQuery: rawBaseQuery,
  tagTypes: ['Quotes', 'Quote'],
  endpoints: (builder) => ({
    getQuotes: builder.query<ApiMultipleResponse<SalesQuoteModel>, QuoteParams>({
      providesTags: ['Quotes'],
      query: (params) => ({
        url: 'productQuotes',
        params: ((): Partial<QuoteParams> => {
          const filteredObject = lodash.pickBy(params, (v) => v !== undefined);

          return filteredObject;
        })(),
        responseHandler: (response): Promise<ApiMultipleResponse<SalesQuoteModel> | string> => (response.status >= 300 && typeof response.status === 'string' ? response.text() : response.json())
      }),
      onQueryStarted: async (_arg, _api) => {
        try {
          await _api.queryFulfilled;
          const { originalArgs } = _api.getCacheEntry();
          const { queries } = _api.getState().samTheRobotApi;

          const lastQueryArgs = { ...originalArgs, offset: _arg.offset - 25 };
          const lastQueryData = Object.values(queries).find((value) => lodash.isEqual(value?.originalArgs, lastQueryArgs));

          if (lastQueryData) {
            _api.dispatch(
              samTheRobotApi.util.updateQueryData('getQuotes', _arg, (draft) => {
                draft.data.unshift(...(lastQueryData.data as any).data);
              })
            );
          }
        } catch (error) {
          console.log(error);
        }
      }
    }),
    getQuote: builder.query<SalesQuoteModel, string>({
      providesTags: ['Quote'],

      query: (quoteId) => ({
        url: `productQuotes/${quoteId}`,
        responseHandler: (response): Promise<ApiMultipleResponse<SalesQuoteModel> | string> => (response.status >= 300 && typeof response.status === 'string' ? response.text() : response.json())
      })
    }),
    recoverQuote: builder.mutation<SalesQuoteModel, string>({
      query: (quoteId) => ({
        url: `productQuotes/${quoteId}/recover`,
        responseHandler: (response): Promise<ApiMultipleResponse<SalesQuoteModel> | string> => (response.status >= 300 && typeof response.status === 'string' ? response.text() : response.json())
      }),
      invalidatesTags: ['Quotes']
    }),
    createQuote: builder.mutation<SalesQuoteModel, QuotePayload>({
      invalidatesTags: ['Quotes'],
      query: (data) => ({
        url: 'productQuotes',
        method: 'POST',
        body: data,
        responseHandler: (response): Promise<ApiMultipleResponse<PdfModel> | string> => (response.status >= 300 && typeof response.status === 'string' ? response.text() : response.json())
      })
    }),
    duplicateQuote: builder.mutation<SalesQuoteModel, string>({
      query: (productQuoteId) => ({
        url: `productQuotes/${productQuoteId}/duplicate`,
        method: 'POST',
        responseHandler: (response): Promise<ApiMultipleResponse<PdfModel> | string> => (response.status >= 300 && typeof response.status === 'string' ? response.text() : response.json())
      })
    }),
    sendQuote: builder.mutation<SalesQuoteModel, string>({
      invalidatesTags: ['Quotes', 'Quote'],
      query: (quoteId) => ({
        url: `productQuotes/${quoteId}/sendQuote`,
        method: 'POST',
        responseHandler: (response): Promise<ApiMultipleResponse<PdfModel> | string> => (response.status >= 300 && typeof response.status === 'string' ? response.text() : response.json())
      })
    }),
    wonQuote: builder.mutation<SalesQuoteModel, string>({
      invalidatesTags: ['Quotes', 'Quote'],
      query: (quoteId) => ({
        url: `productQuotes/${quoteId}/won`,
        method: 'POST',
        responseHandler: (response): Promise<ApiMultipleResponse<PdfModel> | string> => (response.status >= 300 && typeof response.status === 'string' ? response.text() : response.json())
      })
    }),
    lostQuote: builder.mutation<SalesQuoteModel, string>({
      invalidatesTags: ['Quotes', 'Quote'],
      query: (quoteId) => ({
        url: `productQuotes/${quoteId}/lost`,
        method: 'POST',
        responseHandler: (response): Promise<ApiMultipleResponse<PdfModel> | string> => (response.status >= 300 && typeof response.status === 'string' ? response.text() : response.json())
      })
    }),
    convertQuote: builder.mutation<ConvertedQuoteModal, ConvertQuotePayload>({
      invalidatesTags: ['Quotes', 'Quote'],
      query: (payload) => ({
        url: `productQuotes/${payload.quoteId}/convert`,
        method: 'POST',
        body: payload,
        responseHandler: (response): Promise<ApiMultipleResponse<PdfModel> | string> => (response.status >= 300 && typeof response.status === 'string' ? response.text() : response.json())
      })
    }),
    createPDF: builder.mutation<PdfModel, { formData: FormData; quoteId: string }>({
      query: (params) => ({
        url: `productQuotes/${params.quoteId}/pdfs`,
        method: 'POST',
        body: params.formData,
        responseHandler: (response): Promise<ApiMultipleResponse<PdfModel> | string> => (response.status >= 300 && typeof response.status === 'string' ? response.text() : response.json())
      })
    }),
    updateQuoteAfterDuplicate: builder.mutation<SalesQuoteModel, { quoteId: string; body: QuotePayload }>({
      query: ({ quoteId, body }) => ({
        url: `productQuotes/${quoteId}`,
        method: 'PUT',
        body,
        responseHandler: (response): Promise<ApiMultipleResponse<PdfModel> | string> => (response.status >= 300 && typeof response.status === 'string' ? response.text() : response.json())
      })
    }),
    updateQuote: builder.mutation<SalesQuoteModel, QuotePayload>({
      invalidatesTags: ['Quotes', 'Quote'],
      query: (data) => ({
        url: `productQuotes/${data.id}`,
        method: 'PUT',
        body: data,
        responseHandler: (response): Promise<ApiMultipleResponse<PdfModel> | string> => (response.status >= 300 && typeof response.status === 'string' ? response.text() : response.json())
      })
    }),
    deleteQuote: builder.mutation<void, string>({
      invalidatesTags: ['Quotes'],
      query: (quoteId) => ({
        url: `productQuotes/${quoteId}`,
        method: 'DELETE',
        responseHandler: (response): Promise<ApiMultipleResponse<SalesQuoteModel> | string> => (response.status >= 300 && typeof response.status === 'string' ? response.text() : response.json())
      })
    }),
    createAttachment: builder.mutation<Attachment, CreateAttachmentPayload>({
      query: ({ file, quoteId }) => ({ url: `productQuotes/${quoteId}/attachments`, method: 'POST', body: file })
    }),
    deleteAttachment: builder.mutation<void, { quoteId: string; fileName: string }>({
      query: ({ quoteId, fileName }) => ({
        url: `productQuotes/${quoteId}/attachments/${fileName}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Quote', 'Quotes']
    }),
    getVersion: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/version',
        method: 'GET',
        responseHandler: (response): Promise<string> => response.text()
      })
    }),
    getApiName: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/apiName',
        method: 'GET',
        responseHandler: (response): Promise<string> => response.text()
      })
    })
  })
});

export const {
  useGetQuotesQuery,
  useGetQuoteQuery,
  useCreateQuoteMutation,
  useConvertQuoteMutation,
  useWonQuoteMutation,
  useLostQuoteMutation,
  useUpdateQuoteMutation,
  useDeleteQuoteMutation,
  useGetVersionQuery,
  useGetApiNameQuery,
  useRecoverQuoteMutation,
  useCreatePDFMutation,
  useSendQuoteMutation,
  useDeleteAttachmentMutation,
  useCreateAttachmentMutation,
  useDuplicateQuoteMutation,
  useUpdateQuoteAfterDuplicateMutation
} = samTheRobotApi;
