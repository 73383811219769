import { Button, Col, Result, Row, Space, Typography } from 'antd';
import { Grid } from 'antd-mobile';
import { CountryStateInputs } from 'components/atoms/CountryStateInputs';
import { AddDeliveryAddressModal } from 'components/atoms/createDeliveryAddress/AddDeliveryAddressModal';
import { DeliveryAddressCityFilter } from 'components/atoms/deliveryAddressFilters/deliveryAddressCityFilter';
import { DeliveryAddressDescriptionFilter } from 'components/atoms/deliveryAddressFilters/deliveryAddressDescriptionFilter';
import { DeliveryAddressStateFilter } from 'components/atoms/deliveryAddressFilters/deliveryAddressStateFilter';
import { DeliveryAddressStreetFilter } from 'components/atoms/deliveryAddressFilters/deliveryAddressStreetFilter';
import { DeliveryAddressZipCodeFilter } from 'components/atoms/deliveryAddressFilters/deliveryAddressZipCodeFilter';
import WarningMessage from 'components/atoms/warningMessage/WarningMessage';
import { DeliveryAddressGrid } from 'components/molecules/DeliveryAddressGrid';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { QuotePayload } from 'models/Quotes';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const styles: InlineStylesModel = {
  searchRow: {
    padding: 14,
    backgroundColor: '#b0b2bc',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  searchTitle: {
    marginBottom: 0
  },
  resultsTitleRow: {
    margin: '10px 0px'
  },
  gridRow: {
    // height: '50vh',
    display: 'flex',
    flexDirection: 'row',
    flex: 1
  },
  addAddressButton: {
    width: '100%'
  }
};

export const DeliveryAddress = (): JSX.Element => {
  const { values } = useFormikContext<QuotePayload>();
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [showAddAddressModal, _setShowAddAddressModal] = useState(false);
  const isDisabled = !values.customerName || !values.customerAccount || Boolean(values.deliveryAddressLocationId);
  const isMobileScreen = Boolean(window.screen.width < 420);

  /* ****************** Functions ****************** */
  const isDraftForm = pathname.includes('/drafts/');
  const isOrderForm = pathname.includes('/orders/');
  const isView = pathname.includes('/view/');

  const handleGoBack = (): void => {
    if (isDraftForm) {
      navigate(`/drafts/${id}`);
    } else if (isOrderForm) {
      navigate(`/orders/${id}`);
    } else {
      navigate('/create');
    }
  };

  /* ****************** JSX ****************** */
  const addAddressButtonJSX = (
    <Button type="primary" style={styles.addAddressButton} disabled={isDisabled || isView} onClick={(): void => _setShowAddAddressModal(true)}>
      Add New Address
    </Button>
  );

  const deliveryAddressFiltersJSX = (
    <Space>
      <div>Search Filters</div>

      <DeliveryAddressStreetFilter isDisabled={isDisabled || isView} />
      <DeliveryAddressCityFilter isDisabled={isDisabled || isView} />
      <DeliveryAddressZipCodeFilter isDisabled={isDisabled || isView} />
      <CountryStateInputs />
      {addAddressButtonJSX}
    </Space>
  );
  const mobileDeliveryAddressFiltersJSX = (
    <Grid columns={3} gap={2}>
      <Grid.Item span={3} style={{ backgroundColor: 'white' }}>
        <DeliveryAddressDescriptionFilter isDisabled={isDisabled || isView} />
      </Grid.Item>
      <Grid.Item span={3} style={{ backgroundColor: 'white' }}>
        <DeliveryAddressStreetFilter isDisabled={isDisabled || isView} />
      </Grid.Item>
      <Grid.Item span={2} style={{ backgroundColor: 'white' }}>
        <DeliveryAddressCityFilter isDisabled={isDisabled || isView} />
      </Grid.Item>
      <Grid.Item span={1} style={{ backgroundColor: 'white' }}>
        <DeliveryAddressStateFilter isDisabled={isDisabled || isView} />
      </Grid.Item>
    </Grid>
  );

  const warningJSX = !values.customerAccount ? (
    <Col span={24} style={{ paddingTop: 50 }}>
      <Result
        icon={<WarningMessage />}
        status="warning"
        title="Please select a customer"
        extra={
          <Button type="ghost" key="console" onClick={handleGoBack}>
            Select a Customer
          </Button>
        }
      />
    </Col>
  ) : null;

  /* ****************** Render ****************** */
  return (
    <>
      <AddDeliveryAddressModal
        show={showAddAddressModal}
        onClose={(): void => {
          _setShowAddAddressModal(false);
        }}
      />
      <Space direction="vertical" style={{ width: '100%' }}>
        <Space style={styles.searchRow}>
          <Typography.Title level={4} style={styles.searchTitle}>
            Search Addresses
          </Typography.Title>
          {deliveryAddressFiltersJSX}
        </Space>

        <Row style={styles.gridRow} justify="center" align="middle">
          {warningJSX}
          {Boolean(values.customerAccount) && (
            <Col span={24}>
              <DeliveryAddressGrid />
            </Col>
          )}
        </Row>
      </Space>
    </>
  );
};
