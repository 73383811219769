import { Col, Row } from 'antd';
import { AlternateDisplayName } from 'components/atoms/AlternateDisplayName';
import { CustomerContactName } from 'components/atoms/CustomerContactName';
import { CustomerContactPhone } from 'components/atoms/CustomerContactPhone';
import { SalespersonInput } from 'components/atoms/SalespersonInput';
import { useFormikContext } from 'formik';
import { QuotePayload } from 'models/Quotes';
import { BuyerInput } from '../atoms/BuyerInput';

export const SalesInfo = (): JSX.Element => {
  const { values } = useFormikContext<QuotePayload>();

  /* ****************** Render ****************** */
  return (
    <Row style={{ width: '100%' }}>
      <Col span={24}>
        <Row gutter={[32, 0]}>
          <Col xs={24} sm={12} md={12}>
            <AlternateDisplayName />
          </Col>
          <Col xs={24} sm={12} md={12}>
            <BuyerInput />
          </Col>
          <Col xs={24} sm={12} md={12}>
            <SalespersonInput />
          </Col>
          <Col xs={24} sm={12} md={12}>
            <CustomerContactName />
          </Col>
          <Col xs={24} sm={12} md={12}>
            <CustomerContactPhone />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
