import { StyleSheet, Text, View } from '@react-pdf/renderer';

const borderColor = '#1f1e2e';
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: '#1f1e2e',
    backgroundColor: '#F4F4F4',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
    borderLeftColor: '1f1e2e',
    borderLeftWidth: 1,
    borderRightColor: '1f1e2e',
    borderRightWidth: 1,
    borderTopColor: '1f1e2e',
    borderTopWidth: 1
  },
  headerCol: {
    width: '11%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 5,
    paddingTop: 3
  },
  lineNumCol: {
    width: '6%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    margin: 0,
    paddingLeft: 5,
    textAlign: 'left',
    paddingTop: 3
  },

  biggerCol: {
    width: '18%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    margin: 0,
    paddingLeft: 5,
    textAlign: 'left',
    paddingTop: 3
  },
  descriptionCol: {
    width: '35%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    margin: 0,
    paddingLeft: 5,
    textAlign: 'left',
    paddingTop: 3
  },
  smallCol: {
    width: '8%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    margin: 0,
    paddingLeft: 5,
    textAlign: 'left',
    paddingTop: 3
  },
  conditionCol: {
    width: '11%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    margin: 0,
    paddingLeft: 5,
    textAlign: 'left',
    paddingTop: 3
  },
  lasCol: {
    width: '11%',
    textAlign: 'right',
    paddingRight: 5,
    paddingTop: 3
  }
});

const InvoiceTableHeader = (): JSX.Element => (
  <View style={styles.container}>
    <Text style={styles.lineNumCol}>Line #</Text>
    <Text style={styles.biggerCol}>Product Number</Text>
    <Text style={styles.descriptionCol}>Description</Text>
    <Text style={styles.conditionCol}>Condition</Text>
    <Text style={styles.smallCol}>Quantity</Text>
    <Text style={styles.headerCol}>Unit Price</Text>
    <Text style={styles.lasCol}>Total Price</Text>
  </View>
);

export default InvoiceTableHeader;
