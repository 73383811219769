import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Typography } from 'antd';
import useModal from 'antd/lib/modal/useModal';
import { ProductType } from 'common/helpers/dummyData';
import { ProductAdhocCard } from 'components/atoms/ProductAdhocCard';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setFilterCategory, setFilterManufacturer, setFilterProductName, setFilterProductNumber, setOffset, setResetFilters } from 'redux/productsInfo/productsInfoSlice';
import { donaldGrumpApi } from 'redux/services/donaldGrump';
import { ProductSearchTab } from './ProductSearchTab';
import { ProductsSearchFilters } from './ProductsSearchFilters';

export const ProductsSearchModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modal, contextHolder] = useModal();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isView = pathname.includes('/view');
  const [form] = Form.useForm<ProductType>();

  const handleModalClose = (): void => {
    setIsOpen(false);
    dispatch(setResetFilters(true));
    dispatch(setOffset(0));
    dispatch(donaldGrumpApi.util.invalidateTags(['ReleasedProducts']));
    dispatch(setFilterProductName(''));
    dispatch(setFilterProductNumber(''));
    dispatch(setFilterManufacturer(''));
    dispatch(setFilterCategory(''));
  };

  const handleAdhoc = (): void => {
    modal.confirm({
      icon: undefined,
      centered: true,
      bodyStyle: { paddingTop: 10 },
      title: (
        <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 25, padding: 10, marginBottom: 0 }}>
          Adhoc Product
        </Typography.Title>
      ),
      content: <ProductAdhocCard form={form} />,
      onOk: () => form.submit(),
      okText: 'Submit',
      width: 600,
      // cancelButtonProps: { style: { display: 'none' } },
      // okButtonProps: { style: { display: 'none' } },
      closable: true,
      keyboard: true
    });
  };

  const titleJsx = (
    <Row align="middle" style={{ width: '100%', padding: '0 20px' }} justify="space-between">
      <Row gutter={[16, 16]} align="middle">
        <Col>
          <Typography.Title style={{ margin: 0, padding: 0, fontWeight: 150 }} level={4}>
            Add Line Items
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <ProductsSearchFilters />
        </Col>
        <Col>
          <Button style={{ marginLeft: 8 }} onClick={handleAdhoc} type="primary">
            Adhoc Product
          </Button>
        </Col>
      </Row>
    </Row>
  );

  return (
    <>
      {contextHolder}
      <Row>
        <Col span={12}>
          <Button type="primary" disabled={isView} onClick={(): void => setIsOpen(true)} icon={<PlusCircleOutlined style={{ fontSize: 15 }} />}>
            Add Product
          </Button>
        </Col>
      </Row>

      <Modal
        keyboard
        onCancel={handleModalClose}
        // afterClose={handleCleanState}
        closable
        title={titleJsx}
        onOk={handleModalClose}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={1350}
        open={isOpen}>
        <ProductSearchTab />
      </Modal>
    </>
  );
};
