import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { CompanyModel } from 'models/CompanyModel';
import { QuotePayload } from 'models/Quotes';
import moment from 'moment';

interface Props {
  invoice: QuotePayload;
  legalEntity?: CompanyModel;
}

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    marginTop: 24,
    justifyContent: 'space-between'
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    fontFamily: 'Helvetica-Oblique'
  },
  alignRight: {
    display: 'flex',
    alignSelf: 'flex-end'
  },
  alignCenter: {
    display: 'flex',
    alignSelf: 'center'
  },
  label: {
    fontFamily: 'Helvetica-Bold'
  },
  label1: {
    fontFamily: 'Helvetica'
  }
});

const BillTo: React.FC<Props> = ({ invoice, legalEntity }: Props) => {
  const expirationDate = moment(invoice.quoteExpirationDate).format('MM-DD-YYYY');
  const creationDate = moment(invoice.createdDateTime).format('MM-DD-YYYY');

  return (
    <View style={styles.headerContainer}>
      <View style={{ width: '33%' }}>
        <Text style={styles.label}>
          Customer: <Text style={styles.label1}>{invoice.customerAccount || 'N/A'}</Text>
        </Text>
        <Text style={styles.label}>
          Quote Name: <Text style={styles.label1}>{invoice.quoteName || 'N/A'}</Text>
        </Text>
        <Text style={styles.label}>
          Quote ID: <Text style={styles.label1}>{invoice.quoteId || 'AUTOGENERATED'}</Text>
        </Text>
        <Text style={styles.label}>
          Prepared For: <Text style={styles.label1}>{invoice.customerContactName || 'N/A'}</Text>
        </Text>
        <Text style={styles.label}>
          Quote Date: <Text style={styles.label1}>{creationDate || 'AUTOGENERATED'}</Text>
        </Text>
        <Text style={styles.label}>
          Expiration Date: <Text style={styles.label1}>{expirationDate}</Text>
        </Text>
        <Text style={styles.label}>
          Sales Person: <Text style={styles.label1}>{invoice.salesPersonName || 'N/A'}</Text>
        </Text>
        <Text style={styles.label}>
          Prepared By: <Text style={styles.label1}>{invoice.createdByUserFullName || 'N/A'}</Text>
        </Text>
      </View>
      <View style={{ width: '33%' }}>
        {legalEntity?.name && <Text style={styles.alignCenter}>{legalEntity.name}</Text>}
        {legalEntity?.dataAreaId && <Text style={styles.alignCenter}>{legalEntity.dataAreaId}</Text>}
        {legalEntity?.address.street && <Text style={styles.alignCenter}>{legalEntity.address.street}</Text>}
        <Text style={styles.alignCenter}>
          {legalEntity?.address.city && legalEntity.address.state && legalEntity.address.zipCode ? `${legalEntity.address.city}, ${legalEntity.address.state} ${legalEntity.address.zipCode}` : ''}
        </Text>
        {legalEntity?.address.country && <Text style={styles.alignCenter}>{legalEntity?.address.country}</Text>}
      </View>
      <View style={{ width: '33%' }}>
        {invoice?.alternateCustomerDisplayName && <Text style={styles.alignRight}>{invoice.alternateCustomerDisplayName}</Text>}
        {invoice?.deliveryAddressStreet1 && <Text style={styles.alignRight}>{invoice.deliveryAddressStreet1}</Text>}
        <Text style={styles.alignRight}>
          {invoice.deliveryAddressCity && invoice.deliveryAddressSubdivision && invoice.deliveryAddressPostalCode
            ? `${invoice.deliveryAddressCity}, ${invoice.deliveryAddressSubdivision} ${invoice.deliveryAddressPostalCode}`
            : ''}
        </Text>
        {invoice?.deliveryAddressCountry && <Text style={styles.alignRight}>{invoice.deliveryAddressCountry}</Text>}
      </View>
    </View>
  );
};

export default BillTo;
