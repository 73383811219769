import { Button, Modal } from 'antd';
import { Loader } from 'components/common/Loader';
import { useState } from 'react';

interface Props {
  loseQuote: any;
  closeModal: any;
  isModalOpen: boolean;
}

export const LostModal = ({ loseQuote, closeModal, isModalOpen }: Props): JSX.Element => {
  const [isModalSending, setIsModalSending] = useState(false);

  return (
    <Modal
      title={isModalSending ? 'Losing Quote...' : 'Lose Quote?'}
      bodyStyle={{ paddingTop: 20 }}
      closable={!isModalSending}
      onCancel={closeModal}
      open={isModalOpen}
      confirmLoading={isModalSending}
      footer={[
        <Button
          disabled={isModalSending}
          type="primary"
          className="quote-card__menu-item-delete"
          onClick={async () => {
            setIsModalSending(true);
            await loseQuote().then(() => setIsModalSending(false));
          }}
          key={1}>
          Lose This Quote
        </Button>
      ]}>
      {isModalSending ? <Loader style={{ marginTop: 50 }} /> : `Are you sure you are ready to lose this Quote? It will change to read only mode.`}
    </Modal>
  );
};
