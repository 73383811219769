import { Form, Input, InputNumber, Popover, Typography } from 'antd';
import { ProductType } from 'common/helpers/dummyData';
import { useFormikContext } from 'formik';
import { QuotePayload } from 'models/Quotes';
import { FocusEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateProduct } from 'redux/slices/selectedProductsSlice';
import { useDebouncedCallback } from 'use-debounce';
import { VendorCell } from './VendorCell';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'number' | 'text';
  record: ProductType;
  index: number;
  children: React.ReactNode;
}

export const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, inputType, children, record, ...restProps }: EditableCellProps) => {
  const form = Form.useFormInstance();
  const [errMessage, setErrorMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const { values } = useFormikContext<QuotePayload>();
  const idx = values.lines.findIndex((line) => `${line?.productNumber}-${line?.condition ?? 'NEW'}` === `${record?.productNumber}-${record?.condition ?? 'NEW'}`);

  const handleDebounce = useDebouncedCallback((product) => {
    dispatch(updateProduct(product));
  }, 500);

  const handleInputChange = (): void => {
    if (dataIndex === 'listPrice' || dataIndex === 'listDiscountPercentage') {
      form.setFieldValue('unitPrice', (form.getFieldValue('listPrice') - form.getFieldValue('listPrice') * (form.getFieldValue('listDiscountPercentage') / 100)).toFixed(2));
      form.setFieldValue('listPrice', form.getFieldValue('listPrice').toFixed(2));
    }

    if (dataIndex === 'unitPrice') {
      form.setFieldsValue({ listPrice: 0, listDiscountPercentage: 0 });
    }

    const { quantity, unitCost, unitPrice } = form.getFieldsValue();

    // Update all meta data in real time
    const newExtendedCost = (quantity || 1) * (unitCost || 0);
    const newExtendedPrice = unitPrice * (quantity || 1);
    const newGrossMargin = ((unitPrice - (unitCost || 0)) / unitPrice) * 100;
    const newGrossProfit = (unitPrice - (unitCost || 0)) * (quantity || 1);

    handleDebounce({ ...record, extendedCost: newExtendedCost, extendedPrice: newExtendedPrice, unitPrice, grossMargin: newGrossMargin, grossProfit: newGrossProfit });

    if (form.getFieldValue(dataIndex) === '' || form.getFieldValue(dataIndex) === null) {
      setErrorMessage(`${dataIndex} is required`);
      setIsOpen(true);
    } else if (form.getFieldValue(dataIndex) < 0 && dataIndex !== 'quantity') {
      setErrorMessage(`${dataIndex} cant be negative`);
      setIsOpen(true);
    } else setIsOpen(false);
  };

  const handleFocus = (ev: FocusEvent<HTMLInputElement>): void => {
    ev.currentTarget.select();
  };

  const handleProductChange = (): void => {
    if (form.getFieldValue('productNumber').length > 30) {
      setErrorMessage('Product number cannot be longer than 30 characters');
      setIsOpen(true);
    } else if (!form.getFieldValue('productNumber')) {
      setErrorMessage('Product number cannot be empty');
      setIsOpen(true);
    } else setIsOpen(false);
  };

  const handleParser = (value: string | undefined): string => value?.replace(/\$\s?|(,*)/g, '') ?? '';
  const handleFormatter = (value: string | undefined): string => (!['quantity', 'listDiscountPercentage'].includes(dataIndex) ? new Intl.NumberFormat('en-US').format(Number(value) ?? 0) : `${value}`);
  const handleStep = (): string | undefined => (dataIndex === 'quanitiy' ? '0.00' : dataIndex === 'listDiscountPercentage' ? '0' : '0.01');
  const handleMin = (): string | undefined => (dataIndex !== 'quantity' ? '0' : undefined);
  const handleMax = (): string | undefined => (dataIndex === 'listDiscountPercentage' ? '100' : undefined);

  const inputNode =
    dataIndex === 'productNumber' || dataIndex === 'productDescription' ? (
      <Input onChange={handleProductChange} />
    ) : dataIndex === 'manufacturer' || dataIndex === 'category' || dataIndex === 'alternateItemId' ? (
      <Input />
    ) : dataIndex === 'vendorId' ? (
      <VendorCell index={idx} />
    ) : dataIndex === 'quantity' ? (
      <InputNumber
        controls={false}
        style={{ width: '100%', margin: 0 }}
        // min={1}
        onKeyDown={(e): void => {
          if (e.key === '.') e.preventDefault();
        }}
      />
    ) : inputType === 'number' ? (
      <InputNumber
        addonBefore={!['quantity', 'listDiscountPercentage'].includes(dataIndex) ? '$' : undefined}
        addonAfter={dataIndex === 'listDiscountPercentage' ? '%' : undefined}
        onChange={handleInputChange}
        style={{ width: '100%', margin: 0 }}
        controls={false}
        onFocus={handleFocus}
        formatter={handleFormatter}
        parser={handleParser}
        step={handleStep()}
        min={handleMin()}
        max={handleMax()}
      />
    ) : (
      <Input />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Popover open={isOpen} content={<Typography.Text style={{ color: 'red', margin: 10 }}>{errMessage}</Typography.Text>}>
          <Form.Item name={dataIndex} style={{ margin: 0 }}>
            {inputNode}
          </Form.Item>
        </Popover>
      ) : (
        children
      )}
    </td>
  );
};
