import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Col, Divider, List, Row, Spin } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import NoResults from 'components/atoms/noResults/noResults';
import RecoverSalesOrderCard from 'components/atoms/RecoverSalesOrderCard';
import RecoverActions from 'components/molecules/RecoverActions';
import { SalesQuoteFilters } from 'components/molecules/SalesQuoteFilters';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useSelector } from 'react-redux';
import { useGetQuotesQuery } from 'redux/services/samTheRobot/samTheRobotApi';
import { ReduxState } from 'redux/store';

const styles: InlineStylesModel = {
  divider: {
    margin: '9px 0px'
  }
};

/* ****************** interfaces ****************** */

export const Recover = (): JSX.Element => {
  const { samTheRobotQuoteParams } = useSelector((state: ReduxState) => state);

  /* ****************** API/Hooks ****************** */

  const { data: responseDeletedSalesOrders, isLoading, isFetching, isError, error } = useGetQuotesQuery({ ...samTheRobotQuoteParams, returnDeletedDataOnly: true } ?? skipToken);

  return (
    <Spin spinning={isLoading || isFetching} indicator={<BlockLoader direction="loader loader--slideUp" />}>
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <SalesQuoteFilters />
          <RecoverActions responseDeletedSalesOrders={responseDeletedSalesOrders?.data} />
        </Col>
        <Divider style={styles.divider} />
        {responseDeletedSalesOrders?.data.length !== 0 ? (
          <Col span={24}>
            <List
              grid={{ gutter: 8, xs: 1, sm: 2, md: 2, lg: 2, xl: 3, xxl: 4 }}
              dataSource={responseDeletedSalesOrders?.data}
              renderItem={(item): JSX.Element => <RecoverSalesOrderCard salesQuote={item} />}
              rowKey={(item): string => item.id}
              style={{ overflow: 'hidden' }}
            />
          </Col>
        ) : (
          <NoResults errorMessage="No Results Were Found..." />
        )}
      </Row>
    </Spin>
  );
};
