import { pdf } from '@react-pdf/renderer';
import { Button, Menu, message, Modal } from 'antd';
import { colors, toRgb } from 'common/styles/colors';
import { LostModal } from 'components/atoms/popups/LostModal';
import { WonModal } from 'components/atoms/popups/WonModal';
import { Loader } from 'components/common/Loader';
import { QuotePayload, SalesQuoteModel } from 'models/Quotes';
import { useState } from 'react';
import Lottie from 'react-lottie-player';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCreatePDFMutation, useLostQuoteMutation, useSendQuoteMutation, useWonQuoteMutation } from 'redux/services/samTheRobot/samTheRobotApi';
import { useAppSelector } from 'redux/store';
import deleteLottie from '../../common/assets/delete-lottie.json';
import duplicateLottie from '../../common/assets/dupe-lottie.json';
import editLottie from '../../common/assets/edit-lottie.json';
import giftLottie from '../../common/assets/gift-lottie.json';
import sendLottie from '../../common/assets/send-to-customer-lottie.json';
import { DuplicateModalFromHome } from './DuplicateModalFromHome';
import { PdfGenerator } from './PdfGenerator';
import { PdfGeneratorDiscount } from './PdfGeneratorDiscount';

interface Props {
  salesQuote: SalesQuoteModel;
  closePopover: any;
}

export const IconMenu: React.FC<Props> = ({ salesQuote, closePopover }: Props) => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [isWonOpen, setIsWonOpen] = useState(false);
  const [isLostOpen, setIsLostOpen] = useState(false);
  const [isEmailPdfOpen, setIsEmailPdfOpen] = useState(false);
  const [isModalSending, setIsModalSending] = useState(false);
  const [isDuplicateModalOpen, setIsDuplicateOpen] = useState(false);

  const [createPdf] = useCreatePDFMutation();
  const [sendQuote] = useSendQuoteMutation();
  const [lostQuote] = useLostQuoteMutation();
  const [wonQuote] = useWonQuoteMutation();

  const { selectedLegalEntity } = useAppSelector((state) => state.app);

  const isWon = Boolean(salesQuote.status === 'Won');
  const isLost = Boolean(salesQuote.status === 'Lost');

  const PdfValues: QuotePayload = {
    id: salesQuote.id ? salesQuote.id : '',
    buyerId: salesQuote ? salesQuote.buyerId : '',
    buyerName: salesQuote ? salesQuote.buyerName : '',
    customerErpId: salesQuote ? salesQuote.customerErpId || '' : '',
    customerAccount: salesQuote ? salesQuote.customerErpId || '' : '',
    customerName: salesQuote ? salesQuote.customerName || '' : '',
    customerDataAreaId: salesQuote ? salesQuote.dataAreaId || '' : '',
    customerContactName: salesQuote ? salesQuote.customerContactName || '' : '',
    customerContactPhone: salesQuote ? salesQuote.customerContactPhone || '' : '',
    customerEmailAddress: salesQuote ? salesQuote.customerEmailAddress : '',
    customerEmailAddresses: [],
    createdByUserFullName: salesQuote.createdByUserFullName ?? '',
    dataAreaId: salesQuote ? salesQuote.dataAreaId : '',
    isActive: salesQuote ? salesQuote.isActive : true,
    lines: salesQuote ? salesQuote.lines : [],
    notes: salesQuote ? salesQuote.notes : '',
    quoteName: salesQuote ? salesQuote.quoteName : '',
    quoteExpiresInDays: salesQuote ? salesQuote.quoteExpiresInDays : 45,
    quoteExpirationDate: salesQuote ? salesQuote.quoteExpirationDate : '',
    quoteId: salesQuote ? salesQuote.quoteId : '',
    totalCost: salesQuote ? salesQuote.totalCost : 0,
    totalPrice: salesQuote ? salesQuote.totalPrice : 0,
    totalProfit: salesQuote ? salesQuote.totalProfit : 0,
    totalMargin: salesQuote ? salesQuote.totalMargin : 0,
    salesPersonName: salesQuote ? salesQuote.salesPersonName : '',
    salesPersonId: salesQuote ? salesQuote?.salesPersonId : '',
    deliveryAddressStreet1: salesQuote ? salesQuote?.deliveryAddress?.street1 : '',
    deliveryAddressCity: salesQuote ? salesQuote?.deliveryAddress?.city : '',
    deliveryAddressCountry: salesQuote ? salesQuote?.deliveryAddress?.country : '',
    deliveryAddressCounty: salesQuote ? salesQuote?.deliveryAddress?.country : '',
    deliveryAddressSubdivision: salesQuote ? salesQuote?.deliveryAddress?.subdivision : '',
    deliveryAddressPostalCode: salesQuote ? salesQuote?.deliveryAddress?.postalCode : '',
    deliveryAddressName: salesQuote ? salesQuote?.deliveryAddress?.name : '',
    deliveryAddressLocationId: salesQuote ? salesQuote?.deliveryAddress?.locationId : '',
    createdDateTime: salesQuote ? salesQuote?.createdDateTime : '',
    newQuote: false,
    closeWizard: true
  };

  const PdfProducts = salesQuote.lines.map((eachProduct) => {
    return { ...eachProduct, children: eachProduct.subLines };
  });

  const handleDetailClick = (detailId: string, detailStatus: string): void => {
    if (detailStatus === 'Won' || detailStatus === 'Lost') {
      nav(`/view/${detailId}`);
    } else {
      nav(`/edit/${detailId}`);
    }
  };

  const handleDuplicate = (): void => {
    setIsDuplicateOpen(true);
  };

  const handleSendButton = (): void => {
    if (!salesQuote.customerEmailAddresses) message.error('There are no customer emails to send the quote to');
    else {
      closePopover();
      setIsEmailPdfOpen(true);
    }
  };

  const handleSendQuote = async (pdfVersion: string): Promise<void> => {
    const pdfBlob = await pdf(
      pdfVersion === 'discount' ? (
        <PdfGeneratorDiscount legalEntity={selectedLegalEntity} products={PdfProducts} pdfData={PdfValues} />
      ) : (
        <PdfGenerator legalEntity={selectedLegalEntity} products={PdfProducts} pdfData={PdfValues} />
      )
    ).toBlob();
    const formData = new FormData();

    if (!pdfBlob) throw new Error('No PDF file found');
    formData.append('file', pdfBlob, 'quoteIdTest');

    try {
      await createPdf({ quoteId: salesQuote.id, formData }).unwrap();
      await sendQuote(salesQuote.id).unwrap();
      message.success('Succesfully Sent Quote!');
    } catch (err: any) {
      console.log(err);
      if (err.originalStatus === 204) {
        message.success('Succesfully Sent Quote! (w 204 resp)');
      }
    }
    setIsModalSending(false);
  };

  const handleWon = async (): Promise<void> => {
    const pdfBlob = await pdf(<PdfGenerator legalEntity={selectedLegalEntity} products={PdfProducts} pdfData={PdfValues} />).toBlob();
    const formData = new FormData();

    if (!pdfBlob) throw new Error('No PDF file found');
    formData.append('file', pdfBlob, 'quoteIdTest');

    try {
      const pdfResponse = await createPdf({ quoteId: salesQuote.id, formData }).unwrap();
      const wonQuoteResponse = await wonQuote(salesQuote.id).unwrap();

      if (pdfResponse && wonQuoteResponse) message.success('Quote succesfully Won!');
    } catch (err) {
      console.log(err);
      message.error((err as { data: { errorMessage: string } }).data.errorMessage);
    }
  };

  const handleLost = async (): Promise<void> => {
    try {
      const lostQuoteResponse = await lostQuote(salesQuote.id).unwrap();

      if (lostQuoteResponse) message.success('Quote succesfully Lost!');
    } catch (err) {
      console.log(err);
      message.error((err as { data: { errorMessage: string } }).data.errorMessage);
    }
  };
  const [hover, setHover] = useState<string | null>();

  return (
    <>
      <DuplicateModalFromHome values={PdfValues} isDuplicateModalOpen={isDuplicateModalOpen} setIsDuplicateOpen={setIsDuplicateOpen} />
      <Menu mode="vertical" style={{ border: 'none', background: 'transparent' }} className="quote-card">
        <Menu.Item
          onMouseEnter={(): void => setHover('edit')}
          onMouseLeave={(): void => setHover(null)}
          onClick={(): void => handleDetailClick(salesQuote.id, salesQuote.status)}
          icon={<Lottie animationData={editLottie} loop={false} goTo={!hover ? 0 : undefined} play={hover === 'edit'} style={{ width: 25, height: 25, display: 'inline-block' }} />}
          className="quote-card__menu-item quote-card__menu-item-edit"
          style={{ color: toRgb(colors.orangeWeb), display: 'flex', alignItems: 'center' }}>
          {isWon || isLost ? 'View' : 'Edit'}
        </Menu.Item>
        <Menu.Item
          onMouseEnter={(): void => setHover('duplicate')}
          onMouseLeave={(): void => setHover(null)}
          onClick={handleDuplicate}
          icon={<Lottie animationData={duplicateLottie} loop={false} goTo={!hover ? 0 : undefined} play={hover === 'duplicate'} style={{ width: 25, height: 25, display: 'inline-block' }} />}
          className="quote-card__menu-item"
          style={{ color: toRgb(colors.raisinBlackLight), display: 'flex', alignItems: 'center' }}>
          Duplicate
        </Menu.Item>
        {salesQuote.status === 'Draft' ? (
          <Menu.Item
            onMouseEnter={(): void => setHover('sendToCustomer')}
            onMouseLeave={(): void => setHover(null)}
            onClick={handleSendButton}
            icon={
              <Lottie
                animationData={sendLottie}
                loop={false}
                goTo={!hover ? 0 : undefined}
                play={hover === 'sendToCustomer'}
                style={{ width: 25, height: 25, display: 'inline-block', transform: 'scale(0.9)' }}
              />
            }
            className="quote-card__menu-item quote-card__menu-item-send"
            style={{ color: toRgb(colors.robinEggBlue), display: 'flex', alignItems: 'center' }}>
            Send To Customer
          </Menu.Item>
        ) : salesQuote.status === 'SentToCustomer' ? (
          <Menu.Item
            onMouseEnter={(): void => setHover('sendToCustomer')}
            onMouseLeave={(): void => setHover(null)}
            onClick={handleSendButton}
            icon={<Lottie animationData={sendLottie} loop={false} goTo={!hover ? 0 : undefined} play={hover === 'sendToCustomer'} style={{ width: 25, height: 25, display: 'inline-block' }} />}
            className="quote-card__menu-item quote-card__menu-item-send"
            style={{ color: toRgb(colors.robinEggBlue), display: 'flex', alignItems: 'center' }}>
            Resend
          </Menu.Item>
        ) : null}
        {!isWon && !isLost ? (
          <>
            <Menu.Item
              onMouseEnter={(): void => setHover('award')}
              onMouseLeave={(): void => setHover(null)}
              onClick={(): void => {
                closePopover();
                setIsWonOpen(true);
              }}
              icon={<Lottie animationData={giftLottie} loop={false} goTo={!hover ? 0 : undefined} play={hover === 'award'} style={{ width: 25, height: 25, display: 'inline-block' }} />}
              className="quote-card__menu-item quote-card__menu-item-award"
              style={{ color: toRgb(colors.illuminatingEmerald), display: 'flex', alignItems: 'center' }}>
              Won
            </Menu.Item>
            <Menu.Item
              onMouseEnter={(): void => setHover('delete')}
              onMouseLeave={(): void => setHover(null)}
              style={{ display: 'flex', alignItems: 'center' }}
              danger
              onClick={(): void => {
                closePopover();
                setIsLostOpen(true);
              }}
              icon={
                <Lottie
                  animationData={deleteLottie}
                  loop={false}
                  goTo={!hover ? 0 : undefined}
                  play={hover === 'delete'}
                  style={{ width: 25, height: 25, display: 'inline-block', transform: 'scale(1.25)' }}
                />
              }
              className="quote-card__menu-item quote-card__menu-item-delete">
              Lost
            </Menu.Item>
          </>
        ) : null}
        {/**** *** *** *** *** ACTION POPUPS *** *** *** *** ****/}
        <Modal
          title={isModalSending ? 'Sending Quote...' : 'Send Quote?'}
          bodyStyle={{ paddingTop: 20 }}
          closable={!isModalSending}
          onCancel={() => setIsEmailPdfOpen(false)}
          open={isEmailPdfOpen}
          confirmLoading={isModalSending}
          footer={[
            <Button
              disabled={isModalSending}
              onClick={() => {
                setIsModalSending(true);
                handleSendQuote('discount');
              }}
              key={1}>
              Send Quote w/ Discounts
            </Button>,
            <Button
              disabled={isModalSending}
              onClick={() => {
                setIsModalSending(true);
                handleSendQuote('regular');
              }}
              key={2}>
              Send Quote
            </Button>
          ]}>
          {isModalSending ? <Loader style={{ marginTop: 50 }} /> : `Are you sure you are ready to send this quote?`}
        </Modal>
        <WonModal awardQuote={handleWon} closeModal={(): void => setIsWonOpen(false)} isModalOpen={isWonOpen} />
        <LostModal loseQuote={handleLost} closeModal={(): void => setIsLostOpen(false)} isModalOpen={isLostOpen} />
      </Menu>
    </>
  );
};
