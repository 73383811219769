import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Form, message, Modal, Spin } from 'antd';
import { QuoteBreadcrumb } from 'components/atoms/QuoteBreadcrumb';
import { ActionControls } from 'components/common/ActionControls';
import { Loader } from 'components/common/Loader';
import { StepControls } from 'components/common/StepControls';
import { FormikProvider, useFormik } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { QuotePayload } from 'models/Quotes';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useGetQuoteQuery, useUpdateQuoteMutation } from 'redux/services/samTheRobot/samTheRobotApi';
import { setSelectedProducts } from 'redux/slices/selectedProductsSlice';
import { useAppSelector } from 'redux/store';

const styles: InlineStylesModel = {
  title: {
    marginTop: 500,
    marginBottom: 0
  }
};

export const ViewLayout = (): JSX.Element => {
  const { id } = useParams();
  const nav = useNavigate();
  const dispatch = useDispatch();

  const { data, isLoading, isFetching } = useGetQuoteQuery(id ?? skipToken);
  const { selectedCompanyDataAreaId } = useAppSelector((state) => state.orders);

  const [updateQuote, { isLoading: quoteSubmitting }] = useUpdateQuoteMutation();

  const formik = useFormik<QuotePayload>({
    initialValues: {
      attachments: [],
      id: id,
      buyerId: data ? data.buyerId : '',
      buyerName: data ? data.buyerName : '',
      customerErpId: data ? data.customerErpId || '' : '',
      customerAccount: data ? data.customerErpId || '' : '',
      customerName: data ? data.customerName || '' : '',
      customerDataAreaId: data ? data.dataAreaId || '' : '',
      customerContactName: data ? data.customerContactName || '' : '',
      customerContactPhone: data ? data.customerContactPhone || '' : '',
      customerEmailAddress: data ? data.customerEmailAddress : '',
      customerEmailAddresses: data ? data.customerEmailAddresses : [],
      createdByUserFullName: data?.createdByUserFullName ?? '',
      dataAreaId: data ? data.dataAreaId : '',
      isActive: data ? data.isActive : true,
      lines: data ? data.lines : [],
      notes: data ? data.notes : '',
      quoteName: data ? data.quoteName : '',
      quoteExpiresInDays: data ? data.quoteExpiresInDays : 45,
      quoteExpirationDate: data ? data.quoteExpirationDate : '',
      quoteId: data ? data.quoteId : '',
      totalCost: data ? data.totalCost : 0,
      totalPrice: data ? data.totalPrice : 0,
      totalProfit: data ? data.totalProfit : 0,
      totalMargin: data ? data.totalMargin : 0,
      salesPersonName: data ? data.salesPersonName : '',
      salesPersonId: data ? data?.salesPersonId : '',
      deliveryAddressStreet1: data ? data?.deliveryAddress?.street1 : '',
      deliveryAddressCity: data ? data?.deliveryAddress?.city : '',
      deliveryAddressCountry: data ? data?.deliveryAddress?.country : '',
      deliveryAddressCounty: data ? data?.deliveryAddress?.country : '',
      deliveryAddressSubdivision: data ? data?.deliveryAddress?.subdivision : '',
      deliveryAddressPostalCode: data ? data?.deliveryAddress?.postalCode : '',
      deliveryAddressName: data ? data?.deliveryAddress?.name : '',
      deliveryAddressLocationId: data ? data?.deliveryAddress?.locationId : '',
      createdDateTime: data ? data?.createdDateTime : '',
      newQuote: false,
      closeWizard: true
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!selectedCompanyDataAreaId) return message.error('Legal entity not selected, please refresh the page and try again');

      const totalPrice = values.lines.reduce((accumulator, quoteLineObject) => {
        return accumulator + Number(quoteLineObject.extendedPrice || 0);
      }, 0);

      try {
        const data = await updateQuote({
          ...values,
          dataAreaId: selectedCompanyDataAreaId,
          customerErpId: values.customerAccount,
          lines: values.lines,
          totalPrice
        }).unwrap();

        Modal.success({
          afterClose: () => nav(values.closeWizard ? `/search` : `/edit/${data.id}`),
          title: 'Quote successfully updated',
          content: `Quote Number: ${data.quoteId}`
        });
      } catch (error) {
        console.log(error);
        message.error((error as { data: { errorMessage: string } }).data.errorMessage ?? 'An error occurred while submitting your product quote.');
      }
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setSelectedProducts([]));
    };
  }, [dispatch]);

  if (isLoading || isFetching) return <Loader />;

  return (
    <FormikProvider value={formik}>
      <Spin spinning={quoteSubmitting} indicator={<Loader message="Submitting Product Quote" />}>
        <Form style={styles.container} layout="vertical">
          <ActionControls />
          <QuoteBreadcrumb />
          <StepControls />
          <Outlet />
        </Form>
      </Spin>
    </FormikProvider>
  );
};
