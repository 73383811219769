import { CheckOutlined, DeleteOutlined, DownOutlined, EditOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Table, Tooltip, message } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { moneyFormatter } from 'common/helpers/CurrencyFormatter';
import { ProductType } from 'common/helpers/dummyData';
import { EditableCell } from 'components/atoms/EditableCell';
import { ProductNumberCell } from 'components/atoms/ProductNumberCell';
import { useField, useFormikContext } from 'formik';
import { SalesQuoteModel } from 'models/Quotes';
import { FixedType } from 'rc-table/lib/interface';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AddChildModal } from './AddChildModal';
import { SublineTable } from './SublineTable';

interface EditableColumnType extends ColumnType<ProductType> {
  editable?: boolean;
  fixed?: FixedType;
}

const QuotedProductsNew: React.FC = () => {
  const [form] = Form.useForm();
  const { pathname } = useLocation();
  const isView = pathname.includes('/view');

  const [showOptions, setShowOptions] = useState<string>('');
  const [editingKey, setEditingKey] = useState('');
  const [idx, setIdx] = useState<number | undefined>(undefined);

  const {
    values: { lines },
    setFieldValue
  } = useFormikContext<SalesQuoteModel>();

  const [a, { value }, { setValue }] = useField<ProductType>(`lines[${idx}]`);

  // eslint-disable-next-line prefer-const

  const isEditing = (record: ProductType): boolean => lines.findIndex((line) => line === record).toString() === editingKey;

  const save = (): void => {
    const row = form.getFieldsValue() as ProductType;

    if (row.unitPrice === undefined || row.unitCost === undefined || row.quantity === undefined) {
      message.error('Unable to update product line item. Try again.');

      return;
    }

    // const data = await trigger({ productNumber: row.productNumber as string, dataAreaId: selectedCompanyDataAreaId as string, offset: 0 }).unwrap();

    const newExtendedCost = (row.quantity || 1) * (row.unitCost || 0);
    const newExtendedPrice = row.unitPrice * (row.quantity || 1);
    const newGrossMargin = ((row.unitPrice - (row.unitCost || 0)) / row.unitPrice) * 100;
    const newGrossProfit = (row.unitPrice - (row.unitCost || 0)) * (row?.quantity || 1);

    setValue({ ...value, ...row, extendedCost: newExtendedCost, extendedPrice: newExtendedPrice, grossMargin: newGrossMargin, grossProfit: newGrossProfit });

    setEditingKey('');
  };

  const handleCancel = (): void => {
    setEditingKey('');
  };

  const edit = (record: Partial<ProductType>): void => {
    setEditingKey(lines.findIndex((line) => line === record).toString());

    lines.findIndex((line) => line === record);

    form.setFieldsValue({
      lineNumber: record.lineNumber,
      alternateItemId: record.alternateItemId,
      condition: record.condition,
      quantity: record.quantity,
      listPrice: record.listPrice,
      listDiscountPercentage: record.listDiscountPercentage,
      unitPrice: record.unitPrice,
      unitCost: record.unitCost,
      productNumber: record.productNumber,
      productDescription: record.productDescription,
      manufacturer: record.manufacturer,
      category: record.category
    });

    const formProductNumber = form.getFieldValue('productNumber');
    const formCondition = form.getFieldValue('condition');
    const formLineNumber = form.getFieldValue('lineNumber');
  };

  const handleDelete = (key: string): void => {
    setFieldValue(
      'lines',
      lines.filter((record) => lines.findIndex((line) => line === record).toString() !== key)
    );
    setEditingKey('');
  };

  const columns: EditableColumnType[] = [
    {
      title: '',
      width: 20,
      render: (_: string, record: ProductType): JSX.Element => <Row justify="center">{<AddChildModal record={record} isLineLevel={true} showOptions={showOptions} />}</Row>
    },
    {
      title: 'Line',
      width: 45,
      render: (_, record, index): JSX.Element => {
        return <div>{`${index + 1}`}</div>;
      }
    },
    {
      title: 'Product Number',
      dataIndex: 'productNumber',
      key: 'productNumber',
      width: 175,
      editable: true,
      ellipsis: true,
      render: (_: string, record: ProductType, idx: number): JSX.Element => <ProductNumberCell idx={idx} record={record} />
    },
    {
      title: 'Alt Item Id',
      dataIndex: 'alternateItemId',
      key: 'alternateItemId',
      width: 125,
      editable: true,
      ellipsis: true
    },
    {
      title: 'Description',
      dataIndex: 'productDescription',
      key: 'productDescription',
      ellipsis: true,
      editable: true,
      width: 250,
      render: (_: string, record: ProductType): JSX.Element => <Tooltip title={record.productDescription}>{record.productDescription}</Tooltip>
    },
    { title: 'Manufacturer', dataIndex: 'manufacturer', key: 'manufacturer', width: 100, editable: true },
    { title: 'Category', dataIndex: 'category', key: 'category', width: 100, editable: true },
    { title: 'Condition', dataIndex: 'condition', key: 'condition', width: 100, editable: true },
    { title: 'Vendor', dataIndex: 'vendorId', key: 'vendorId', width: 100, editable: true },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
      editable: true,
      width: 65
    },
    {
      title: 'List Price',
      dataIndex: 'listPrice',
      key: 'listPrice',
      editable: true,
      width: 125,
      align: 'right',
      render: (_: string, record: ProductType): JSX.Element => <>{moneyFormatter.format(Number(record.listPrice))}</>
    },
    {
      title: 'Discount',
      dataIndex: 'listDiscountPercentage',
      key: 'listDiscountPercentage',
      editable: true,
      align: 'right',
      width: 80,
      render: (_: string, record: ProductType): JSX.Element => <>{record.listDiscountPercentage}%</>
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      editable: true,
      width: 125,
      align: 'right',
      render: (_: string, record: ProductType): JSX.Element => (
        <>{record.unitPrice ? moneyFormatter.format(record.unitPrice) : moneyFormatter.format(Number(record.listPrice) - (Number(record.listPrice) * Number(record.listDiscountPercentage)) / 100)}</>
      )
    },
    {
      title: 'Unit Cost',
      dataIndex: 'unitCost',
      key: 'unitCost',
      editable: true,
      width: 125,
      align: 'right',
      render: (_: string, record: ProductType): JSX.Element => <>{moneyFormatter.format(Number(record.unitCost))}</>
    },
    {
      title: 'Ext Price',
      dataIndex: 'extendedPrice',
      key: 'extendedPrice',
      width: 125,
      align: 'right',
      render: (_: string, record: ProductType): JSX.Element => <>{moneyFormatter.format(Number(record.extendedPrice))}</>
    },
    {
      title: 'Ext Cost',
      dataIndex: 'extendedCost',
      key: 'extendedCost',
      width: 125,
      align: 'right',
      render: (_: string, record: ProductType): JSX.Element => <>{moneyFormatter.format(Number(record.extendedCost))}</>
    },
    {
      title: 'Margin',
      dataIndex: 'grossProfit',
      key: 'grossProfit',
      width: 125,
      align: 'right',
      render: (_: number, record: ProductType): JSX.Element => (
        <div
          style={{
            color: Number(record.grossProfit) >= 0 ? 'black' : 'red'
          }}>
          {moneyFormatter.format(Number(record.grossProfit))}
        </div>
      )
    },
    {
      title: 'Margin %',
      dataIndex: 'grossMargin',
      key: 'grossMargin',
      width: 100,
      align: 'right',
      render: (_: string, record: ProductType): JSX.Element => (
        <div
          style={{
            color: Number(record.grossMargin) < 0 ? 'red' : 'black'
          }}>
          {isNaN(Number(record.grossMargin)) || !isFinite(Number(record.grossMargin)) ? 0 : Number(record.grossMargin).toFixed(2)}%
        </div>
      )
    },
    {
      title: 'Action',
      width: 80,
      align: 'center',
      key: 'action',
      fixed: 'right',
      render: (_, record: ProductType): JSX.Element => {
        if (isView) return <></>;

        return (
          <Row style={{ width: '100%' }} justify="center" gutter={[5, 5]}>
            <Col>
              {editingKey === lines.findIndex((line) => line === record).toString() ? (
                <Button style={{ borderRadius: '50%' }} onClick={() => save()} icon={<CheckOutlined />} />
              ) : (
                <Button style={{ borderRadius: '50%' }} disabled={!!editingKey} onClick={(): void => edit(record)} icon={<EditOutlined />} />
              )}
            </Col>

            <Col>
              <Button
                disabled={!editingKey}
                className="product-table__delete-icon"
                onClick={(e): void => {
                  e.stopPropagation();
                  Modal.confirm({
                    title: `Delete ${record.productNumber}`,
                    onOk: () => handleDelete(lines.findIndex((line) => line === record).toString()),
                    okText: 'Delete',
                    okType: 'danger',
                    content: 'Are you sure you want to delete this product?'
                  });
                }}
                style={{ borderRadius: '50%' }}
                icon={<DeleteOutlined />}
              />
            </Col>
          </Row>
        );
      }
    }
  ];

  // DISABLED FOR NOW UNTIL LATER
  // const finalColumns = columns.filter((col) => {
  //   if (!showExtraColumns.includes(col.key as string) && ['productDescription', 'manufacturer', 'category'].includes(col.key as string)) return false;

  //   return true;
  // });

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: ProductType) => ({
        record,
        inputType: col.title === 'Condition' ? 'string' : 'number',
        dataIndex: col.dataIndex,
        title: `${col.title}`,
        editing: isView ? false : isEditing(record)
        // onchange: save(record?.lineNumber?.toString())
      })
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        style={{ marginTop: 5 }}
        components={{
          body: {
            cell: EditableCell
          }
        }}
        dataSource={lines}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{ x: 3000 }}
        rowKey={(row): string => lines.findIndex((line) => line === row).toString()}
        expandable={{
          columnWidth: 30,
          expandIcon: ({ onExpand, expanded, record }): JSX.Element | undefined => {
            if (!(record as unknown as { subLines: typeof record.subLines[] }).subLines) return undefined;
            if ((record as unknown as { subLines: typeof record.subLines[] }).subLines.length === 0) return undefined;

            return expanded ? (
              <DownOutlined
                className="product-table__arrow"
                style={{
                  fontSize: 13,
                  padding: 5
                }}
                onClick={(e): void => {
                  e.preventDefault();
                  e.stopPropagation();
                  onExpand(record, e);
                }}
              />
            ) : (
              <RightOutlined
                className="product-table__arrow"
                style={{
                  fontSize: 13,
                  padding: 5
                }}
                onClick={(e): void => {
                  e.preventDefault();
                  e.stopPropagation();
                  onExpand(record, e);
                }}
              />
            );
          },
          expandedRowRender: (record, index): React.ReactNode => {
            if (!record.subLines?.length) return <div className="hideRow" />;

            return <SublineTable index={index} product={record} subLines={record.subLines ?? []} />;
          }
        }}
        onRow={(record: ProductType, index) => ({
          onClick: (): void => {
            setIdx(index);
            if (editingKey !== lines.findIndex((line) => line === record).toString()) {
              if (editingKey) save();
              edit(record);
            }
          },
          onMouseLeave: (): void => {
            setShowOptions('');
          },
          onMouseEnter: (): void => {
            setShowOptions(lines.findIndex((line) => line === record).toString() || '');
          },
          onKeyDown: (event): void => {
            if (event.key.toLocaleLowerCase() === 'enter') {
              if (editingKey === lines.findIndex((line) => line === record).toString()) save();
            }
            if (event.key.toLocaleLowerCase() === 'escape') {
              handleCancel();
            }
          }
        })}
      />
    </Form>
  );
};

export default QuotedProductsNew;
