import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

/* ****************** Enums ****************** */
export enum SalesOrderStatusType {
  INVOICED = 'Invoiced',
  CANCELLED = 'Canceled',
  BACKORDER = 'Backorder',
  OPEN = 'Open',
  PARTIAL_SHIP = 'PartialShip',
  ALL = 'All',
  NONE = 'None'
}

/* ****************** State Info ****************** */
interface OrdersStateModel {
  customerPO?: string;
  filterAddress?: string;
  filterCity?: string;
  filterCountry?: string;
  filterCounty?: string;
  filterCreatedByUserFullName?: string;
  filterCreatedDateTime?: string;

  filterCustomerPO?: string;
  filterCustomerRequisitionNumber?: string;
  filterCustomersOrderReference?: string;
  filterDescription?: string;
  filterDraftId?: string;
  filterQuoteId?: string;
  filterEndDate?: string;
  filterOemOrderNumber?: string;
  filterProductId?: string;
  filterProjectId?: string;
  filterSalesOrderNumber?: string;
  filterSalesPerson?: string;
  filterStartDate?: string;
  filterState?: string;
  filterStatus?: string;
  filterStreet?: string;
  filterZipCode?: string;
  selectedCompanyDataAreaId?: string;
  successfulLineItems: { productName: string; message: string }[];
  submitLoadingMessage: string;
  submittedCount: number;
  offset: number;
  importLoadingMessage: string | null;
  unsuccessfulLineItems: { productName: string; message: string }[];
  quoteSubmissionStatusMessage: string;
}

const initialState: OrdersStateModel = {
  customerPO: undefined,
  filterAddress: undefined,
  filterCity: undefined,
  filterCountry: undefined,
  filterCounty: undefined,
  filterCreatedByUserFullName: undefined,
  filterCreatedDateTime: undefined,
  filterCustomerPO: undefined,
  filterCustomerRequisitionNumber: undefined,
  filterCustomersOrderReference: undefined,
  filterDraftId: undefined,
  filterQuoteId: undefined,
  filterEndDate: undefined,
  filterOemOrderNumber: undefined,
  filterProjectId: undefined,
  filterSalesOrderNumber: undefined,
  filterSalesPerson: undefined,
  filterStartDate: undefined,
  filterState: undefined,
  filterDescription: undefined,
  filterStatus: 'Draft',
  filterStreet: undefined,
  filterZipCode: undefined,
  filterProductId: undefined,
  selectedCompanyDataAreaId: Cookies.get('legalEntity') || 'MDSI',
  submitLoadingMessage: '',
  successfulLineItems: [],
  unsuccessfulLineItems: [],
  submittedCount: 0,
  offset: 0,
  importLoadingMessage: null,
  quoteSubmissionStatusMessage: ''
};

/* ****************** Slice ****************** */
export const quotesSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setFilterCustomerRequisitionNumber(state, action: PayloadAction<OrdersStateModel['filterCustomerRequisitionNumber']>) {
      state.filterCustomerRequisitionNumber = action.payload;
    },
    setFilterCustomersOrderReference(state, action: PayloadAction<OrdersStateModel['filterCustomersOrderReference']>) {
      state.filterCustomersOrderReference = action.payload;
    },
    setFilterSalesOrderNumber(state, action: PayloadAction<OrdersStateModel['filterSalesOrderNumber']>) {
      state.filterSalesOrderNumber = action.payload;
    },
    setFilterOemOrderNumber(state, action: PayloadAction<OrdersStateModel['filterOemOrderNumber']>) {
      state.filterOemOrderNumber = action.payload;
    },
    setFilterProjectId(state, action: PayloadAction<OrdersStateModel['filterProjectId']>) {
      state.filterProjectId = action.payload;
    },
    setFilterProductId(state, action: PayloadAction<OrdersStateModel['filterProductId']>) {
      state.filterProductId = action.payload;
    },
    setFilterAddress(state, action: PayloadAction<OrdersStateModel['filterAddress']>) {
      state.filterAddress = action.payload;
      state.offset = 0;
    },
    setFilterCity(state, action: PayloadAction<OrdersStateModel['filterCity']>) {
      state.filterCity = action.payload;
      state.offset = 0;
    },
    setFilterCountry(state, action: PayloadAction<OrdersStateModel['filterCountry']>) {
      state.filterCountry = action.payload;
      state.offset = 0;
    },
    setFilterState(state, action: PayloadAction<OrdersStateModel['filterState']>) {
      state.filterState = action.payload;
      state.offset = 0;
    },
    setFilterDescription(state, action: PayloadAction<OrdersStateModel['filterStreet']>) {
      state.filterDescription = action.payload;
      state.offset = 0;
    },
    setFilterStreet(state, action: PayloadAction<OrdersStateModel['filterStreet']>) {
      state.filterStreet = action.payload;
      state.offset = 0;
    },
    setFilterZipCode(state, action: PayloadAction<OrdersStateModel['filterZipCode']>) {
      state.filterZipCode = action.payload;
      state.offset = 0;
    },
    setFilterStatus(state, action: PayloadAction<OrdersStateModel['filterStatus']>) {
      state.filterStatus = action.payload;
    },
    setFilterCustomerPO(state, action: PayloadAction<OrdersStateModel['filterCustomerPO']>) {
      state.filterCustomerPO = action.payload;
    },
    setFilterCreatedByUserFullName(state, action: PayloadAction<OrdersStateModel['filterCreatedByUserFullName']>) {
      state.filterCreatedByUserFullName = action.payload;
    },
    setFilterCreatedDateTime(state, action: PayloadAction<OrdersStateModel['filterCreatedDateTime']>) {
      state.filterCreatedDateTime = action.payload;
    },
    setFilterSalesPerson(state, action: PayloadAction<OrdersStateModel['filterSalesPerson']>) {
      state.filterSalesPerson = action.payload;
    },
    setFilterDraftId(state, action: PayloadAction<OrdersStateModel['filterDraftId']>) {
      state.filterDraftId = action.payload;
    },
    setFilterQuoteId(state, action: PayloadAction<OrdersStateModel['filterQuoteId']>) {
      state.filterQuoteId = action.payload;
    },
    setFilterStartDate(state, action: PayloadAction<OrdersStateModel['filterStartDate']>) {
      state.filterStartDate = action.payload;
    },
    setFilterEndDate(state, action: PayloadAction<OrdersStateModel['filterEndDate']>) {
      state.filterEndDate = action.payload;
    },
    setSelectedCompanyDataAreaId(state, action: PayloadAction<string>) {
      state.selectedCompanyDataAreaId = action.payload;
    },
    setSuccessfulLineItems(state, action: PayloadAction<OrdersStateModel['successfulLineItems']>) {
      state.successfulLineItems = action.payload;
    },
    setUnsuccessfulLineItems(state, action: PayloadAction<OrdersStateModel['unsuccessfulLineItems']>) {
      state.unsuccessfulLineItems = action.payload;
    },
    setCustomerPO(state, action: PayloadAction<string>) {
      state.customerPO = action.payload;
    },
    resetForm(state) {
      return { ...initialState, ...{ selectedCompanyDataAreaId: state.selectedCompanyDataAreaId } };
    },
    setSubmitLoadingMessage(state, action: PayloadAction<{ message: string; addCount: boolean }>) {
      if (action.payload.addCount) {
        const countInfo = action.payload.message.match(/[0-9]+/g);

        if (countInfo) {
          state.submittedCount += 1;
          state.submitLoadingMessage = `Posting Sales Order Line Items ${state.submittedCount} of ${countInfo[1]}`;
        }
      } else {
        state.submitLoadingMessage = action.payload.message;
      }
    },
    resetSubmittedCount(state) {
      state.submittedCount = 0;
    },
    setOffset(state, action: PayloadAction<number>) {
      state.offset = action.payload;
    },
    setImportLoadingMessage: (state, { payload }: PayloadAction<OrdersStateModel['importLoadingMessage']>) => {
      state.importLoadingMessage = payload;
    },
    setQuoteSubmissionStatusMessage: (state, { payload }: PayloadAction<OrdersStateModel['quoteSubmissionStatusMessage']>) => {
      state.quoteSubmissionStatusMessage = payload;
    }
  }
});

export const {
  setFilterAddress,
  setFilterCity,
  setFilterCountry,
  setFilterCreatedByUserFullName,
  setFilterCreatedDateTime,
  setFilterCustomerPO,
  setFilterCustomerRequisitionNumber,
  setFilterCustomersOrderReference,
  setFilterDescription,
  setFilterDraftId,
  setFilterQuoteId,
  setFilterEndDate,
  setFilterOemOrderNumber,
  setFilterProductId,
  setFilterProjectId,
  setFilterSalesOrderNumber,
  setFilterSalesPerson,
  setFilterStartDate,
  setFilterState,
  setFilterStatus,
  setFilterStreet,
  setFilterZipCode,
  setSelectedCompanyDataAreaId,
  setCustomerPO,
  resetForm,
  setSubmitLoadingMessage,
  setSuccessfulLineItems,
  setUnsuccessfulLineItems,
  resetSubmittedCount,
  setOffset,
  setImportLoadingMessage,
  setQuoteSubmissionStatusMessage
} = quotesSlice.actions;

export const quotesReducer = quotesSlice.reducer;
