import { Form, InputProps, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useFormikContext } from 'formik';
const { Title } = Typography;

interface Props extends InputProps {
  fieldName: string;
  label: string;
}

export const NoteInput = ({ fieldName, label, ...rest }: Props): JSX.Element => {
  const { getFieldMeta, handleChange, getFieldHelpers } = useFormikContext<any>();
  const { value, touched, error } = getFieldMeta<string>(fieldName);
  const { setTouched } = getFieldHelpers(fieldName);

  return (
    <Form.Item
      noStyle
      hasFeedback={touched && !error}
      labelAlign="left"
      validateStatus={touched && error ? 'error' : 'success'}
      help={touched && error ? error : undefined}
      style={{ marginBottom: 0, color: 'rgba(0,0,0,0.85)' }}>
      <Title level={5} style={{ color: '#2F5596' }}>
        Notes
      </Title>
      {/* <Input multiple {...rest} value={value || undefined} onChange={handleChange(fieldName)} onFocus={() => setTouched(false)} onBlur={() => setTouched(true)} /> */}
      <TextArea
        disabled={rest.disabled}
        value={value || undefined}
        onChange={handleChange(fieldName)}
        rows={4}
        maxLength={1000}
        onFocus={() => setTouched(false)}
        onBlur={() => setTouched(true)}
        showCount
      />
    </Form.Item>
  );
};
