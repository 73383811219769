import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Col, Row } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import NoResults from 'components/atoms/noResults/noResults';
import { SalesQuoteLinkedCard } from 'components/atoms/SalesQuoteLinkedCard';
import { WimsicalError } from 'components/atoms/WimsicalError';
import { Loader } from 'components/common/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setOffset } from 'redux/services/samTheRobot/quoteParams';
import { useGetQuotesQuery } from 'redux/services/samTheRobot/samTheRobotApi';
import { ReduxState } from 'redux/store';

/* ****************** interfaces ****************** */
export const SalesQuoteGrid = (): JSX.Element => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { samTheRobotQuoteParams } = useSelector((state: ReduxState) => state);
  const { selectedCompanyDataAreaId } = useSelector((state: ReduxState) => state.orders);
  const { data: quotesDataResponse, isLoading, isFetching, error, isError } = useGetQuotesQuery({ ...samTheRobotQuoteParams, dataAreaId: selectedCompanyDataAreaId });

  const handleDetailClick = (detailId: string, detailStatus: string): void => {
    if (detailStatus === 'Won' || detailStatus === 'Lost') {
      nav(`/view/${detailId}`);
    } else {
      nav(`/edit/${detailId}`);
    }
  };

  /* ******************** Effects ******************** */
  // useEffect(() => {
  //   return () => {
  //     dispatch(resetParams());
  //   };
  // }, [dispatch]);
  const hasMore = !!quotesDataResponse && quotesDataResponse.data.length < quotesDataResponse.totalCount;

  const handleNext = (): void => {
    dispatch(setOffset(samTheRobotQuoteParams.offset + 25));
  };

  /* ****************** JSX ****************** */
  const salesQuotesCards = quotesDataResponse?.data.map((order, i) => (
    <Col key={order.id} onClick={(): void => handleDetailClick(order.id, order.status)} xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
      <SalesQuoteLinkedCard salesQuote={order} />
    </Col>
  ));

  /* ****************** Render ****************** */
  if (isLoading || (isFetching && samTheRobotQuoteParams.offset === 0)) return <Loader message={undefined} style={{ marginTop: 50 }} />;

  if (quotesDataResponse && quotesDataResponse.data.length === 0) {
    return <NoResults errorMessage="No Results Were Found..." />;
  }

  if (isError) {
    const queryError = error as FetchBaseQueryError;

    return (
      <Row justify="center" style={{ width: '100%' }}>
        <Col span={24}>
          <WimsicalError status={typeof queryError.status === 'number' ? queryError.status : 500} message={''} />
        </Col>
      </Row>
    );
  }

  return (
    <InfiniteScroll
      dataLength={quotesDataResponse?.data.length || 0}
      style={{ overflow: 'unset' }}
      next={handleNext}
      hasMore={hasMore}
      loader={
        <Row style={{ paddingTop: 50 }}>
          <BlockLoader direction="loader loader--slideUp" />
        </Row>
      }>
      <Row gutter={[16, 16]}>{salesQuotesCards}</Row>
    </InfiniteScroll>
  );
};
