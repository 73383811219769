import { Space } from 'antd';
import { FancyInput } from 'components/atoms/FancyInput';
import { FancySelect } from 'components/atoms/FancySelect';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDefaultCondition,
  setDefaultDiscountPercentage,
  setFilterCategory,
  setFilterManufacturer,
  setFilterProductName,
  setFilterProductNumber,
  setOffset,
  setResetFilters
} from 'redux/productsInfo/productsInfoSlice';
import { useGetConditionsQuery } from 'redux/services/animals/animalsApi';
import { setProductNumberContains } from 'redux/services/donaldGrump/releasedProductsParams';
import { ReduxState, useAppSelector } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';

enum SearchType {
  PRODUCT_ID = 'productId',
  PRODUCT_NAME = 'productName',
  MANUFACTURER = 'manufacturerId',
  CATEGORY = 'productGroupId',
  PRODUCT = 'productNumberContains'
}

export const ProductsSearchFilters: React.FC = () => {
  const { filterProductName, filterProductNumber, offset, resetFilters } = useSelector((state: ReduxState) => state.productsInfo);
  const { defaultDiscountPercentage, defaultCondition } = useAppSelector((state) => state.productsInfo);
  const dispatch = useDispatch();
  const { data } = useGetConditionsQuery({ overrideSkipTake: true, offset: 0, conditionNames: 'CERTRECOND,NEW,USED' });
  const [man, setMan] = useState<string | undefined>(undefined);
  const [searchName, setSearchName] = useState<string | undefined>(undefined);
  const [productNumber, setProductNumber] = useState<string | undefined>(undefined);
  const [category, setCategory] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (resetFilters) {
      setMan(undefined);
      setSearchName(undefined);
      setProductNumber(undefined);
      setCategory(undefined);
      dispatch(setResetFilters(false));
    }
  }, [dispatch, resetFilters]);

  /* ****************** Functions ****************** */
  const debounceSearchValue = useDebouncedCallback((value: string, type: SearchType) => {
    switch (type) {
      case SearchType.PRODUCT:
        dispatch(setOffset(0));
        dispatch(setProductNumberContains(value));
        break;

      case SearchType.PRODUCT_NAME:
        dispatch(setOffset(0));
        dispatch(setFilterProductName(value));
        break;

      case SearchType.MANUFACTURER:
        dispatch(setOffset(0));
        dispatch(setFilterManufacturer(value));
        break;

      case SearchType.CATEGORY:
        dispatch(setOffset(0));
        dispatch(setFilterCategory(value));
        break;
    }
  }, 600);

  const handleSearchNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchName(e.currentTarget.value);
    debounceSearchValue(e.currentTarget.value, SearchType.PRODUCT_NAME);
  };

  const handleSearchNumberChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setProductNumber(e.currentTarget.value);
    debounceSearchValue(e.currentTarget.value, SearchType.PRODUCT);
  };

  const handleSearchManufacturerChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setMan(e.currentTarget.value);
    debounceSearchValue(e.currentTarget.value, SearchType.MANUFACTURER);
  };

  const handleSearchCategoryChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setCategory(e.currentTarget.value);
    debounceSearchValue(e.currentTarget.value, SearchType.CATEGORY);
  };

  useEffect(() => {
    return (): void => {
      dispatch(setFilterProductName(''));
      dispatch(setFilterProductNumber(''));
      dispatch(setFilterManufacturer(''));
      dispatch(setFilterCategory(''));
      dispatch(setOffset(0));
    };
  }, []);

  return (
    <Space>
      <FancyInput
        value={defaultDiscountPercentage}
        width={150}
        placeholder="Default Discount"
        suffix="%"
        onChange={(e): void => {
          dispatch(setDefaultDiscountPercentage(e.target.value));
        }}
      />
      <FancySelect
        value={defaultCondition}
        width={150}
        handleSelect={(condition: string): void => {
          dispatch(setDefaultCondition(condition));
        }}
        placeholder="Default Condition"
        options={data?.data.map((condition) => ({ label: condition.name, value: condition.name }))}
      />
      <FancyInput value={productNumber} placeholder="Product Number" width={160} onChange={handleSearchNumberChange} />
      <FancyInput value={searchName} placeholder="Product Name" width={160} onChange={handleSearchNameChange} />
      <FancyInput value={man} placeholder="Manufacturer" width={150} onChange={handleSearchManufacturerChange} />
      <FancyInput value={category} placeholder="Category" width={150} onChange={handleSearchCategoryChange} />
    </Space>
  );
};
