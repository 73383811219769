import { Button, Modal } from 'antd';
import { Loader } from 'components/common/Loader';
import { useState } from 'react';

interface Props {
  awardQuote: any;
  closeModal: any;
  isModalOpen: boolean;
}

export const WonModal = ({ awardQuote, closeModal, isModalOpen }: Props): JSX.Element => {
  const [isModalSending, setIsModalSending] = useState(false);

  return (
    <Modal
      title={isModalSending ? 'Awarding Quote...' : 'Won Quote?'}
      bodyStyle={{ paddingTop: 20 }}
      closable={!isModalSending}
      onCancel={closeModal}
      open={isModalOpen}
      confirmLoading={isModalSending}
      footer={[
        <Button
          disabled={isModalSending}
          type="primary"
          className="quote-card__menu-item-award"
          onClick={async () => {
            setIsModalSending(true);
            await awardQuote().then(() => setIsModalSending(false));
          }}
          key={1}>
          Award This Quote
        </Button>
      ]}>
      {isModalSending ? <Loader style={{ marginTop: 50 }} /> : `Are you sure you are ready to award this Quote? It will change to read only mode, and allow Sales converting.`}
    </Modal>
  );
};
