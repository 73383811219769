import { Form, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useField, useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { QuotePayload } from 'models/Quotes';
import { useLocation } from 'react-router-dom';
const { Title } = Typography;

const styles: InlineStylesModel = {
  formItem: {
    marginBottom: 10,
    minHeight: '10em'
  },
  formItemMobile: {
    marginBottom: 10,
    backgroundColor: 'rgba(150,150,150,.1)',
    padding: 4,
    paddingLeft: 12,
    borderRadius: 5,
    border: '1px solid rgba(100,100,100,.1)'
  },
  formItemCol: {
    marginRight: '16px',
    maxHeight: '42px'
  },
  titleColor: {
    color: '#2F5596'
  }
};

export const EmailMessageInput = (): JSX.Element => {
  const {
    setErrors,
    values: { emailMessage },
    handleChange
  } = useFormikContext<QuotePayload>();
  const [a, { error, touched }, { setError, setTouched, setValue }] = useField('emailMessage');

  const { pathname } = useLocation();
  const isView = pathname.includes('/view');

  return (
    <Form.Item labelAlign="left" validateStatus={touched && error ? 'error' : 'success'} help={touched && error ? error : undefined} style={{ marginBottom: 0 }} labelCol={{ style: { padding: 0 } }}>
      <Title level={5} style={styles.titleColor}>
        Email Message
      </Title>
      <TextArea maxLength={1000} onChange={handleChange('emailMessage')} name="emailMessage" value={emailMessage ?? undefined} allowClear style={styles.formItem} disabled={isView} />
      {!isView && <p style={{ fontSize: 12, color: 'gray' }}>{`${emailMessage?.length}/1000`}</p>}
    </Form.Item>
  );
};
